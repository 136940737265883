import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import thumb1 from "../../assets/images/video_thumbnails/1.png";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import ic_maktab_icon from "../../assets/images/ic_maktab_icon.png";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import SurahFatiha from "../../assets/SurahFatiha.mp3";
import ic_delete from "../../assets/images/ic_delete.svg";
import ic_edit from "../../assets/images/ic_edit.svg";
import ic_eyes from "../../assets/images/ic_eyes.svg";
import upload from "../../assets/images/upload.svg";
import Modal from "react-bootstrap/Modal";
import {
  DeleteCallWithErrorResponse,
  multipartPostCall,
  multipartPostCallWithErrorResponse,
  multipartPutCallWithErrorResponse,
  PostCallWithErrorResponse,
  putCallWithAuthTokenWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig, { BASE_URL_FOR_IMAGES } from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import FormLoader from "../FormLoader";
const months = [
  { month_id: 1 },
  { month_id: 2 },
  { month_id: 3 },
  { month_id: 4 },
  { month_id: 5 },
  { month_id: 6 },
  { month_id: 7 },
  { month_id: 8 },
  { month_id: 9 },
  { month_id: 10 },
];
const examNumber = [
  { number_id: 1, number_title: "One" },
  { number_id: 2, number_title: "Two" },
  { number_id: 3, number_title: "Three" },
  { number_id: 4, number_title: "Four" },
  { number_id: 5, number_title: "Five" },
  { number_id: 6, number_title: "Six" },
  { number_id: 7, number_title: "Seven" },
  { number_id: 8, number_title: "Eight" },
  { number_id: 9, number_title: "Nine" },
  { number_id: 10, number_title: "Ten" },
];
const subFourCategory = [
  { subCategory_id: 1, subCategory_title: "Trustee", assessmentId: 1 },
  { subCategory_id: 2, subCategory_title: "Principal", assessmentId: 5 },
  { subCategory_id: 3, subCategory_title: "Teacher", assessmentId: 3 },
  { subCategory_id: 4, subCategory_title: "Student", assessmentId: 2 },
  { subCategory_id: 5, subCategory_title: "Parent (Supervisor)", assessmentId: 14 },
];
const Maktab = () => {
  const [currentTab, setCurrentTab] = useState(1);
  localStorage.setItem("jd_id", currentTab);
  console.log("Current Tab", currentTab);

  // localStorage.setItem("jd_id", 1);
  const [subCatId, setSubCatId] = useState(1);
  console.log("");
  const { sidebar, adminData } = useContext(AppContext);

  const [AddNewGroup, setAddNewGroup] = useState(0);
  const [MainVideo, setMainVideo] = useState(false);
  const handleMainVideoClose = () => setMainVideo(false);
  const handleMainVideoShow = () => setMainVideo(true);
  const [addFeature, setAddFeature] = useState(false);
  const [idDisable, setIdDisable] = useState(false);
  const handleAddFeatureClose = () => {
    setAddFeatureData({
      urdu_title: "",
      urdu_desc: "",
      hindi_title: "",
      hindi_desc: "",
      english_title: "",
      english_desc: "",
      translitration_title: "",
      translitration_desc: "",
      file: "",
    });
    setIdEdit("");
    setAddFeature(false);
  };
  const handleAddFeatureShow = () => setAddFeature(true);
  const [addWorksrhop, setAddWorksrhop] = useState(false);
  const [imageWidth, setImageWidth] = useState("100%");

  const handleAddWorksrhopClose = () => {
    setIdEdit("");
    setAddWorkShopeData({
      urdu_description: "",
      english_description: "",
      english_lit_description: "",
      hindi_description: "",
      hindi_video_path: "",
      english_lit_video_path: "",
      english_video_path: "",
      urdu_duaration: "",
      english_duaration: "",
      english_lit_duaration: "",
      hindi_duaration: "",
      hindi_name: "",
      english_lit_name: "",
      english_name: "",
      urdu_name: "",
      step_no_hindi: "",
      step_no_lit: "",
      step_no_english: "",
      step_no_urdu: "",
      urdu_video_path: "",
      subcategory_id: 2,
      is_active: true,
    });
    setAddWorksrhop(false);
  };
  const handleAddWorksrhopShow = () => setAddWorksrhop(true);
  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);
  const [AddTrustee, setAddTrustee] = useState(false);
  const handleAddTrusteeClose = () => {
    setIdEdit("");
    setAddJobDetails({
      urdu_title: "",
      urdu_desc: "",
      hindi_title: "",
      hindi_desc: "",
      english_title: "",
      english_desc: "",
      translitration_title: "",
      translitration_desc: "",
    });
    setAddTrustee(false);
  };

  const handleAddTrusteeShow = (id) => {
    setIdJOBS(id);
    setAddTrustee(true);
  };
  const [idJOBS, setIdJOBS] = useState(1);
  const [idJOBSCategory, setIdJOBSCategory] = useState(1);
  const [idMonth, setIdMonth] = useState(1);
  const [idNumber, setIdNumber] = useState(1);
  const [AddPaper, setAddPaper] = useState(false);
  const handleAddPaperClose = () => {
    setIdEdit("");
    setAddExamPaper({
      year: "",
      created_by_id: 11,
      years_link: "",
      exam_id: idNumber,
      years_title_id: "",
    });
    setAddPaper(false);
  };
  const handleAddPaperShow = () => setAddPaper(true);
  const [AddCategory, setAddCategory] = useState(false);
  const handleAddCategoryClose = () => setAddCategory(false);
  const handleAddCategoryShow = () => setAddCategory(true);
  const [AddMonthList, setAddMonthList] = useState(false);
  const handleAddMonthListClose = () => {
    setIdEdit("");
    setAddMonthsData({
      notes_urdu: "",
      notes_hindi: "",
      notes_english: "",
      notes_lit: "",
    });
    setAddMonthList(false);
  };
  const handleAddMonthListShow = () => setAddMonthList(true);
  const [validated, setValidated] = useState(false);
  const [loader, setLoader] = useState(true);
  const [idEdit, setIdEdit] = useState("");
  const [urlDelete, setUrlDelete] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [updateID, setUpdateID] = useState("");
  const [featurelist, setFeaturelist] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [vedioApiList, setVedioApiList] = useState([]);
  const [conditionApilist, setConditionApilist] = useState(
    ApiConfig.FEATURE_LIST + `?subcategory_id=${2}`
  );
  const [workshoID, setWorkshoID] = useState("");
  const [addFeatureData, setAddFeatureData] = useState({
    urdu_title: "",
    urdu_desc: "",
    hindi_title: "",
    hindi_desc: "",
    english_title: "",
    english_desc: "",
    translitration_title: "",
    translitration_desc: "",
    file: "",
  });
  const [workShopeList, setWorkShopeList] = useState([]);
  console.log("workShopeList", workShopeList);
  const [addworkShopeData, setAddWorkShopeData] = useState({
    urdu_description: "",
    english_description: "",
    english_lit_description: "",
    hindi_description: "",
    urdu_video_path: "",
    hindi_video_path: "",
    english_lit_video_path: "",
    english_video_path: "",
    urdu_duaration: "",
    english_duaration: "",
    english_lit_duaration: "",
    hindi_duaration: "",
    hindi_name: "",
    english_lit_name: "",
    english_name: "",
    urdu_name: "",
    step_no_hindi: "",
    step_no_lit: "",
    step_no_english: "",
    step_no_urdu: "",
    subcategory_id: 2,
    is_active: true,
  });
  const [addJobDetails, setAddJobDetails] = useState({
    urdu_title: "",
    urdu_desc: "",
    hindi_title: "",
    hindi_desc: "",
    english_title: "",
    english_desc: "",
    translitration_title: "",
    translitration_desc: "",
  });
  const [addMonthsData, setAddMonthsData] = useState({
    notes_urdu: "",
    notes_hindi: "",
    notes_english: "",
    notes_lit: "",
  });
  const [addExamCategory, setAddExamCategory] = useState({
    title: "",
  });
  const [addExamPaper, setAddExamPaper] = useState({
    year: "",
    years_link: "",
    exam_id: idNumber,
    years_title_id: "",
  });
  const [videoEditData, setVideoEditData] = useState({
    subcategory_media_VideoName_hindi: "",
    subcategory_media_VideoPath_hindi: "",
    subcategory_media_VideoDuaration_hindi: "",
    subcategory_media_VideoName_lit: "",
    subcategory_media_VideoPath_lit: "",
    subcategory_media_VideoDuaration_lit: "",
    subcategory_media_VideoName_urdu: "",
    subcategory_media_VideoPath_urdu: "",
    subcategory_media_VideoDuaration_urdu: "",
    subcategory_media_VideoName_english: " ",
    subcategory_media_VideoPath_english: "",
    subcategory_media_VideoDuaration_english: "",
    subcategory_id: 2,
  });
  const [listCategory, setListCategory] = useState([]);
  const [examPaperData, setExamPaperData] = useState([]);
  const [superMonthsData, setSuperMonthsData] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const addFeatureDatacopy = { ...addFeatureData };
    addFeatureDatacopy[name] = value;
    setAddFeatureData(addFeatureDatacopy);
  };
  const handleChangeVideoEdit = (event) => {
    const { name, value } = event.target;
    const videoEditDatacopy = { ...videoEditData };
    videoEditDatacopy[name] = value;
    setVideoEditData(videoEditDatacopy);
  };
  const handleChangeWorkShope = (event) => {
    const { name, value } = event.target;
    const addworkShopeDatacopy = { ...addworkShopeData };
    addworkShopeDatacopy[name] = value;
    setAddWorkShopeData(addworkShopeDatacopy);
  };
  const handleChangeJob = (event) => {
    const { name, value } = event.target;
    const addJobDetailscopy = { ...addJobDetails };
    addJobDetailscopy[name] = value;
    setAddJobDetails(addJobDetailscopy);
  };
  const handleChangeMonths = (event) => {
    const { name, value } = event.target;
    const addMonthsDatacopy = { ...addMonthsData };
    addMonthsDatacopy[name] = value;
    setAddMonthsData(addMonthsDatacopy);
  };

  // add feature data
  let payLoadFormData = new FormData();
  payLoadFormData.append("file", addFeatureData.file);
  payLoadFormData.append("urdu_title", addFeatureData.urdu_title);
  payLoadFormData.append("urdu_desc", addFeatureData.urdu_desc);
  payLoadFormData.append("hindi_title", addFeatureData.hindi_title);
  payLoadFormData.append("hindi_desc", addFeatureData.hindi_desc);
  payLoadFormData.append("english_title", addFeatureData.english_title);
  payLoadFormData.append("english_desc", addFeatureData.english_desc);
  payLoadFormData.append(
    "translitration_title",
    addFeatureData.translitration_title
  );
  payLoadFormData.append(
    "translitration_desc",
    addFeatureData.translitration_desc
  );
  const addFeatureApi = (e, url, data, Methode, handle) => {
    e.preventDefault();
    handleMainVideoClose();
    handleAddFeatureClose();
    handleAddWorksrhopClose();
    handleAddTrusteeClose();
    handleAddMonthListClose();
    handleAddPaperClose();
    handleAddCategoryClose();

    setLoader(true);
    Methode(url, data)
      .then((res) => {
        setLoader(false);
        if (res.response?.status === 200) {
          featureListApi(conditionApilist);
          if (res.json.message == "Media Updated Successfully") {
            featureListApi(ApiConfig.GET_MEDIEA + `?subcategory_id=${2}`);
          }
          if (res.json.category == "exampaper category") {
            featureListApi(ApiConfig.EXAM_PAPER_CATEGORY_LIST);
          }

          ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        // ToastMsg("error", "something went wrong");
      });
  };

  //main vidieo api integration
  //list api
  useEffect(() => {
    //GET_MEDIEA
    featureListApi(ApiConfig.FEATURE_LIST + `?subcategory_id=${2}`);
    featureListApi(ApiConfig.GET_MEDIEA + `?subcategory_id=${2}`);
  }, []);

  const featureListApi = (urldata) => {
    setLoader(true);
    simpleGetCallWithErrorResponse(urldata)
      .then((res) => {
        setLoader(false);

        // if (res.response.status === 200) {

        if (res.response.status === 200) {
          if (res.json?.media) {
            setVedioApiList(res.json?.media);
          }
          if (res.json?.featuresAndBenefits) {
            setFeaturelist(res.json?.featuresAndBenefits);
          }
          if (res.json?.workshopData) {
            // setWorkShopeList(res.json?.workshopData);
            let arr = [24, 25, 26, 27, 28, 29, 30, 31, 32, 33];
            console.log('>>>1', res?.json?.workshopData);
            console.log('>>>1', arr);
            let i = 0;
            for (const key of res?.json?.workshopData) {
              console.log('>>>2', key);
              console.log('>>>3', i);
              key.assesment_id = arr[i];
              console.log('>>>4', key);

              i++;
            }
            setWorkShopeList(res.json.workshopData);


          }
          if (res.json?.getJobDescriptionDetails) {
            setJobList(res.json?.getJobDescriptionDetails);
          }
          if (res.json?.examPaperData) {
            setExamPaperData(res.json?.examPaperData);
          }
          if (res.json?.examPaperFile) {
            setListCategory(res.json?.examPaperFile);
          }
          if (res.json?.notes) {
            setSuperMonthsData(res.json?.notes);
          }
          if (res.json?.featuresAndBenefitsData) {
            setAddFeatureData(res.json?.featuresAndBenefitsData);
          }
          if (res.json?.editnotes) {
            setAddMonthsData(res.json?.editnotes);
          }
          if (res.json?.editwsk) {
            setAddWorkShopeData(res.json?.editwsk);
          }
          if (res.json?.jddEdit) {
            setAddJobDetails(res.json?.jddEdit);
          }
          if (res.json?.editmedia) {
            setVideoEditData(res.json.editmedia);
          }
          // setAddExamPaper(res.json?.edit)
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);
        // ToastMsg("error", "something went wrong");
      });
  };

  const handleClick = () => {
    const data = {
      jd_id: subCatId,
    };

    navigate("/Assessment", { state: data });
  };

  // edit adat

  //testing
  const featureDeleteApi = (urld) => {
    handleDeleteClose();
    setLoader(true);
    DeleteCallWithErrorResponse(urld)
      .then((res) => {
        setLoader(false);

        if (res.json && res.json?.result === true) {
          if (res.json.message == "Exam Paper Category Deleted Successfully") {
            featureListApi(ApiConfig.EXAM_PAPER_CATEGORY_LIST);
          }
          ToastMsg("success", "Deleted Successfully");

          featureListApi(conditionApilist);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        // ToastMsg("error", "something went wrong");
      });
  };

  const filterData = (id) => {
    let data = featurelist.filter((ele) => ele.fb_id === id);
    let filteredData = data[0].list;
    let idDat = data[0].fb_id;
    let dataArrayID = filteredData.map((ele) => ele.fb_detail_id);
    setUpdateID(dataArrayID);
    setDeleteId(idDat);
  };

  const examFilter = (examied, year_id) => {
    let ExamData = examPaperData.filter((ele) => {
      return ele.years_title_id == year_id;
    });
    let newData = ExamData[0].years.filter((id2) => id2.year_id == examied);
    newData[0].years_title_id = year_id;
    // newData[0].year_id = examied;
    newData[0].exam_id = idNumber;
    setAddExamPaper(newData[0]);
  };

  //validationfeature set

  const handleSubmit = (event, section) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (section == "featureSet") {
        {
          idEdit == "edit"
            ? addFeatureApi(
              event,
              ApiConfig.FEATURE_UPDATE +
              `?subcategory_id=${2}&details_ids=${JSON.stringify(
                updateID
              )}&fb_id=${deleteId}`,
              payLoadFormData,
              putMultipartWithAuthCallWithErrorResponse,
              "featureSet"
            )
            : addFeatureApi(
              event,
              ApiConfig.FEATURE_ADD + `?subcategory_id=${2}`,
              payLoadFormData,
              multipartPostCallWithErrorResponse,
              "feature"
            );
        }
      }
      if (section == "Workshop") {
        {
          idEdit == "edit"
            ? addFeatureApi(
              event,
              ApiConfig.WORKSHOP_UPDATE,
              JSON.stringify({
                ...addworkShopeData,
                created_by_id: Number(adminData.user_id),
                workshop_ten_step_id: workshoID,
                is_active: true,
              }),

              putCallWithAuthTokenWithErrorResponse,
              "Workshop"
            )
            : addFeatureApi(
              event,
              ApiConfig.WORKSHOP_ADD,
              JSON.stringify({
                ...addworkShopeData,
                created_by_id: Number(adminData.user_id),
              }),
              PostCallWithErrorResponse,
              "Workshop"
            );
        }
      }
      if (section == "jobdetails") {
        {
          idEdit == "edit"
            ? addFeatureApi(
              addFeatureApi(
                event,
                ApiConfig.JOBDESC_LIST_DETAILS_UPDATE +
                `?group_id=${workshoID}`,
                JSON.stringify(addJobDetails),
                putCallWithAuthTokenWithErrorResponse,
                "jobdetails"
              )
            )
            : addFeatureApi(
              event,
              ApiConfig.JOBDESC_LIST_DETAILS_ADD + `?jd_id=${idJOBSCategory}`,
              JSON.stringify({
                ...addJobDetails,
              }),
              PostCallWithErrorResponse,
              "jobdetails"
            );
        }
      }
      if (section == "Supervision") {
        {
          idEdit == "edit"
            ? addFeatureApi(
              event,
              ApiConfig.SUPERVISION_MONTH_UPDATE,
              JSON.stringify({
                ...addMonthsData,
                created_by_id: adminData.user_id,
                group_id: workshoID,
              }),
              // putMultipartWithAuthCallWithErrorResponse
              putCallWithAuthTokenWithErrorResponse,
              "Supervision"
            )
            : addFeatureApi(
              event,
              ApiConfig.SUPERVISION_MONTH_ADD,
              // +
              //   `?supervision_id=${idJOBSCategory}&month_id=${idMonth}`
              JSON.stringify({
                ...addMonthsData,
                created_by_id: adminData.user_id,
                supervision_id: idJOBSCategory,
                month_id: idMonth,
              }),
              PostCallWithErrorResponse,
              "Supervision"
            );
        }
      }
      if (section == "ExamPaper") {
        {
          idEdit == "edit"
            ? addFeatureApi(
              event,
              ApiConfig.EXAM_PAPER_UPDATE + `?year_id=${workshoID}`,
              JSON.stringify({
                ...addExamPaper,
                modified_by_id: adminData.user_id,
              }),
              putCallWithAuthTokenWithErrorResponse,
              "ExamPaper"
            )
            : addFeatureApi(
              event,
              ApiConfig.EXAM_PAPER_ADD,
              //  +
              //   `?jd_id=${idJOBSCategory}`
              JSON.stringify({
                ...addExamPaper,
                created_by_id: adminData.user_id,
              }),
              PostCallWithErrorResponse,
              "ExamPaper"
            );
        }
      }
      if (section == "addCategory") {
        addFeatureApi(
          event,
          ApiConfig.EXAM_PAPER_ADD_CATEGORY,
          // +
          //   `?jd_id=${idJOBSCategory}`
          JSON.stringify({
            ...addExamCategory,
          }),
          PostCallWithErrorResponse,
          "addCategory"
        );
      }
      if (section == "videos") {
        addFeatureApi(
          event,
          ApiConfig.UPDATE_MEDIEA + `?subcategory_id=${2}`,
          JSON.stringify(videoEditData),
          putCallWithAuthTokenWithErrorResponse
        );
      }
    }

    setValidated(true);
  };

  const navigate = useNavigate();
  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Maktab</label>
        </div>
      </div>

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              onClick={() => featureDeleteApi(urlDelete)}
            >
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>

      {/* ======================= END DELETE ========================== */}

      <div className="main-wrapper" id="maktab-main">
        <div className="main-all-video ">
          {vedioApiList?.length > 0 && (
            <div className="right">
              <div className="add-btn">
                <button
                  onClick={() => {
                    setConditionApilist(
                      ApiConfig.GET_MEDIEA + `?subcategory_id=${2}`
                    );
                    handleMainVideoShow();
                  }}
                >
                  <img src={ic_edit} alt="" />
                </button>
              </div>
            </div>
          )}

          {vedioApiList?.length > 0 ? (
            vedioApiList?.map((videolist, index) => {
              return (
                <div className="outer-main-video row" key={index}>
                  {videolist.sub_category_media.map((subitem, index) => {
                    return (
                      <div className="col-md-3 outer-main-video" key={index}>
                        <h6>
                          {(subitem.language_id === 4 && <span>Hindi</span>) ||
                            (subitem.language_id === 1 && (
                              <span>English</span>
                            )) ||
                            (subitem.language_id === 2 && <span>Urdu</span>) ||
                            (subitem.language_id === 3 && (
                              <span>Transliteration</span>
                            ))}{" "}
                          Video
                        </h6>
                        <div className="video">
                          <a
                            href={subitem.subcategory_media_VideoPath}
                            target="_blank"
                          >
                            <img src={thumb1} alt="" />
                            <div className="play-btn">
                              <button class="button">
                                <span class="arrow"></span>
                                Play
                              </button>
                            </div>
                          </a>
                          {/* <video controls poster={thumb1}>
                            <source
                              src={subitem.subcategory_media_VideoPath}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video> */}
                        </div>
                        <div className="content">
                          <div className="left">
                            <div className="step-name">
                              <label htmlFor="" className="key">
                                Name:
                              </label>
                              <label htmlFor="" className="value ms-2">
                                {subitem.subcategory_media_VideoName}
                              </label>
                            </div>
                            <div className="duration">
                              <label htmlFor="" className="key">
                                Duration:
                              </label>
                              <label htmlFor="" className="value ms-2">
                                {subitem.subcategory_media_VideoDuaration}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <h5 className="text-center text-danger">No Data Found</h5>
          )}

          <Modal
            show={MainVideo}
            onHide={handleMainVideoClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                noValidate
                validated={validated}
                // onSubmit={(e) => handleSubmit(e,"videos")}
                onSubmit={(e) => handleSubmit(e, "videos")}
              >
                <div className="feature-wrapper">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <Form.Label>English URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_english"
                        value={
                          videoEditData.subcategory_media_VideoPath_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter English URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Hindi URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_hindi"
                        value={videoEditData.subcategory_media_VideoPath_hindi}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Hindi URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Urdu URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_urdu"
                        value={videoEditData.subcategory_media_VideoPath_urdu}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Urdu URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Transliteration URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_lit"
                        value={videoEditData.subcategory_media_VideoPath_lit}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Transliteration URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name English</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_english"
                        value={
                          videoEditData.subcategory_media_VideoName_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name English
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Hindi</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_hindi"
                        value={videoEditData.subcategory_media_VideoName_hindi}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name Hindi
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Urdu</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_urdu"
                        value={videoEditData.subcategory_media_VideoName_urdu}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Transliteration</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_lit"
                        value={videoEditData.subcategory_media_VideoName_lit}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name Urdu
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration English 1</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_english"
                        // pattern="^[0-9:]+$" // Regular expression to allow only numbers and colons
                        value={
                          videoEditData.subcategory_media_VideoDuaration_english
                        }
                        onChange={handleChangeVideoEdit}
                        onKeyDown={(e) => {
                          const key = e.key;
                          const allowedKeys = [
                            "Backspace",
                            "Delete",
                            "ArrowLeft",
                            "ArrowRight",
                          ];
                          if (
                            !/[0-9:]/.test(key) &&
                            !allowedKeys.includes(key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration English
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Hindi 1</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_hindi"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_hindi
                        }
                        onChange={handleChangeVideoEdit}
                        onKeyDown={(e) => {
                          const key = e.key;
                          const allowedKeys = [
                            "Backspace",
                            "Delete",
                            "ArrowLeft",
                            "ArrowRight",
                          ];
                          if (
                            !/[0-9:]/.test(key) &&
                            !allowedKeys.includes(key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Hindi
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Urdu</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_urdu"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_urdu
                        }
                        onChange={handleChangeVideoEdit}
                        onKeyDown={(e) => {
                          const key = e.key;
                          const allowedKeys = [
                            "Backspace",
                            "Delete",
                            "ArrowLeft",
                            "ArrowRight",
                          ];
                          if (
                            !/[0-9:]/.test(key) &&
                            !allowedKeys.includes(key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Urdu
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Transliteration</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_lit"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_lit
                        }
                        onChange={handleChangeVideoEdit}
                        onKeyDown={(e) => {
                          const key = e.key;
                          const allowedKeys = [
                            "Backspace",
                            "Delete",
                            "ArrowLeft",
                            "ArrowRight",
                          ];
                          if (
                            !/[0-9:]/.test(key) &&
                            !allowedKeys.includes(key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Transliteration
                      </Form.Control.Feedback>
                    </div>
                    {/* <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>English Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for English Video"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Hindi Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for Hindi Video"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Urdu Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for Urdu Video"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Transliteration Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for Transliteration Video"
                        />
                      </Form.Group>
                    </div> */}
                    <div className="buttons-modal  text-center mt-2  ">
                      <button className="me-2 ">MEDIA UPDATE</button>
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            {/* <Modal.Footer className="justify-content-center">
           
            </Modal.Footer> */}
          </Modal>
        </div>

        <hr />

        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
          <Nav variant="pills" className="flex-row">
            <Nav.Item>
              <Nav.Link
                eventKey="1"
                onClick={() => {
                  featureListApi(
                    ApiConfig.FEATURE_LIST + `?subcategory_id=${2}`
                  );
                  setConditionApilist(
                    ApiConfig.FEATURE_LIST + `?subcategory_id=${2}`
                  );
                  // setIdDisable(true)
                }}

              // disabled={idDisable}
              >
                Features & Benefits
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="2"
                onClick={() => {
                  featureListApi(
                    ApiConfig.WORKSHOP_LIST + `?subcategory_id=${2}`
                  );
                  setConditionApilist(
                    ApiConfig.WORKSHOP_LIST + `?subcategory_id=${2}`
                  );
                }}
              >
                Workshop in 10 Steps
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              {/* onClick={() => featureListApi(ApiConfig.JOBDESC_LIST+ `?subcategory_id=${2}`)} */}
              <Nav.Link
                eventKey="3"
                onClick={() => {
                  localStorage.setItem("assessmentId", 1);

                  featureListApi(
                    ApiConfig.JOBDESC_LIST_DETAILS + `?jd_id=${1}`
                  );
                  setConditionApilist(
                    ApiConfig.JOBDESC_LIST_DETAILS + `?jd_id=${1}`
                  );
                }}
              >
                Job Description
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              {/* <Nav.Link eventKey="4">Lecture</Nav.Link> */}
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="5"
                onClick={() => {
                  featureListApi(
                    ApiConfig.SUPERVISION_MONTH_DETAILS +
                    `?supervision_id=${1}&month_id=${1}`
                  );
                  setConditionApilist(
                    ApiConfig.SUPERVISION_MONTH_DETAILS +
                    `?supervision_id=${1}&month_id=${1}`
                  );
                }}
              >
                Supervision
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="6"
                onClick={() => {
                  featureListApi(ApiConfig.EXAM_PAPER_CATEGORY_LIST);
                  featureListApi(ApiConfig.EXAM_PAPER_LIST + `?exam_id=${1}`);
                  setConditionApilist(
                    ApiConfig.EXAM_PAPER_LIST + `?exam_id=${1}`
                  );
                }}
              >
                Exam Paper
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            {/*   ====== Feature ===== */}
            <Tab.Pane eventKey="1">
              <div className="feature-lists row">
                <div className="add-btn">
                  <button onClick={() => handleAddFeatureShow()}>Add +</button>
                </div>
                {loader ? (
                  <FormLoader />
                ) : (
                  <>
                    {featurelist && featurelist.length > 0 ? (
                      featurelist.map((card, index) => {
                        return (
                          <div className="col-md-4">
                            <div
                              className="feature-card"
                              key={"hellomat" + index}
                            >
                              <div className="img">
                                <img
                                  src={
                                    card?.image_path &&
                                    card?.image_path.length > 0 &&
                                    BASE_URL_FOR_IMAGES + card.image_path
                                  }
                                  alt=""
                                  className="f-icon"
                                />
                                <div className="add-btn">
                                  <>
                                    <button
                                      className="delete "
                                      onClick={() => {
                                        handleDeleteShow();
                                        setDeleteId(card.fb_id);
                                        setUrlDelete(
                                          ApiConfig.DELETEAPI +
                                          `?fb_id=${card.fb_id}`
                                        );
                                      }}
                                    >
                                      <img src={ic_delete} alt="" />
                                    </button>
                                    <button
                                      onClick={() => {
                                        filterData(card.fb_id);
                                        featureListApi(
                                          ApiConfig.FEATURE_EDIT +
                                          `?fb_id=${card.fb_id}`
                                        );

                                        setIdEdit("edit");
                                        setImageWidth("117px");

                                        handleAddFeatureShow();
                                      }}
                                    >
                                      <img src={ic_edit} alt="" />
                                    </button>
                                  </>
                                </div>
                              </div>
                              <>
                                {card.list &&
                                  card.list.length > 0 &&
                                  card.list.map((subitem, index) => {
                                    return (
                                      <>
                                        <div
                                          className="heading"
                                          key={"newindex" + index}
                                        >
                                          <label htmlFor="">
                                            {subitem?.title}
                                          </label>
                                        </div>
                                        <p className="content">
                                          {subitem?.description}
                                        </p>
                                        <hr />
                                      </>
                                    );
                                  })}
                              </>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <h5 className="text-center text-danger">No Data Found</h5>
                    )}
                  </>
                )}
              </div>

              <Modal
                show={addFeature}
                onHide={handleAddFeatureClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {idEdit === "edit" ? "Update Features" : "Add Features"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={(e) => handleSubmit(e, "featureSet")}
                  >
                    <div className="feature-wrapper">
                      <div className="row">
                        {idEdit ? (
                          <>
                            <div>
                              {" "}
                              <Form.Label>Feature Image</Form.Label>
                            </div>
                            <div className="col-md-12 mb-4  d-flex">
                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "col-md-12 "
                                    : "col-md-2 "
                                }
                              >
                                <Form.Control
                                  style={{ width: imageWidth }}
                                  type="file"
                                  name="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  // value={addFeatureData.file}
                                  onChange={(e) => {
                                    setAddFeatureData({
                                      ...addFeatureData,
                                      file: e.target.files[0],
                                    });
                                    setImageWidth("100%");
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Feature Image
                                </Form.Control.Feedback>
                              </div>

                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "d-none"
                                    : "col-md-10 "
                                }
                              >
                                <Form.Control
                                  disabled={true}
                                  required
                                  type="text"
                                  name="file"
                                  value={addFeatureData.file}
                                  onChange={handleChange}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Feature Image
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-4">
                            <Form.Label>Feature Image</Form.Label>
                            <Form.Control
                              required
                              type="file"
                              name="file"
                              accept="image/x-png,image/gif,image/jpeg"
                              // value={addFeatureData?.file}
                              onChange={(e) =>
                                setAddFeatureData({
                                  ...addFeatureData,
                                  file: e.target.files[0],
                                })
                              }
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Feature Image
                            </Form.Control.Feedback>
                          </div>
                        )}
                        <div className="col-md-6 mb-4">
                          <Form.Label>Feature Title Urdu</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="urdu_title"
                            maxLength="95"
                            value={addFeatureData.urdu_title}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Feature Title Urdu
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Feature Title Hindi</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="hindi_title"
                            maxLength="95"
                            value={addFeatureData.hindi_title}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Title Hindi
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Feature Title English</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="english_title"
                            maxLength="95"
                            value={addFeatureData.english_title}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Title English
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Feature Title Transliteration</Form.Label>
                          <Form.Control
                            required
                            maxLength="95"
                            type="text"
                            name="translitration_title"
                            value={addFeatureData.translitration_title}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Title Transliteration
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-4">
                          <Form.Label>Feature Description Urdu</Form.Label>
                          <Form.Control
                            required
                            as="textarea"
                            maxLength="1800"
                            rows={3}
                            name="urdu_desc"
                            value={addFeatureData.urdu_desc}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description Urdu
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-4">
                          <Form.Label>Feature Description Hindi</Form.Label>
                          <Form.Control
                            required
                            as="textarea"
                            maxLength="1800"
                            rows={3}
                            name="hindi_desc"
                            value={addFeatureData.hindi_desc}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Description Hindi
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-4">
                          <Form.Label>Feature Description English</Form.Label>
                          <Form.Control
                            required
                            as="textarea"
                            maxLength="1800"
                            rows={3}
                            name="english_desc"
                            value={addFeatureData.english_desc}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description English
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-4">
                          <Form.Label>
                            Feature Description Transliteration
                          </Form.Label>
                          <Form.Control
                            required
                            as="textarea"
                            maxLength="1800"
                            rows={3}
                            name="translitration_desc"
                            value={addFeatureData.translitration_desc}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description Transliteration
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="buttons-main">
                        {/* <button className="cancel-btn">Cancel</button> */}
                        <button className="submit-btn">
                          {idEdit == "edit" ? "Update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
            </Tab.Pane>
            {/* ======= Steps ======= */}
            <Tab.Pane eventKey="2">
              <div className="workshopSteps-main">
                <div className="wrapper row">
                  <div className="add-btn">
                    <button onClick={handleAddWorksrhopShow}>Add +</button>
                  </div>
                  {loader ? (
                    <FormLoader />
                  ) : (
                    <>
                      {workShopeList && workShopeList.length > 0 ? (
                        workShopeList.map((cards, index) => {
                          return (
                            <div className="col-md-4">
                              <div
                                className="videoStepsCard"
                                key={"cardwork" + index}
                              >
                                <div className="add-btn customBTN">
                                  <div className="">
                                    <button
                                      onClick={() => {
                                        // handleClick();
                                        localStorage.setItem("subCat", 2);
                                        localStorage.setItem("assessmentId", cards.assesment_id);
                                        localStorage.setItem("workshop_ten_step_id", cards.workshop_ten_step_id);
                                        // handleAddTrusteeShow(idJOBSCategory);
                                        navigate("/Assessment", {
                                          state: { data: subCatId },
                                        });
                                      }}
                                    >
                                      Assessment
                                    </button>
                                  </div>
                                  <div className="">
                                    <button
                                      className="delete"
                                      onClick={() => {
                                        setDeleteId(cards.workshop_ten_step_id);

                                        setUrlDelete(
                                          ApiConfig.WORKSHOP_DELETEAPI +
                                          `?workshop_ten_step_id=${cards.workshop_ten_step_id}`
                                        );

                                        handleDeleteShow();
                                      }}
                                    >
                                      <img src={ic_delete} alt="" />
                                    </button>
                                    <button
                                      onClick={() => {
                                        // filterData(card.fb_id);
                                        // EditListApi(card.fb_id);
                                        setIdEdit("edit");
                                        handleAddWorksrhopShow();
                                        setWorkshoID(cards.workshop_ten_step_id);
                                        // WORKSHOP_EDIT
                                        featureListApi(
                                          ApiConfig.WORKSHOP_EDIT +
                                          `?workshop_ten_step_id=${cards.workshop_ten_step_id}`
                                        );
                                      }}
                                    >
                                      <img src={ic_edit} alt="" />
                                    </button>
                                  </div>
                                </div>

                                {cards.list &&
                                  cards.list.length > 0 &&
                                  cards.list.map((subcard, index) => {
                                    return (
                                      <>
                                        <div
                                          className="steps"
                                          key={"subcard001" + index}
                                        >
                                          <label htmlFor="" className="key">
                                            Step:
                                          </label>
                                          <label
                                            htmlFor=""
                                            className="value ms-2"
                                          >
                                            {subcard.step_no}
                                          </label>
                                        </div>
                                        <div className="step-name">
                                          <label htmlFor="" className="key">
                                            Step Name:
                                          </label>
                                          <label
                                            htmlFor=""
                                            className="value ms-2"
                                          >
                                            {subcard.step_name}
                                          </label>
                                        </div>
                                        <div className="duration">
                                          <label htmlFor="" className="key">
                                            Duration:
                                          </label>
                                          <label
                                            htmlFor=""
                                            className="value ms-2"
                                          >
                                            {subcard.duaration}
                                          </label>
                                        </div>
                                        <div className="video">
                                          <a
                                            href={subcard.video_path}
                                            target="_blank"
                                          >
                                            <img src={thumb1} alt="" />
                                          </a>
                                          {/* <video controls poster={thumb1}>
                                            <source
                                              src={subcard.video_path}
                                              type="video/mp4"
                                            />
                                            Your browser does not support the
                                            video tag.
                                          </video> */}
                                        </div>
                                        <div className="desc">
                                          <label htmlFor="" className="key">
                                            Description
                                          </label>
                                          <p className="value">
                                            {subcard.description}
                                          </p>
                                        </div>
                                        <hr />
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <h5 className="text-center text-danger">
                          No Data Found
                        </h5>
                      )}
                    </>
                  )}
                </div>
              </div>

              <Modal
                show={addWorksrhop}
                onHide={handleAddWorksrhopClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {idEdit === "edit" ? "Update Workshop" : "Add Workshop"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={(e) => handleSubmit(e, "Workshop")}
                  >
                    <div className="feature-wrapper">
                      <div className="row">
                        <div className="col-md-3 mb-4">
                          <Form.Label>Step Urdu</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="step_no_urdu"
                            value={addworkShopeData.step_no_urdu}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Step Urdu
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-3 mb-4">
                          <Form.Label>Step Hindi</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            name="step_no_hindi"
                            value={addworkShopeData.step_no_hindi}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Step Hindi
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-3 mb-4">
                          <Form.Label>Step English</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            name="step_no_english"
                            value={addworkShopeData.step_no_english}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Step English
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-3 mb-4">
                          <Form.Label>Step Transliteration</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            name="step_no_lit"
                            value={addworkShopeData.step_no_lit}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Step Transliteration
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-3 mb-4">
                          <Form.Label>Step Name Urdu</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="urdu_name"
                            value={addworkShopeData.urdu_name}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Step Name Urdu
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-3 mb-4">
                          <Form.Label>Step Name Hindi</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            name="hindi_name"
                            value={addworkShopeData.hindi_name}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Step Name Hindi
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-3 mb-4">
                          <Form.Label>Step Name English</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="english_name"
                            value={addworkShopeData.english_name}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Step Name English
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-3 mb-4">
                          <Form.Label>Step Name Transliteration</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="english_lit_name"
                            value={addworkShopeData.english_lit_name}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Step Name Transliteration
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-4 mb-4">
                          <Form.Label>Duration Urdu</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="urdu_duaration"
                            value={addworkShopeData.urdu_duaration}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Duration Urdu
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-4 mb-4">
                          <Form.Label>Duration Hindi</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            lang="hi"
                            name="hindi_duaration"
                            value={addworkShopeData.hindi_duaration}
                            onChange={handleChangeWorkShope}
                            onKeyDown={(e) => {
                              const key = e.key;
                              const allowedPattern = /^[0-9:]|^[०१२३४५६७८९]$/;
                              if (
                                !allowedPattern.test(key) &&
                                key !== "Backspace"
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Duration Hindi
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-4 mb-4">
                          <Form.Label>Duration English</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="english_duaration"
                            value={addworkShopeData.english_duaration}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Duration English
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-4 mb-4">
                          <Form.Label>
                            Duration Transliteration
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="english_lit_duaration"
                            value={addworkShopeData.english_lit_duaration}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter English transliteration
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-4 mb-4">
                          <Form.Label>Video URL Urdu</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="urdu_video_path"
                            value={addworkShopeData.urdu_video_path}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Video URL Urdu
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-4 mb-4">
                          <Form.Label>Video URL Hindi</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="hindi_video_path"
                            value={addworkShopeData.hindi_video_path}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Video URL Hindi
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-4 mb-4">
                          <Form.Label>Video URL English</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="english_video_path"
                            value={addworkShopeData.english_video_path}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Video URL English
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-4 mb-4">
                          <Form.Label>Video URL litrature</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="english_lit_video_path"
                            value={addworkShopeData.english_lit_video_path}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Video URL litrature
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-12 mb-4">
                          <Form.Label>Description Urdu</Form.Label>
                          <Form.Control
                            required
                            as="textarea"
                            rows={3}
                            maxLength={100000}
                            name="urdu_description"
                            value={addworkShopeData.urdu_description}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description Urdu
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-4">
                          <Form.Label>Description Hindi</Form.Label>
                          <Form.Control
                            required
                            as="textarea"
                            rows={3}
                            maxLength={100000}

                            name="hindi_description"
                            value={addworkShopeData.hindi_description}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description Hindi
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-4">
                          <Form.Label>Description English</Form.Label>
                          <Form.Control
                            required
                            as="textarea"
                            rows={3}
                            maxLength={100000}

                            name="english_description"
                            value={addworkShopeData.english_description}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description English
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-4">
                          <Form.Label>Description Transliteration</Form.Label>
                          <Form.Control
                            required
                            as="textarea"
                            rows={3}
                            maxLength={100000}

                            name="english_lit_description"
                            value={addworkShopeData.english_lit_description}
                            onChange={handleChangeWorkShope}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description Transliteration
                          </Form.Control.Feedback>
                        </div>

                        <hr />
                      </div>
                      <div className="buttons-main">
                        {/* <button className="cancel-btn">Cancel</button> */}
                        <button className="submit-btn">
                          {idEdit == "edit" ? "Update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
            </Tab.Pane>
            {/* ====== JD ======= */}
            <Tab.Pane eventKey="3">
              <div className="jobDescriptio-main">
                <div className="wrapper">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                    <div className="row">
                      <div className="">
                        <Nav
                          variant="pills"
                          onSelect={(selectedKey) =>
                            setCurrentTab(`${selectedKey}`)
                          }
                          className="flex-row"
                        >
                          {subFourCategory &&
                            subFourCategory.length > 0 &&
                            subFourCategory.map((sublist, index) => {
                              console.log("sublist", sublist);
                              return (
                                <Nav.Item key={"navidation" + index}>
                                  <Nav.Link
                                    eventKey={sublist.subCategory_id}
                                    onClick={() => {
                                      localStorage.setItem("assessmentId", sublist.assessmentId);
                                      featureListApi(
                                        ApiConfig.JOBDESC_LIST_DETAILS +
                                        `?jd_id=${sublist.subCategory_id}`
                                      );

                                      setConditionApilist(
                                        ApiConfig.JOBDESC_LIST_DETAILS +
                                        `?jd_id=${sublist.subCategory_id}`
                                      );
                                      setIdJOBSCategory(sublist.subCategory_id);
                                      setAddMonthsData({
                                        ...addMonthsData,
                                        supervision_id: sublist.subCategory_id,
                                      });
                                      // setSubCatId(sublist.subCategory_id);
                                    }}
                                  >
                                    {sublist.subCategory_title}
                                  </Nav.Link>
                                </Nav.Item>
                              );
                            })}
                        </Nav>
                      </div>
                      <div className="">
                        <Tab.Content>
                          {/* ==== JD TRUSTEE ==== */}
                          <div className="add-btn">
                            <button
                              onClick={() =>
                                handleAddTrusteeShow(idJOBSCategory)
                              }
                            >
                              Add +
                            </button>

                            <button
                              onClick={() => {
                                // handleClick();
                                localStorage.setItem("subCat", 2);
                                // handleAddTrusteeShow(idJOBSCategory);
                                navigate("/Assessment", {
                                  state: { data: subCatId },
                                });
                              }}
                              className="ms-2"
                            >
                              Assessment
                            </button>
                          </div>
                          <Tab.Pane eventKey={idJOBSCategory}>
                            <div className="row jd-main">
                              {loader ? (
                                <FormLoader />
                              ) : (
                                <>
                                  {jobList && jobList.length > 0 ? (
                                    jobList.map((ele, index) => {
                                      return (
                                        <div className="col-md-4">
                                          <div
                                            className=" jd-cards"
                                            key={"job" + index}
                                          >
                                            <div className="add-btn">
                                              <button
                                                className="delete"
                                                onClick={() => {
                                                  setDeleteId(ele.group_id);
                                                  setUrlDelete(
                                                    ApiConfig.JOBDESC_DELETE +
                                                    `?group_id=${ele.group_id}`
                                                  );
                                                  handleDeleteShow();
                                                }}
                                              >
                                                <img src={ic_delete} alt="" />
                                              </button>
                                              <button
                                                onClick={() => {
                                                  setWorkshoID(ele.group_id);
                                                  featureListApi(
                                                    ApiConfig.JOBDESC_EDIT +
                                                    `?group_id=${ele.group_id}`
                                                  );
                                                  setIdEdit("edit");
                                                  handleAddTrusteeShow();
                                                  //
                                                }}
                                              >
                                                <img src={ic_edit} alt="" />
                                              </button>
                                            </div>
                                            {ele?.list.length > 0 &&
                                              ele.list.map((item, index) => {
                                                return (
                                                  <div key={"testing" + index}>
                                                    <h6>{item.title}</h6>
                                                    <p>{item.description}</p>
                                                    <hr />
                                                  </div>
                                                );
                                              })}

                                            <hr />
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <h5 className="text-center text-danger">
                                      No Data Found
                                    </h5>
                                  )}
                                </>
                              )}
                            </div>
                          </Tab.Pane>
                          <Modal
                            show={AddTrustee}
                            onHide={handleAddTrusteeClose}
                            size="xl"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <Modal.Header closeButton>
                              {idJOBS == 1 && (
                                <Modal.Title>
                                  {idEdit === "edit"
                                    ? "Update Trustee"
                                    : "Add Trustee"}
                                </Modal.Title>
                              )}
                              {idJOBS == 2 && (
                                <Modal.Title>
                                  {idEdit === "edit"
                                    ? "Update PRINCIPAL"
                                    : "Add PRINCIPAL"}
                                </Modal.Title>
                              )}
                              {idJOBS == 3 && (
                                <Modal.Title>
                                  {idEdit === "edit"
                                    ? "Update TEACHER"
                                    : "Add TEACHER"}
                                </Modal.Title>
                              )}
                              {idJOBS == 4 && (
                                <Modal.Title>
                                  {idEdit === "edit"
                                    ? "Update Supervisor"
                                    : "Add SUPERVISION"}
                                </Modal.Title>
                              )}
                              {idJOBS == 5 && (
                                <Modal.Title>
                                  {idEdit === "edit"
                                    ? "Update STUDENTS"
                                    : "Add STUDENTS"}
                                </Modal.Title>
                              )}
                            </Modal.Header>
                            <Modal.Body>
                              <Form
                                noValidate
                                validated={validated}
                                onSubmit={(e) => handleSubmit(e, "jobdetails")}
                              >
                                <div className="feature-wrapper">
                                  <div className="row">
                                    <div className="col-md-3 mb-4">
                                      <Form.Label>Title English</Form.Label>
                                      <Form.Control
                                        required
                                        type="text"
                                        name="english_title"
                                        value={addJobDetails.english_title}
                                        onChange={handleChangeJob}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Title English
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-3 mb-4">
                                      <Form.Label>Title Urdu</Form.Label>
                                      <Form.Control
                                        required
                                        type="text"
                                        name="urdu_title"
                                        value={addJobDetails.urdu_title}
                                        onChange={handleChangeJob}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Title Urdu
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-3 mb-4">
                                      <Form.Label>Title Hindi</Form.Label>
                                      <Form.Control
                                        required
                                        type="text"
                                        name="hindi_title"
                                        value={addJobDetails.hindi_title}
                                        onChange={handleChangeJob}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Title Hindi
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-3 mb-4">
                                      <Form.Label>
                                        Title Transliteration
                                      </Form.Label>
                                      <Form.Control
                                        required
                                        type="text"
                                        name="translitration_title"
                                        value={
                                          addJobDetails.translitration_title
                                        }
                                        onChange={handleChangeJob}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Title Transliteration
                                      </Form.Control.Feedback>
                                    </div>

                                    <div className="col-md-6 mb-4">
                                      <Form.Label>
                                        Description English
                                      </Form.Label>
                                      <Form.Control
                                        required
                                        as="textarea"
                                        rows={3}
                                        name="english_desc"
                                        value={addJobDetails.english_desc}
                                        onChange={handleChangeJob}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Description English
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                      <Form.Label>Description Urdu</Form.Label>
                                      <Form.Control
                                        required
                                        as="textarea"
                                        rows={3}
                                        name="urdu_desc"
                                        value={addJobDetails.urdu_desc}
                                        onChange={handleChangeJob}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Description Urdu
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                      <Form.Label>Description Hindi</Form.Label>
                                      <Form.Control
                                        required
                                        as="textarea"
                                        rows={3}
                                        name="hindi_desc"
                                        value={addJobDetails.hindi_desc}
                                        onChange={handleChangeJob}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Description Hindi
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                      <Form.Label>
                                        Description Transliteration
                                      </Form.Label>
                                      <Form.Control
                                        required
                                        as="textarea"
                                        rows={3}
                                        name="translitration_desc"
                                        value={
                                          addJobDetails.translitration_desc
                                        }
                                        onChange={handleChangeJob}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Description Transliteration
                                      </Form.Control.Feedback>
                                    </div>
                                    <hr />
                                  </div>

                                  <div className="buttons-main">
                                    {/* <button className="cancel-btn">Cancel</button> */}
                                    <button className="submit-btn">
                                      {idEdit == "edit" ? "Update" : "Submit"}
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            </Modal.Body>
                          </Modal>
                        </Tab.Content>
                      </div>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            </Tab.Pane>
            {/* ============ Lecture ========== */}
            <Tab.Pane eventKey="4"> </Tab.Pane>
            {/* ======== Supervision ======== */}
            <Tab.Pane eventKey="5">
              <div className="jobDescriptio-main">
                <div className="wrapper">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                    <div className="row">
                      <div className="">
                        <Nav variant="pills" className="flex-row">
                          {subFourCategory &&
                            subFourCategory.length > 0 &&
                            subFourCategory.map((sublist, index) => {
                              return (
                                <Nav.Item key={"ploce" + index}>
                                  <Nav.Link
                                    eventKey={sublist.subCategory_id}
                                    onClick={() => {
                                      localStorage.setItem("assessmentId", sublist.assessmentId);
                                      featureListApi(
                                        ApiConfig.SUPERVISION_MONTH_DETAILS +
                                        `?supervision_id=${sublist.subCategory_id}&month_id=${idMonth}`
                                      );
                                      setIdJOBSCategory(sublist.subCategory_id);
                                      setAddMonthsData({
                                        ...addMonthsData,
                                        supervision_id: sublist.subCategory_id,
                                      });
                                    }}
                                  >
                                    {sublist.subCategory_title}
                                  </Nav.Link>
                                </Nav.Item>
                              );
                            })}
                        </Nav>
                      </div>
                      <hr />
                      <div className="" id="month-tab">
                        <Tab.Content>
                          {/* ==== JD TRUSTEE ==== */}
                          <Tab.Pane eventKey={idJOBSCategory}>
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey={idMonth}
                            >
                              <div className="row">
                                <div className="">
                                  <Nav variant="pills" className="flex-row">
                                    {months &&
                                      months.length > 1 &&
                                      months.map((month, index) => {
                                        return (
                                          <Nav.Item key={"nav" + index}>
                                            <Nav.Link
                                              eventKey={month.month_id}
                                              // eventKey="1"
                                              onClick={() => {
                                                featureListApi(
                                                  ApiConfig.SUPERVISION_MONTH_DETAILS +
                                                  `?supervision_id=${idJOBSCategory}&month_id=${month.month_id}`
                                                );
                                                setIdMonth(month.month_id);
                                                setAddMonthsData({
                                                  ...addMonthsData,
                                                  month_id: month.month_id,
                                                });
                                                setConditionApilist(
                                                  ApiConfig.SUPERVISION_MONTH_DETAILS +
                                                  `?supervision_id=${idJOBSCategory}&month_id=${month.month_id}`
                                                );
                                              }}
                                            >
                                              Month {month.month_id}
                                            </Nav.Link>
                                          </Nav.Item>
                                        );
                                      })}
                                  </Nav>
                                </div>

                                <div className="" id="month-tab">
                                  <Tab.Content>
                                    {/* ==== JD TRUSTEE ==== */}
                                    <Tab.Pane eventKey={idMonth}>
                                      <div className="month-list">
                                        <div className="add-btn">
                                          <button
                                            onClick={handleAddMonthListShow}
                                          >
                                            Add +
                                          </button>
                                        </div>
                                        {loader ? (
                                          <FormLoader />
                                        ) : (
                                          <div className="row month-list-card-main">
                                            {superMonthsData &&
                                              superMonthsData.length > 0 ? (
                                              superMonthsData.map(
                                                (months, index) => {
                                                  return (
                                                    <div className="col-md-4">
                                                      <div
                                                        className="month-list-card"
                                                        key={"super" + index}
                                                      >
                                                        <div className="add-btn">
                                                          <button
                                                            className="delete "
                                                            onClick={() => {
                                                              setDeleteId(
                                                                months.group_id
                                                              );
                                                              setUrlDelete(
                                                                ApiConfig.SUPERVISION_MONTH_DELETE +
                                                                `?group_id=${months.group_id}`
                                                              );
                                                              handleDeleteShow();
                                                            }}
                                                          >
                                                            <img
                                                              src={ic_delete}
                                                              alt=""
                                                            />
                                                          </button>
                                                          <button
                                                            onClick={() => {
                                                              setIdEdit("edit");
                                                              setWorkshoID(
                                                                months.group_id
                                                              );
                                                              featureListApi(
                                                                ApiConfig.SUPERVISION_MONTH_EDIT +
                                                                `?group_id=${months.group_id}`
                                                              );

                                                              handleAddMonthListShow();
                                                            }}
                                                          >
                                                            <img
                                                              src={ic_edit}
                                                              alt=""
                                                            />
                                                          </button>
                                                        </div>
                                                        {months?.list.length >
                                                          0 &&
                                                          months?.list.map(
                                                            (ele, index) => {
                                                              return (
                                                                <>
                                                                  <p
                                                                    key={
                                                                      "chgfsdfsfs" +
                                                                      index
                                                                    }
                                                                  >
                                                                    {ele.notes}
                                                                  </p>

                                                                  <hr />
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <h5 className="text-center text-danger">
                                                No Data Found
                                              </h5>
                                            )}
                                            <hr />
                                          </div>
                                        )}
                                      </div>
                                    </Tab.Pane>
                                  </Tab.Content>
                                </div>
                              </div>
                            </Tab.Container>
                          </Tab.Pane>
                        </Tab.Content>

                        <Modal
                          show={AddMonthList}
                          onHide={handleAddMonthListClose}
                          size="lg"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>
                              {idEdit == "edit"
                                ? "Update Month List Notes"
                                : "Add Month List Notes"}
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form
                              noValidate
                              validated={validated}
                              onSubmit={(e) => handleSubmit(e, "Supervision")}
                            >
                              <div className="feature-wrapper">
                                <div className="row">
                                  <div className="col-md-12 mb-4">
                                    <Form.Label>Urdu Notes</Form.Label>
                                    <Form.Control
                                      required
                                      type="text"
                                      as="textarea"
                                      rows={3}
                                      name="notes_urdu"
                                      value={addMonthsData.notes_urdu}
                                      onChange={handleChangeMonths}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                      Please Enter Urdu Notes
                                    </Form.Control.Feedback>
                                  </div>{" "}
                                  <div className="col-md-12 mb-4">
                                    <Form.Label>hindi Notes</Form.Label>
                                    <Form.Control
                                      required
                                      type="text"
                                      as="textarea"
                                      rows={3}
                                      name="notes_hindi"
                                      value={addMonthsData.notes_hindi}
                                      onChange={handleChangeMonths}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please Enter hindi Notes
                                    </Form.Control.Feedback>
                                  </div>{" "}
                                  <div className="col-md-12 mb-4">
                                    <Form.Label>English Notes</Form.Label>
                                    <Form.Control
                                      required
                                      type="text"
                                      as="textarea"
                                      rows={3}
                                      name="notes_english"
                                      value={addMonthsData.notes_english}
                                      onChange={handleChangeMonths}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please Enter English Notes
                                    </Form.Control.Feedback>
                                  </div>
                                  <div className="col-md-12 mb-4">
                                    <Form.Label>
                                      Transliteration Notes
                                    </Form.Label>
                                    <Form.Control
                                      required
                                      type="text"
                                      as="textarea"
                                      rows={3}
                                      name="notes_lit"
                                      value={addMonthsData.notes_lit}
                                      onChange={handleChangeMonths}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Please Enter Transliteration Notes
                                    </Form.Control.Feedback>
                                  </div>
                                </div>
                                <div className="buttons-main">
                                  <button className="submit-btn">
                                    {idEdit == "edit" ? "Update" : "Submit"}
                                  </button>
                                </div>
                              </div>
                            </Form>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            </Tab.Pane>
            {/* ======== Exam Paper ======== */}
            <Tab.Pane eventKey="6">
              <div className="jobDescriptio-main">
                <div className="wrapper">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                    <div className="row">
                      <div className="">
                        <Nav variant="pills" className="flex-row">
                          {examNumber &&
                            examNumber.length > 0 &&
                            examNumber.map((numsystem, index) => {
                              return (
                                <Nav.Item key={"number" + index}>
                                  <Nav.Link
                                    onClick={() => {
                                      setConditionApilist(
                                        ApiConfig.EXAM_PAPER_LIST +
                                        `?exam_id=${numsystem.number_id}`
                                      );
                                      featureListApi(
                                        ApiConfig.EXAM_PAPER_LIST +
                                        `?exam_id=${numsystem.number_id}`
                                      );
                                      setIdNumber(numsystem.number_id);
                                      setAddExamPaper({
                                        ...addExamPaper,
                                        exam_id: numsystem.number_id,
                                      });
                                    }}
                                    eventKey={numsystem.number_id}
                                  >
                                    {numsystem.number_title}
                                  </Nav.Link>
                                </Nav.Item>
                              );
                            })}
                        </Nav>
                      </div>
                      <div className="">
                        <Tab.Content>
                          {/* ==== ONE ==== */}
                          <Tab.Pane eventKey={idNumber}>
                            <div className="exam-paper-main">
                              <div className="add-btn">
                                <button
                                  onClick={handleAddCategoryShow}
                                  className="me-2"
                                >
                                  Add Category +
                                </button>
                                <button onClick={handleAddPaperShow}>
                                  Add +
                                </button>
                              </div>
                              {loader ? (
                                <FormLoader />
                              ) : (
                                <div className="exam-paper row">
                                  <h5>Exam Paper {idNumber}</h5>
                                  {examPaperData &&
                                    examPaperData?.length > 0 ? (
                                    examPaperData.map((element) => {
                                      return (
                                        <div className="col-md-4">
                                          <div className=" paper-section">
                                            <div className="d-flex justify-content-between ">
                                              <h6>{element.title}</h6>
                                              <div>
                                                <button
                                                  className="cat-delete-btn"
                                                  onClick={() => {
                                                    setDeleteId(
                                                      element.years_title_id
                                                    );
                                                    setUrlDelete(
                                                      ApiConfig.EXAM_PAPER_DELETE_CATEGORY +
                                                      `?years_title_id=${element.years_title_id}`
                                                    );
                                                    handleDeleteShow();
                                                  }}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    class="bi bi-x"
                                                    viewBox="0 0 16 16"
                                                  >
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                  </svg>
                                                </button>
                                              </div>
                                            </div>

                                            {element?.years.length > 0 &&
                                              element?.years.map(
                                                (testCard, index) => {
                                                  return (
                                                    <div
                                                      className="paper_year_main"
                                                      key={"okly" + index}
                                                    >
                                                      <div>
                                                        <a
                                                          href={
                                                            testCard?.years_link
                                                          }
                                                          download
                                                          target="_blank"
                                                        >
                                                          {testCard?.year}
                                                        </a>
                                                      </div>
                                                      <div className="add-btn">
                                                        <button
                                                          className="delete "
                                                          onClick={() => {
                                                            setDeleteId(
                                                              testCard.years_title_id
                                                            );
                                                            setUrlDelete(
                                                              ApiConfig.EXAM_PAPER_DELETE +
                                                              `?year_id=${testCard.year_id}`
                                                            );
                                                            handleDeleteShow();
                                                          }}
                                                        >
                                                          <img
                                                            src={ic_delete}
                                                            alt=""
                                                          />
                                                        </button>

                                                        <button className="view">
                                                          <a
                                                            href={
                                                              testCard?.years_link
                                                            }
                                                            target="_blank"
                                                            download
                                                          >
                                                            <img
                                                              src={ic_eyes}
                                                              alt=""
                                                            />
                                                          </a>
                                                        </button>



                                                        <button
                                                          onClick={() => {
                                                            setIdEdit("edit");
                                                            handleAddPaperShow();
                                                            setWorkshoID(
                                                              testCard.year_id
                                                            );
                                                            // featureListApi(
                                                            //   ApiConfig.EXAM_PAPER_EDIT +
                                                            //     `?year_id=${testCard.year_id}`
                                                            // );
                                                            examFilter(
                                                              testCard.year_id,
                                                              element.years_title_id
                                                            );
                                                          }}
                                                        >
                                                          <img
                                                            src={ic_edit}
                                                            alt=""
                                                          />
                                                        </button>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}

                                            <hr />
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <h5 className="text-center text-danger">
                                      No Data Found
                                    </h5>
                                  )}
                                </div>
                              )}
                            </div>
                          </Tab.Pane>

                          {/* ============= Paper Modal =================== */}

                          <Modal
                            show={AddPaper}
                            onHide={handleAddPaperClose}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>
                                {idEdit == "edit"
                                  ? "Update Expam Paper"
                                  : "Add Expam Paper"}{" "}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Form
                                noValidate
                                validated={validated}
                                onSubmit={(e) => handleSubmit(e, "ExamPaper")}
                              >
                                <div className="feature-wrapper">
                                  <div className="row">
                                    <div className="col-md-6 mb-4">
                                      <Form.Label>Select Category</Form.Label>
                                      <Form.Select
                                        required
                                        aria-label="Default select example"
                                        value={addExamPaper.years_title_id}
                                        onChange={(e) => {
                                          setAddExamPaper({
                                            ...addExamPaper,
                                            years_title_id: Number(
                                              e.target.value
                                            ),
                                          });
                                        }}
                                      >
                                        <option value=""> Please Select</option>
                                        {listCategory?.map((ele, index) => {
                                          return (
                                            <option
                                              value={ele.years_title_id}
                                              key={"oktr" + index}
                                            >
                                              {ele.title}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                      <Form.Control.Feedback type="invalid">
                                        Please Select Category
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                      <Form.Label>Paper Year</Form.Label>
                                      <Form.Control
                                        required
                                        type="text"
                                        value={addExamPaper.year}
                                        onChange={(e) =>
                                          setAddExamPaper({
                                            ...addExamPaper,
                                            year: e.target.value,
                                          })
                                        }
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Paper Year
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                      <Form.Label>Paper URL</Form.Label>
                                      <Form.Control
                                        required
                                        type="text"
                                        value={addExamPaper.years_link}
                                        onChange={(e) =>
                                          setAddExamPaper({
                                            ...addExamPaper,
                                            years_link: e.target.value,
                                          })
                                        }
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Paper URL
                                      </Form.Control.Feedback>
                                    </div>
                                  </div>
                                  <div className="buttons-main">
                                    {/* <button className="cancel-btn">Cancel</button> */}

                                    <button className="submit-btn">
                                      {idEdit == "edit" ? "Update" : "Submit"}
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            </Modal.Body>
                          </Modal>

                          {/* ============= Categort Modal =================== */}
                          <Modal
                            show={AddCategory}
                            onHide={handleAddCategoryClose}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Add Category</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Form
                                noValidate
                                validated={validated}
                                onSubmit={(e) => handleSubmit(e, "addCategory")}
                              >
                                <div className="feature-wrapper">
                                  <div className="row">
                                    <div className="col-md-12 mb-4">
                                      <Form.Label>Add Category</Form.Label>
                                      <Form.Control
                                        required
                                        type="text"
                                        onChange={(e) =>
                                          setAddExamCategory({
                                            ...addExamCategory,
                                            title: e.target.value,
                                          })
                                        }
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Add Category
                                      </Form.Control.Feedback>
                                    </div>
                                  </div>
                                  <div className="buttons-main">
                                    {/* <button className="cancel-btn">Cancel</button> */}

                                    <button className="submit-btn">
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            </Modal.Body>
                          </Modal>
                        </Tab.Content>
                      </div>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </main>
  );
};

export default Maktab;
