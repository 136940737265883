import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import thumb1 from "../../../assets/images/video_thumbnails/1.png";
//Datatable Modules

import { Link, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";

import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import ic_delete from "../../../assets/images/ic_delete.svg";
import ic_edit from "../../../assets/images/ic_edit.svg";
import ic_eyes from "../../../assets/images/ic_eyes.svg";
import quraan_kareem_cover_9_line from "../../../assets/images/quraan_kareem_cover_9_line.png";
import ic_nursery_tr_1 from "../../../assets/images/ic_nursery_tr_1.png";
import panch_min_ka_madrasa from "../../../assets/images/panch_min_ka_madrasa.png";
import Modal from "react-bootstrap/Modal";
import {
  PostCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  putCallWithAuthTokenWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../../api/ApiServices";
import ApiConfig, { IMAGE_URL } from "../../../api/ApiConfig";
import { ToastMsg } from "../../TostMsg";
import Syllabus from "./Syllabus";
import FormLoader from "../../FormLoader";
import { sendAsFormData } from "../../commonFun";
const Publication = () => {
  const navigate = useNavigate();

  const { sidebar, adminData } = useContext(AppContext);

  const [AddNewGroup, setAddNewGroup] = useState(0);
  const [isImage, setIsImage] = useState("");

  const [idEdit, setIdEdit] = useState(false);
  const [imageWidth, setImageWidth] = useState("100%");

  const [addQuraan, setAddQuraan] = useState(false);
  const handleAddQuraanClose = () => setAddQuraan(false);
  const handleAddQuraanShow = () => setAddQuraan(true);

  const [addSyllabus, setAddSyllabus] = useState(false);
  const handleAddSyllabusClose = () => setAddSyllabus(false);
  const handleAddSyllabusShow = () => setAddSyllabus(true);

  const [addCourse, setAddCourse] = useState(false);
  const handleAddCourseClose = () => setAddCourse(false);
  const handleAddCourseShow = () => setAddCourse(true);

  const [addOtherBooks, setAddOtherBooks] = useState(false);
  const handleAddOtherBooksClose = () => setAddOtherBooks(false);
  const handleAddOtherBooksShow = () => setAddOtherBooks(true);

  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);

  const [MainVideo, setMainVideo] = useState(false);
  const handleMainVideoClose = () => setMainVideo(false);
  const handleMainVideoShow = () => setMainVideo(true);

  const [validated, setValidated] = useState(false);
  const [loader, setLoader] = useState(false);
  const [vedioApiList, setvedioApiList] = useState([]);
  const [videoEditData, setVideoEditData] = useState({
    subcategory_media_VideoName_hindi: "",
    subcategory_media_VideoPath_hindi: "",
    subcategory_media_VideoDuaration_hindi: "",
    subcategory_media_VideoName_lit: " ",
    subcategory_media_VideoPath_lit: "",
    subcategory_media_VideoDuaration_lit: " ",
    subcategory_media_VideoName_urdu: "",
    subcategory_media_VideoPath_urdu: "",
    subcategory_media_VideoDuaration_urdu: "",
    subcategory_media_VideoName_english: " ",
    subcategory_media_VideoPath_english: "",
    subcategory_media_VideoDuaration_english: "",
    subcategory_id: 2,
  });

  const [isEdit, setIsEdit] = useState("");
  const [isEditBooks, setIsEditBooks] = useState("");
  const [isEditCourse, setIsEditCourse] = useState("");

  const [quraneKareemList, setQuraneKareemList] = useState([]);
  const [otherBooksList, setOtherBooksList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [assistId, setAssistId] = useState(0);
  const [courseId, setCourseId] = useState(0);
  const [assistIdOtherBook, setAssistIdOtherBook] = useState(0);

  const [courseObj, setCourseObj] = useState({
    secondary_title_hindi: "",
    secondary_title_english: "",
    secondary_title_lit: "",
    secondary_title_urdu: "",
    course_desc_hindi: "",
    course_desc_english: "",
    course_desc_lit: "",
    course_desc_urdu: "",
    course_title_hindi: "",
    course_title_english: "",
    course_title_lit: "",
    course_title_urdu: "",
    file: "",
    // link_one : ""
  });

  // Qurane Kareem section Add Quran
  const [addQuraanObj, setAddQuraanObj] = useState({
    assist_second_heading_hindi: "",
    assist_second_heading_english: "",
    assist_second_heading_lit: "",
    assist_second_heading_urdu: "",

    ebookLinkUrdu: "",
    ebookLinkEnglish: "",
    ebookLinkTranslit: "",
    ebookLinkHindi: "",

    assist_desc_urdu: "",
    assist_title_urdu: "",
    assist_title_lit: "",
    assist_desc_lit: "",
    assist_desc_english: "",
    assist_title_english: "",
    file: "",
    assist_option_id: 1,
    assist_desc_hindi: "",
    assist_title_hindi: "",

    buy_now_link: "",
    open_app_link: "",
    play_store_link: "",
    app_store_link: "",

    option_assis_img: "",
    option_assist_main_id: 0,
  });
  console.log("addQuraanObj", addQuraanObj.ebookLinkHindi);

  // Other Books section Add Others Books
  const [addOtherBooksObj, setAddOtherBooksObj] = useState({
    assist_second_heading_hindi: "",
    assist_second_heading_english: "",
    assist_second_heading_lit: "",
    assist_second_heading_urdu: "",

    ebookLinkUrdu: "",
    ebookLinkEnglish: "",
    ebookLinkTranslit: "",
    ebookLinkHindi: "",

    assist_desc_urdu: "",
    assist_title_urdu: "",
    assist_title_lit: "",
    assist_desc_lit: "",
    assist_desc_english: "",
    assist_title_english: "",
    file: "",
    assist_option_id: 3,
    assist_desc_hindi: "",
    assist_title_hindi: "",
    // link_two: "",
    // link_one: "",

    buy_now_link: "",
    open_app_link: "",
    play_store_link: "",
    app_store_link: "",

    option_assis_img: "",
    option_assist_main_id: 0,
  });

  const handleChangeVideoEdit = (event) => {
    const { name, value } = event.target;
    const videoEditDatacopy = { ...videoEditData };
    videoEditDatacopy[name] = value;
    setVideoEditData(videoEditDatacopy);
  };

  const handleSubmit = (event, section) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (section == "updateVideos") {
        updateMediaApi(event);
      }
      if (section == "quraan") {
        submitAddQuraan(event);
      }
      if (section == "syllabus") {
        submitAddCourse(event);
      }
      if (section == "books") {
        submitAddOtherBooks(event)
      }
    }

    setValidated(true);
  };
  useEffect(() => {
    videoApiList();
  }, []);
  const videoApiList = () => {
    setLoader(true);
    // ?subcategory_id=3&school_workshop_ten_step_id=10
    simpleGetCallWithErrorResponse(
      ApiConfig.GET_MEDIEA + `?subcategory_id=${7}`
    )
      .then((res) => {
        if (res.json && res.json?.result === true) {
          setLoader(false);

          setvedioApiList(res.json.media);
          setVideoEditData(res.json.editmedia);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);
        console.log(err);
        // ToastMsg("error", "something went wrong");
      });
  };
  const updateMediaApi = (e) => {
    e.preventDefault();
    handleMainVideoClose();
    putCallWithAuthTokenWithErrorResponse(
      ApiConfig.UPDATE_MEDIEA + `?subcategory_id=${7}`,
      JSON.stringify({
        ...videoEditData,
        modified_by_id: adminData.user_id,
      })
    )
      .then((res) => {
        if (res.json && res.json?.result === true) {
          ToastMsg("success", res.json.message);
          videoApiList();
          // tenStepsListApi(addBtn);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  // Qurane Kareem List
  const quranekareemListApi = (urldata) => {
    setLoader(true);
    simpleGetCallWithErrorResponse(urldata)
      .then((res) => {
        setLoader(false);
        setQuraneKareemList(res.json.data);
        setOtherBooksList(res.json.data);
      })
      .catch((err) => {
        setLoader(false);
        ToastMsg("error", "something went wrong");
      });
  };

  const courseListApi = (urldata) => {
    setLoader(true);
    simpleGetCallWithErrorResponse(urldata)
      .then((res) => {
        console.log("Response Course=> ", res);
        setLoader(false);
        setCourseList(res.json.data);
      })
      .catch((err) => {
        setLoader(false);
        ToastMsg("error", "something went wrong");
      });
  };

  // Qurane Kareem Assist Delete
  const deleteAssist = (id) => {
    setAssistId(id);
  };

  // Other Books Assist Delete
  const deleteOtherBookCard = (id) => {
    setAssistIdOtherBook(id);
  };

  // Course Assist Delete
  const deleteCourse = (id) => {
    setCourseId(id);
  };

  // Delete Assist
  const deleteAssistPopUp = () => {
    handleDeleteClose();
    setLoader(true);
    assistId
      ? simpleGetCallWithErrorResponse(
        ApiConfig.ASSIST_DELETE_ID + `?assist_main_id=${assistId}`
      )
        .then((res) => {
          setLoader(false);
          handleDeleteClose();
          ToastMsg("success", "Successfully Deleted");
          quranekareemListApi(
            ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${1}`
          );
          // setQuraneKareemList(res.json.data);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
          quranekareemListApi(
            ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${1}`
          );
          // ToastMsg("error", "something went wrong");
        })
      : assistIdOtherBook
        ? simpleGetCallWithErrorResponse(
          ApiConfig.ASSIST_DELETE_ID + `?assist_main_id=${assistIdOtherBook}`
        )
          .then((res) => {
            setLoader(false);
            handleDeleteClose();
            ToastMsg("success", "Successfully Deleted");
            quranekareemListApi(
              ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${3}`
            );
            // setQuraneKareemList(res.json.data);
          })
          .catch((err) => {
            console.log(err);
            setLoader(false);
            quranekareemListApi(
              ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${3}`
            );
            // ToastMsg("error", "something went wrong");
          })
        : simpleGetCallWithErrorResponse(
          ApiConfig.SYLLABUS_DELETE_ID + `?group_id=${courseId}`
        )
          .then((res) => {
            setLoader(false);
            handleDeleteClose();
            ToastMsg("success", "Successfully Deleted");
            courseListApi(ApiConfig.SYLLABUS_LIST);
          })
          .catch((err) => {
            console.log(err);
            setLoader(false);
            courseListApi(ApiConfig.SYLLABUS_LIST);
          });
  };

  useEffect(() => {
    quranekareemListApi(
      ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${1}`
    );
  }, []);

  // Add Quraan Assist
  const submitAddQuraan = (e) => {
    e.preventDefault();
    handleAddQuraanClose(true);
    setLoader(true);

    !isEdit
      ? postWithAuthCallWithErrorResponse(
        ApiConfig.ADD_QURAAN_FORM,
        sendAsFormData(addQuraanObj)
      )
        .then((res) => {
          setLoader(false);
          if (res.json.result) {
            ToastMsg("success", "Successfully Added");
            quranekareemListApi(
              ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${1}`
            );
          } else {
            // ToastMsg("error", "Something Went Wrong")
            quranekareemListApi(
              ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${1}`
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
      : postWithAuthCallWithErrorResponse(
        ApiConfig.UPDATE_ASSIST_DATA,
        sendAsFormData(addQuraanObj)
      )
        .then((res) => {
          setLoader(false);
          if (res.json.result) {
            ToastMsg("success", "Successfully Updated");
            quranekareemListApi(
              ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${1}`
            );
          } else {
            // ToastMsg("error", "Something Went Wrong")
            quranekareemListApi(
              ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${1}`
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  // Add Course
  const submitAddCourse = (e) => {
    e.preventDefault();
    handleAddCourseClose(true);
    setLoader(true);

    !isEditCourse
      ? postWithAuthCallWithErrorResponse(
        ApiConfig.ADD_SYLLABUS_FORM,
        sendAsFormData(courseObj)
      )
        .then((res) => {
          setLoader(false);
          if (res.json.result) {
            ToastMsg("success", "Successfully Added");
            courseListApi(ApiConfig.SYLLABUS_LIST);
          } else {
            courseListApi(ApiConfig.SYLLABUS_LIST);
          }
        })
        .catch((err) => {
          console.log(err);
        })
      : postWithAuthCallWithErrorResponse(
        ApiConfig.UPDATE_SYLLABUS_FORM,
        sendAsFormData(courseObj)
      )
        .then((res) => {
          setLoader(false);
          if (res.json.result) {
            ToastMsg("success", "Successfully Updated");
            courseListApi(ApiConfig.SYLLABUS_LIST);
          } else {
            courseListApi(ApiConfig.SYLLABUS_LIST);
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  // Get Update Data of Assist Qurane Kareem
  const getUpdateDataId = (id) => {
    simpleGetCallWithErrorResponse(
      ApiConfig.GET_EDIT_DATA + `?assist_main_id=${id}`
    )
      .then((res) => {
        console.log("res Publications", res)
        let data = res.json.assist;
        let assist_record = res.json.assist_data;

        function findLanguageData(languageId) {
          return assist_record.find(record => record.language_id === languageId);
        }
        setAddQuraanObj({
          assist_desc_urdu: data.assist_desc_urdu,
          assist_title_urdu: data.assist_title_urdu,
          assist_title_lit: data.assist_title_lit,
          assist_desc_lit: data.assist_desc_lit,
          assist_desc_english: data.assist_desc_english,
          assist_title_english: data.assist_title_english,
          // file: data.file,
          file: data.file,
          assist_option_id: 1,
          assist_desc_hindi: data.assist_desc_hindi,
          assist_title_hindi: data.assist_title_hindi,
          // link_two: data.link_two,
          // link_one: data.link_one,
          open_app_link: assist_record[0]?.open_app_link || "",
          buy_now_link: assist_record[0]?.buy_now_link || "",
          play_store_link: assist_record[0]?.play_store_link || "", 
          app_store_link: assist_record[0]?.app_store_link || "", 
          
          option_assis_img: "",
          option_assist_main_id: id,

          assist_second_heading_hindi: findLanguageData(4)?.assist_second_heading || '',
          assist_second_heading_english: findLanguageData(1)?.assist_second_heading || '',
          assist_second_heading_lit: findLanguageData(3)?.assist_second_heading || '',
          assist_second_heading_urdu: findLanguageData(2)?.assist_second_heading || '',

          ebookLinkEnglish: findLanguageData(1)?.e_book_link || "",
          ebookLinkUrdu: findLanguageData(2)?.e_book_link || "",
          ebookLinkTranslit: findLanguageData(3)?.e_book_link || "",
          ebookLinkHindi: findLanguageData(4)?.e_book_link || "",
          
        });
      })
      .catch((err) => {
        console.log(err);
        // ToastMsg("error", "something went wrong");
      });
  };

  // Add Other Books
  const submitAddOtherBooks = (e) => {
    e.preventDefault();
    handleAddOtherBooksClose(true);
    setLoader(true);

    !isEditBooks
      ? postWithAuthCallWithErrorResponse(
        ApiConfig.ADD_QURAAN_FORM,
        sendAsFormData(addOtherBooksObj)
      )
        .then((res) => {
          setLoader(false);
          if (res.json.result) {
            ToastMsg("success", "Successfully Added");
            quranekareemListApi(
              ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${3}`
            );
          } else {
            // ToastMsg("error", "Something Went Wrong")
            quranekareemListApi(
              ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${3}`
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
      : postWithAuthCallWithErrorResponse(
        ApiConfig.UPDATE_ASSIST_DATA,
        sendAsFormData(addOtherBooksObj)
      )
        .then((res) => {
          setLoader(false);
          if (res.json.result) {
            ToastMsg("success", "Successfully Updated");
            quranekareemListApi(
              ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${3}`
            );
          } else {
            // ToastMsg("error", "Something Went Wrong")
            quranekareemListApi(
              ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${3}`
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  // Get Update Data of Assist Qurane Kareem
  const getUpdateDataIdOtherBooks = (id) => {
    simpleGetCallWithErrorResponse(
      ApiConfig.GET_EDIT_DATA + `?assist_main_id=${id}`
    )
      .then((res) => {
        let data = res.json.assist;
        let assist_record = res.json.assist_data;
        assist_record.sort((a, b) => a.language_id - b.language_id);
        console.log("assist_record", assist_record);

        setAddOtherBooksObj({
          assist_title_urdu: data.assist_title_urdu,
          assist_title_lit: data.assist_title_lit,
          assist_title_english: data.assist_title_english,
          assist_title_hindi: data.assist_title_hindi,

          assist_desc_urdu: data.assist_desc_urdu,
          assist_desc_lit: data.assist_desc_lit,
          assist_desc_hindi: data.assist_desc_hindi,
          assist_desc_english: data.assist_desc_english,
          file: data.file,
          assist_option_id: 3,
          // link_two: assist_record[0]?.buy_now_link,
          // link_one: assist_record[0]?.open_app_link,
          open_app_link: assist_record[0]?.open_app_link || "",
          buy_now_link: assist_record[0]?.buy_now_link || "",
          play_store_link: assist_record[0]?.play_store_link || "", 
          app_store_link: assist_record[0]?.app_store_link || "", 

          option_assis_img: "",
          option_assist_main_id: id,

          assist_second_heading_english: assist_record[0]?.assist_second_heading || "",
          assist_second_heading_urdu: assist_record[1]?.assist_second_heading || "",
          assist_second_heading_lit: assist_record[2]?.assist_second_heading || "",
          assist_second_heading_hindi: assist_record[3]?.assist_second_heading || "",

          ebookLinkUrdu: assist_record[1]?.e_book_link || "",
          ebookLinkEnglish: assist_record[0]?.e_book_link || "",
          ebookLinkTranslit: assist_record[2]?.e_book_link || "",
          ebookLinkHindi: assist_record[3]?.e_book_link || "",
        });
      })
      .catch((err) => {
        console.log(err);
        // ToastMsg("error", "something went wrong");
      });
  };

  // Get Update Data of Course
  const getUpdateDataIdCourse = (id) => {
    simpleGetCallWithErrorResponse(
      ApiConfig.SYLLABUS_EDIT_ID + `?group_id=${id}`
    )
      .then((res) => {
        console.log("Edit Course", res);
        let data = res.json.syllabus;
        // let assist_record = res.json.assist_data;

        setCourseObj({
          secondary_title_hindi: data.secondary_title_hindi,
          secondary_title_english: data.secondary_title_english,
          secondary_title_lit: data.secondary_title_lit,
          secondary_title_urdu: data.secondary_title_urdu,
          course_desc_hindi: data.course_desc_hindi,
          course_desc_english: data.course_desc_english,
          course_desc_lit: data.course_desc_lit,
          course_desc_urdu: data.course_desc_urdu,
          course_title_hindi: data.course_title_hindi,
          course_title_english: data.course_title_english,
          course_title_lit: data.course_title_lit,
          course_title_urdu: data.course_title_urdu,
          file: data.file,
          group_id: id,
        });
      })
      .catch((err) => {
        console.log(err);
        // ToastMsg("error", "something went wrong");
      });
  };

  const sendCardData = (id) => {
    navigate("/Syllabus", { state: id });
  };

  const handleAddQuraanShowClear = () => {
    setIsImage("");
    setAddQuraanObj({
      assist_second_heading_hindi: "",
      assist_second_heading_english: "",
      assist_second_heading_lit: "",
      assist_second_heading_urdu: "",
      assist_desc_urdu: "",
      assist_title_urdu: "",
      assist_title_lit: "",
      assist_desc_lit: "",
      assist_desc_english: "",
      assist_title_english: "",
      file: "",
      assist_option_id: 1,
      assist_desc_hindi: "",
      assist_title_hindi: "",
      link_two: "",
      link_one: "",
      option_assis_img: "",
      option_assist_main_id: 0,
    })
    setValidated(false);
  }

  const handleAddCourseShowClear = () => {
    setIsImage("");
    setCourseObj({
      secondary_title_hindi: "",
      secondary_title_english: "",
      secondary_title_lit: "",
      secondary_title_urdu: "",
      course_desc_hindi: "",
      course_desc_english: "",
      course_desc_lit: "",
      course_desc_urdu: "",
      course_title_hindi: "",
      course_title_english: "",
      course_title_lit: "",
      course_title_urdu: "",
      file: "",
    });
    setValidated(false);
  }

  const handleAddOtherBookClear = () => {
    setIsImage("");
    setAddOtherBooksObj({
      assist_second_heading_hindi: "",
      assist_second_heading_english: "",
      assist_second_heading_lit: "",
      assist_second_heading_urdu: "",
      assist_desc_urdu: "",
      assist_title_urdu: "",
      assist_title_lit: "",
      assist_desc_lit: "",
      assist_desc_english: "",
      assist_title_english: "",
      file: "",
      assist_option_id: 3,
      assist_desc_hindi: "",
      assist_title_hindi: "",
      link_two: "",
      link_one: "",
      option_assis_img: "",
      option_assist_main_id: 0,
    })
    setValidated(false)
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    const addFeatureDatacopy = { ...addQuraanObj };
    addFeatureDatacopy[name] = value;
    setAddQuraanObj(addFeatureDatacopy);

    const addCourseDatacopy = { ...courseObj };
    addCourseDatacopy[name] = value;
    setCourseObj(addCourseDatacopy);

    const addOtherBookDatacopy = { ...addOtherBooksObj };
    addOtherBookDatacopy[name] = value;
    setAddOtherBooksObj(addOtherBookDatacopy);
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Publication</label>
        </div>
      </div>

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"
              onClick={deleteAssistPopUp}
            >
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>

      {/* ======================= END DELETE ========================== */}

      <div className="main-wrapper" id="maktab-main">
        <div className="main-all-video ">
          {vedioApiList?.length > 0 && (
            <div className="right">
              <div className="add-btn">
                <button onClick={handleMainVideoShow}>
                  <img src={ic_edit} alt="" />
                </button>
              </div>
            </div>
          )}

          {vedioApiList?.length > 0 ? (
            vedioApiList?.map((videolist, index) => {
              return (
                <div className="outer-main-video row" key={index}>
                  {videolist.sub_category_media.map((subitem, index) => {
                    return (
                      <div className="col-md-3 outer-main-video" key={index}>
                        <h6>
                          {(subitem.language_id === 4 && <span>Hindi</span>) ||
                            (subitem.language_id === 1 && (
                              <span>English</span>
                            )) ||
                            (subitem.language_id === 2 && <span>Urdu</span>) ||
                            (subitem.language_id === 3 && (
                              <span>Transliteration </span>
                            ))}{" "}
                          Video
                        </h6>
                        <div className="video">
                          <a
                            href={subitem.subcategory_media_VideoPath}
                            target="_blank"
                          >
                            <img src={thumb1} alt="" />
                            <div className="play-btn">
                              <button class="button">
                                <span class="arrow"></span>
                                Play
                              </button>
                            </div>
                          </a>
                          {/* <video controls poster={thumb1}>
                            <source
                              src={subitem.subcategory_media_VideoPath}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video> */}
                        </div>
                        <div className="content">
                          <div className="left">
                            <div className="step-name">
                              <label htmlFor="" className="key">
                                Name:
                              </label>
                              <label htmlFor="" className="value ms-2">
                                {subitem.subcategory_media_VideoName}
                              </label>
                            </div>
                            <div className="duration">
                              <label htmlFor="" className="key">
                                Duration:
                              </label>
                              <label htmlFor="" className="value ms-2">
                                {subitem.subcategory_media_VideoDuaration}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <h5 className="text-center text-danger">No Data Found</h5>
          )}

          <Modal
            show={MainVideo}
            onHide={handleMainVideoClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                noValidate
                validated={validated}
                // onSubmit={(e) => handleSubmit(e,"videos")}
                onSubmit={(e) => handleSubmit(e, "updateVideos")}
              >
                <div className="feature-wrapper">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <Form.Label>English URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_english"
                        value={
                          videoEditData.subcategory_media_VideoPath_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter English URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Hindi URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_hindi"
                        value={videoEditData.subcategory_media_VideoPath_hindi}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Hindi URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Urdu URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_urdu"
                        value={videoEditData.subcategory_media_VideoPath_urdu}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Urdu URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Transliteration URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_lit"
                        value={videoEditData.subcategory_media_VideoPath_lit}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Transliteration URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name English</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_english"
                        value={
                          videoEditData.subcategory_media_VideoName_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name English
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Hindi</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_hindi"
                        value={videoEditData.subcategory_media_VideoName_hindi}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name Hindi
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Urdu</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_urdu"
                        value={videoEditData.subcategory_media_VideoName_urdu}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Transliteration</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_lit"
                        value={videoEditData.subcategory_media_VideoName_lit}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name Urdu
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration English</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_english"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration English
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Hindi</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_hindi"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_hindi
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Hindi
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Urdu</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_urdu"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_urdu
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Urdu
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Transliteration</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_lit"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_lit
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Transliteration
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>English Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for English Video"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Hindi Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for Hindi Video"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Urdu Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for Urdu Video"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Transliteration Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for Transliteration Video"
                        />
                      </Form.Group>
                    </div>
                    <div className="buttons-modal  text-center mt-2  ">
                      <button className="me-2 ">MEDIA UPDATE</button>
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            {/* <Modal.Footer className="justify-content-center">
           
            </Modal.Footer> */}
          </Modal>
        </div>
        <hr />
        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
          <Nav variant="pills" className="flex-row">
            <Nav.Item>
              <Nav.Link
                eventKey="1"
                onClick={() => {
                  quranekareemListApi(
                    ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${1}`
                  );
                  // quranekareemList(ApiConfig.FEATURE_LIST + `?subcategory_id=${2}`)
                }}
              >
                Quraan-E-Kareem
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="2"
                onClick={() => {
                  courseListApi(ApiConfig.SYLLABUS_LIST);
                }}
              >
                Syllabus
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="3"
                onClick={() => {
                  quranekareemListApi(
                    ApiConfig.QURANE_KAREEM_LIST + `?assist_option_id=${3}`
                  );
                }}
              >
                Other Books
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            {/*   ====== Feature ===== */}
            <Tab.Pane eventKey="1">
              <div className="feature-lists publication-tab-content row g-3">
                <div className="add-btn">
                  <button
                    onClick={() => {
                      handleAddQuraanShow();
                      setIsEdit(false);
                      setIdEdit(false)
                      handleAddQuraanShowClear()
                    }}
                  >
                    Add +
                  </button>
                </div>
                {loader ? (
                  <FormLoader />
                ) : (
                  <>
                    {quraneKareemList && quraneKareemList.length > 0 ? (
                      quraneKareemList.map((card, index) => {
                        return (
                          <div className="col-md-3">
                            <div className="books-card-main">
                              <div className="btns-top">
                                {/* <Link to="/Quraan">
                                  <button
                                    onClick={() => {
                                      handleAddQuraanShow();
                                    }}
                                    className="view"
                                  >
                                    <img src={ic_eyes} alt="" />
                                  </button>
                                </Link> */}
                                <button
                                  className="delete "
                                  onClick={() => {
                                    handleDeleteShow();
                                    deleteAssist(card?.option_assist_main_id);
                                  }}
                                >
                                  <img src={ic_delete} alt="" />
                                </button>
                                <button
                                  onClick={() => {
                                    setIsEdit(true);
                                    setIdEdit(true);
                                    setIsImage("edit");
                                    setImageWidth("117px");
                                    handleAddQuraanShow();
                                    getUpdateDataId(
                                      card?.option_assist_main_id
                                    );
                                  }}
                                  className="edit"
                                >
                                  <img src={ic_edit} alt="" />
                                </button>
                              </div>

                              <div className="inner-wrapper">
                                <Link to="#" className="book-img">
                                  <img
                                    // src={`https://api.deeniyatplus.com:3010/api/public/assist/${card.option_assis_img}`}
                                    src={`${IMAGE_URL}${card.option_assis_img}`}
                                    alt=""
                                  />
                                </Link>
                                {card.item.map((cardData) => {
                                  return (
                                    <>
                                      <h6 className="name" >
                                        {cardData.assist_title}
                                      </h6>
                                      <div className="name">
                                        {cardData.assist_second_heading}
                                      </div>
                                      <div className="line">
                                        {cardData.assist_desc}
                                      </div>
                                      <hr />
                                    </>
                                  );
                                })}

                                <div className="line">
                                  <span className="fw-bold">Open App: </span>
                                  {card.open_app_link}
                                </div>
                                <div className="line">
                                  <span className="fw-bold">Buy Now: </span>
                                  {card.buy_now_link}
                                </div>
                                <div className="line">
                                  <span className="fw-bold">Play Store: </span>
                                  {card.play_store_link}
                                </div>
                                <div className="line">
                                  <span className="fw-bold">App Store: </span>
                                  {card.app_store_link}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <h5 className="text-center text-danger">No Data Found</h5>
                    )}
                  </>
                )}
              </div>

              <Modal
                show={addQuraan}
                onHide={handleAddQuraanClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Add Quraan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={(e) => {
                      handleSubmit(e, "quraan");
                    }}
                  >
                    <div className="feature-wrapper">
                      <div className="row">
                        {/* <div className="col-md-12 mb-4">
                          <Form.Label>Quraan Image</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.file?.option_assis_img}
                            type="file"
                            required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                file: e.target.files[0],
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Sabaq Name
                          </Form.Control.Feedback>
                        </div> */}

                        {isImage ? (
                          <>
                            <div>
                              {" "}
                              <Form.Label>Quraan Image</Form.Label>
                            </div>
                            <div className="col-md-12 mb-4  d-flex">
                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "col-md-12 "
                                    : "col-md-2 "
                                }
                              >
                                <Form.Control
                                  style={{ width: imageWidth }}
                                  type="file"
                                  name="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  // value={addFeatureData.file}
                                  onChange={(e) => {
                                    setAddQuraanObj({
                                      ...addQuraanObj,
                                      file: e.target.files[0],
                                    });
                                    setImageWidth("100%");
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Quraan Image
                                </Form.Control.Feedback>
                              </div>

                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "d-none"
                                    : "col-md-10 "
                                }
                              >
                                <Form.Control
                                  disabled={true}
                                  required
                                  type="text"
                                  name="file"
                                  value={addQuraanObj.file}
                                  onChange={handleChange}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Quraan Image
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-4">
                            <Form.Label>Quraan Image</Form.Label>
                            <Form.Control
                              required
                              type="file"
                              name="file"
                              accept="image/x-png,image/gif,image/jpeg"
                              // value={addFeatureData?.file}
                              onChange={(e) => {
                                setAddQuraanObj({
                                  ...addQuraanObj,
                                  file: e.target.files[0],
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Feature Image
                            </Form.Control.Feedback>
                          </div>
                        )}

                        <div className="col-md-6 mb-4">
                          <Form.Label>Heading Urdu</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.assist_title_urdu}
                            type="text"
                            required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                assist_title_urdu: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Heading Urdu
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Heading Hindi</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.assist_title_hindi}
                            type="text"
                            required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                assist_title_hindi: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Heading Hindi
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Heading English</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.assist_title_english}
                            type="text"
                            required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                assist_title_english: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Heading English
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Heading Transliteration </Form.Label>
                          <Form.Control
                            value={addQuraanObj?.assist_title_lit}
                            type="text"
                            required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                assist_title_lit: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Heading Transliteration
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-6 mb-4">
                          <Form.Label>Line Heading Urdu</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.assist_second_heading_urdu}
                            type="text"
                            required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                assist_second_heading_urdu: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Line Heading Urdu
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Line Heading Hindi</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.assist_second_heading_hindi}
                            type="text"
                            required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                assist_second_heading_hindi: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Line Heading Hindi
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Line Heading English</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.assist_second_heading_english}
                            type="text"
                            required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                assist_second_heading_english: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Line Heading English
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Line Heading Transliteration</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.assist_second_heading_lit}
                            type="text"
                            required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                assist_second_heading_lit: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Line Heading Transliteration
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-6 mb-4">
                          <Form.Label>E-Book Link Urdu</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.ebookLinkUrdu}
                            type="text"
                            // required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                ebookLinkUrdu: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter E-Book Link Urdu
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-6 mb-4">
                          <Form.Label>E-Book Link Hindi</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.ebookLinkHindi}
                            type="text"
                            // required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                ebookLinkHindi: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter E-Book Link Hindi
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-6 mb-4">
                          <Form.Label>E-Book Link English</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.ebookLinkEnglish}
                            type="text"
                            // required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                ebookLinkEnglish: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter E-Book Link English
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-6 mb-4">
                          <Form.Label>E-Book Link Transliteration</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.ebookLinkTranslit}
                            type="text"
                            // required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                ebookLinkTranslit: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter E-Book Link Transliteration
                          </Form.Control.Feedback>
                        </div>



                        <div className="col-md-12 mb-4">
                          <Form.Label>Description Urdu</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.assist_desc_urdu}
                            as="textarea"
                            required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                assist_desc_urdu: e.target.value,
                              });
                            }}
                            rows={3}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description Urdu.
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-4">
                          <Form.Label>Description Hindi</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.assist_desc_hindi}
                            as="textarea"
                            required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                assist_desc_hindi: e.target.value,
                              });
                            }}
                            rows={3}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description Hindi.
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-4">
                          <Form.Label>Description English</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.assist_desc_english}
                            as="textarea"
                            required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                assist_desc_english: e.target.value,
                              });
                            }}
                            rows={3}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description English.
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-12 mb-4">
                          <Form.Label>Description Transliteration </Form.Label>
                          <Form.Control
                            value={addQuraanObj?.assist_desc_lit}
                            as="textarea"
                            required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                assist_desc_lit: e.target.value,
                              });
                            }}
                            rows={3}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description Transliteration .
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-12 mb-4">
                          {/* <Form.Label>Link 1</Form.Label> */}
                          <Form.Label>Open App</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.open_app_link}
                            type="text"
                            // required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                open_app_link: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter App Link
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-12 mb-4">
                          {/* <Form.Label>Link 2</Form.Label> */}
                          <Form.Label>Buy Now</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.buy_now_link}
                            type="text"
                            // required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                buy_now_link: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter App Link
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-12 mb-4">
                          {/* <Form.Label>Link 2</Form.Label> */}
                          <Form.Label>Play Store</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.play_store_link}
                            type="text"
                            // required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                play_store_link: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Link
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-12 mb-4">
                          {/* <Form.Label>Link 2</Form.Label> */}
                          <Form.Label>App Store</Form.Label>
                          <Form.Control
                            value={addQuraanObj?.app_store_link}
                            type="text"
                            // required
                            onChange={(e) => {
                              setAddQuraanObj({
                                ...addQuraanObj,
                                app_store_link: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Link
                          </Form.Control.Feedback>
                        </div>

                      </div>
                      <div className="buttons-main">
                        {/* <button className="cancel-btn">Cancel</button> */}

                        <button
                          className="submit-btn"
                        // onClick={submitAddQuraan}
                        >
                          {isEdit ? "Update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
            </Tab.Pane>
            {/* ======= Steps ======= */}
            <Tab.Pane eventKey="2">
              <div className="feature-lists publication-tab-content row">
                <div className="add-btn">
                  <button
                    onClick={() => {
                      handleAddCourseShow();
                      setIsEditCourse(false);
                      setCourseObj({});
                      handleAddCourseShowClear();

                    }}
                  >
                    Add Syllabus +
                  </button>
                </div>
                {loader ? (
                  <FormLoader />
                ) : (
                  <>
                    {courseList && courseList.length > 0 ? (
                      courseList?.map((card, index) => {
                        return (
                          <div className="col-md-3">
                            <div className="books-card-main">
                              <div className="btns-top">
                                <button
                                  className="delete "
                                  onClick={() => {
                                    handleDeleteShow();
                                    deleteCourse(card?.group_id);
                                  }}
                                >
                                  <img src={ic_delete} alt="" />
                                </button>
                                <button
                                  onClick={() => {
                                    setIsEditCourse(true);
                                    handleAddCourseShow();
                                    setIsImage("edit");
                                    setImageWidth("117px");
                                    getUpdateDataIdCourse(card?.group_id);
                                  }}
                                  className="edit"
                                >
                                  <img src={ic_edit} alt="" />
                                </button>
                              </div>
                              <div
                                className="inner-wrapper"
                                // onClick={() => {sendCardData(card.group_id)}}
                                onClick={() =>
                                  localStorage.setItem("GroupId", card.group_id)
                                }
                              >
                                <Link to="/Syllabus" className="book-img">
                                  <img
                                    // src={ic_nursery_tr_1}
                                    // src={`https://api.deeniyatplus.com:3010/api/public/assist/${card.course[0]?.course_img}`}
                                    src={`${IMAGE_URL}${card.course[0]?.course_img}`}
                                    alt=""
                                  />
                                </Link>
                                {card?.course?.map((cardData) => {
                                  return (
                                    <>
                                      <h6>
                                        {cardData.course_title}
                                      </h6>
                                      <div className="name">
                                        {cardData.secondary_title}
                                      </div>
                                      <div className="line">
                                        {cardData.course_desc}
                                      </div>
                                      <hr />
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <h5 className="text-danger text-center">
                          No Data Found
                        </h5>
                      </>
                    )}
                  </>
                )}
              </div>

              <Modal
                show={addCourse}
                onHide={handleAddCourseClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {isEditCourse ? "Update Syllabus" : "Add Syllabus"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={(e) => {
                      handleSubmit(e, "syllabus");
                    }}
                  >
                    <div className="feature-wrapper">
                      <div className="row">
                        {/* <div className="col-md-12 mb-4">
                          <Form.Label>Syllabus Image</Form.Label>
                          <Form.Control
                            required
                            // value={courseObj?.file}
                            type="file"
                            onChange={(e) => {
                              setCourseObj({
                                ...courseObj,
                                file: e.target.files[0],
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Sabaq Name
                          </Form.Control.Feedback>
                        </div> */}

                        {isImage ? (
                          <>
                            <div>
                              {" "}
                              <Form.Label>Syllabus Image</Form.Label>
                            </div>
                            <div className="col-md-12 mb-4  d-flex">
                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "col-md-12 "
                                    : "col-md-2 "
                                }
                              >
                                <Form.Control
                                  style={{ width: imageWidth }}
                                  type="file"
                                  name="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  // value={addFeatureData.file}
                                  onChange={(e) => {
                                    setCourseObj({
                                      ...courseObj,
                                      file: e.target.files[0],
                                    });
                                    setImageWidth("100%");
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Syllabus Image
                                </Form.Control.Feedback>
                              </div>

                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "d-none"
                                    : "col-md-10 "
                                }
                              >
                                <Form.Control
                                  disabled={true}
                                  required
                                  type="text"
                                  name="file"
                                  value={courseObj.file}
                                  onChange={handleChange}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Syllabus Image
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-4">
                            <Form.Label>Syllabus Image</Form.Label>
                            <Form.Control
                              required
                              type="file"
                              name="file"
                              accept="image/x-png,image/gif,image/jpeg"
                              // value={addFeatureData?.file}
                              onChange={(e) => {
                                setCourseObj({
                                  ...courseObj,
                                  file: e.target.files[0],
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Syllabus Image
                            </Form.Control.Feedback>
                          </div>
                        )}





                        <div className="col-md-6 mb-4">
                          <Form.Label>Heading Urdu</Form.Label>
                          <Form.Control
                            value={courseObj?.course_title_urdu}
                            type="text"
                            required
                            onChange={(e) => {
                              setCourseObj({
                                ...courseObj,
                                course_title_urdu: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Heading Urdu
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Heading Hindi</Form.Label>
                          <Form.Control
                            value={courseObj?.course_title_hindi}
                            type="text"
                            required
                            onChange={(e) => {
                              setCourseObj({
                                ...courseObj,
                                course_title_hindi: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Heading Hindi
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Heading English</Form.Label>
                          <Form.Control
                            value={courseObj?.course_title_english}
                            type="text"
                            required
                            onChange={(e) => {
                              setCourseObj({
                                ...courseObj,
                                course_title_english: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Heading English
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Heading Transliteration </Form.Label>
                          <Form.Control
                            value={courseObj?.course_title_lit}
                            type="text"
                            required
                            onChange={(e) => {
                              setCourseObj({
                                ...courseObj,
                                course_title_lit: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Heading Transliteration
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-6 mb-4">
                          <Form.Label>Line Heading Urdu</Form.Label>
                          <Form.Control
                            value={courseObj?.secondary_title_urdu}
                            type="text"
                            required
                            onChange={(e) => {
                              setCourseObj({
                                ...courseObj,
                                secondary_title_urdu: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Line Heading Urdu
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Line Heading Hindi</Form.Label>
                          <Form.Control
                            value={courseObj?.secondary_title_hindi}
                            type="text"
                            required
                            onChange={(e) => {
                              setCourseObj({
                                ...courseObj,
                                secondary_title_hindi: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Line Heading Hindi
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Line Heading English</Form.Label>
                          <Form.Control
                            value={courseObj?.secondary_title_english}
                            type="text"
                            required
                            onChange={(e) => {
                              setCourseObj({
                                ...courseObj,
                                secondary_title_english: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Line Heading English
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Line Heading Transliteration </Form.Label>
                          <Form.Control
                            value={courseObj?.secondary_title_lit}
                            type="text"
                            required
                            onChange={(e) => {
                              setCourseObj({
                                ...courseObj,
                                secondary_title_lit: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Line Heading Transliteration
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-12 mb-4">
                          <Form.Label>Description Urdu</Form.Label>
                          <Form.Control
                            value={courseObj?.course_desc_urdu}
                            as="textarea"
                            required
                            onChange={(e) => {
                              setCourseObj({
                                ...courseObj,
                                course_desc_urdu: e.target.value,
                              });
                            }}
                            rows={3}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description Urdu.
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-4">
                          <Form.Label>Description Hindi</Form.Label>
                          <Form.Control
                            value={courseObj?.course_desc_hindi}
                            as="textarea"
                            required
                            onChange={(e) => {
                              setCourseObj({
                                ...courseObj,
                                course_desc_hindi: e.target.value,
                              });
                            }}
                            rows={3}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description Hindi.
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-4">
                          <Form.Label>Description English</Form.Label>
                          <Form.Control
                            value={courseObj?.course_desc_english}
                            as="textarea"
                            required
                            onChange={(e) => {
                              setCourseObj({
                                ...courseObj,
                                course_desc_english: e.target.value,
                              });
                            }}
                            rows={3}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description English.
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-12 mb-4">
                          <Form.Label>Description Transliteration</Form.Label>
                          <Form.Control
                            value={courseObj?.course_desc_lit}
                            as="textarea"
                            required
                            onChange={(e) => {
                              setCourseObj({
                                ...courseObj,
                                course_desc_lit: e.target.value,
                              });
                            }}
                            rows={3}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description Transliteration.
                          </Form.Control.Feedback>
                        </div>

                        {/* <div className="col-md-12 mb-4">
                          <Form.Label>App Link</Form.Label>
                          <Form.Control
                            value={courseObj?.link_one}
                            type="text"
                            onChange={(e) => {
                              setCourseObj({
                                ...courseObj,
                                link_one: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Heading
                          </Form.Control.Feedback>
                        </div> */}
                      </div>
                      <div className="buttons-main">
                        {/* <button className="cancel-btn">Cancel</button> */}

                        <button
                          className="submit-btn"
                        // onClick={submitAddCourse}
                        >
                          {isEditCourse ? "Update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
            </Tab.Pane>
            {/* ====== JD ======= */}
            <Tab.Pane eventKey="3">
              <div className="feature-lists publication-tab-content row">
                <div className="add-btn">
                  <button
                    onClick={() => {
                      handleAddOtherBooksShow();
                      setIsEditBooks(false);
                      handleAddOtherBookClear();
                    }}
                  >
                    Add Books +
                  </button>
                </div>
                {loader ? (
                  <FormLoader />
                ) : (
                  <>
                    {otherBooksList && otherBooksList.length > 0 ? (
                      otherBooksList.map((card, index) => {
                        return (
                          <div className="col-md-3">
                            <div className="books-card-main">
                              <div className="btns-top">
                                {/* <Link to="/OtherBooks">
                                      <button onClick={handleAddOtherBooksShow} className="view">
                                        <img src={ic_eyes} alt="" />
                                      </button>
                                    </Link> */}
                                <button
                                  className="delete "
                                  onClick={() => {
                                    handleDeleteShow();
                                    deleteOtherBookCard(
                                      card?.option_assist_main_id
                                    );
                                  }}
                                >
                                  <img src={ic_delete} alt="" />
                                </button>
                                <button
                                  onClick={() => {
                                    handleAddOtherBooksShow();
                                    getUpdateDataIdOtherBooks(
                                      card?.option_assist_main_id
                                    );
                                    setIsEditBooks(true);
                                    setIsImage("edit");
                                    setImageWidth("117px");
                                  }}
                                  className="edit"
                                >
                                  <img src={ic_edit} alt="" />
                                </button>
                              </div>
                              <div className="inner-wrapper">
                                {/* <Link to="/OtherBooks" className="book-img">
                                      <img src={panch_min_ka_madrasa} alt="" />
                                    </Link> */}
                                <Link to="#" className="book-img">
                                  <img
                                    // src={`https://api.deeniyatplus.com:3010/api/public/assist/${card.option_assis_img}`}
                                    src={`${IMAGE_URL}${card.option_assis_img}`}
                                    alt=""
                                  />
                                </Link>
                                {card.item.map((cardData) => {
                                  {
                                    return (
                                      <>
                                        <h6>
                                          {cardData.assist_title}
                                        </h6>
                                        <div className="name">
                                          {cardData.assist_second_heading}
                                        </div>
                                        <div className="line">
                                          {cardData.assist_desc}
                                        </div>
                                        <hr />
                                      </>
                                    );
                                  }
                                })}

                                {/* <div className="line">
                                  <span className="fw-bold">Read E-Book:</span> {card.link_one}
                                </div>
                                <div className="line">
                                  <span className="fw-bold">Buy Now/Open App: </span>{card.link_two}
                                </div> */}
                                  <div className="line">
                                  <span className="fw-bold">Open App: </span>
                                  {card.open_app_link}
                                </div>
                                <div className="line">
                                  <span className="fw-bold">Buy Now: </span>
                                  {card.buy_now_link}
                                </div>
                                <div className="line">
                                  <span className="fw-bold">Play Store: </span>
                                  {card.play_store_link}
                                </div>
                                <div className="line">
                                  <span className="fw-bold">App Store: </span>
                                  {card.app_store_link}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <h5 className="text-center text-danger">No Data Found</h5>
                    )}
                  </>
                )}
              </div>
              {/* Other Books */}
              <Modal
                show={addOtherBooks}
                onHide={handleAddOtherBooksClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {isEditBooks ? "Update Book" : "Add Book"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={(e) => {
                      handleSubmit(e, "books");
                    }}
                  >
                    <div className="feature-wrapper">
                      <div className="row">
                        {/* <div className="col-md-12 mb-4">
                          <Form.Label>Books Image</Form.Label>
                          <Form.Control
                            type="file"
                            required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                file: e.target.files[0],
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Sabaq Name
                          </Form.Control.Feedback>
                        </div> */}

                        {isImage ? (
                          <>
                            <div>
                              {" "}
                              <Form.Label>Books Image</Form.Label>
                            </div>
                            <div className="col-md-12 mb-4  d-flex">
                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "col-md-12 "
                                    : "col-md-2 "
                                }
                              >
                                <Form.Control
                                  style={{ width: imageWidth }}
                                  type="file"
                                  name="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  // value={addFeatureData.file}
                                  onChange={(e) => {
                                    setAddOtherBooksObj({
                                      ...addOtherBooksObj,
                                      file: e.target.files[0],
                                    });
                                    setImageWidth("100%");
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Books Image
                                </Form.Control.Feedback>
                              </div>

                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "d-none"
                                    : "col-md-10 "
                                }
                              >
                                <Form.Control
                                  disabled={true}
                                  required
                                  type="text"
                                  name="file"
                                  value={addOtherBooksObj.file}
                                  onChange={handleChange}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Books Image
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-4">
                            <Form.Label>Books Image</Form.Label>
                            <Form.Control
                              required
                              type="file"
                              name="file"
                              accept="image/x-png,image/gif,image/jpeg"
                              // value={addFeatureData?.file}
                              onChange={(e) => {
                                setAddOtherBooksObj({
                                  ...addOtherBooksObj,
                                  file: e.target.files[0],
                                });
                              }}
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Books Image
                            </Form.Control.Feedback>
                          </div>
                        )}




                        <div className="col-md-6 mb-4">
                          <Form.Label>Heading Urdu</Form.Label>
                          <Form.Control
                            value={addOtherBooksObj?.assist_title_urdu}
                            type="text"
                            required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                assist_title_urdu: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Heading Urdu
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Heading Hindi</Form.Label>
                          <Form.Control
                            value={addOtherBooksObj?.assist_title_hindi}
                            type="text"
                            required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                assist_title_hindi: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Heading Hindi
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Heading English</Form.Label>
                          <Form.Control
                            value={addOtherBooksObj?.assist_title_english}
                            type="text"
                            required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                assist_title_english: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Heading English
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Heading Transliteration</Form.Label>
                          <Form.Control
                            value={addOtherBooksObj?.assist_title_lit}
                            type="text"
                            required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                assist_title_lit: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Heading Transliteration
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-6 mb-4">
                          <Form.Label>Line Heading Urdu</Form.Label>
                          <Form.Control
                            value={addOtherBooksObj?.assist_second_heading_urdu}
                            type="text"
                            required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                assist_second_heading_urdu: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Line Heading Urdu
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Line Heading Hindi</Form.Label>
                          <Form.Control
                            value={
                              addOtherBooksObj?.assist_second_heading_hindi
                            }
                            type="text"
                            required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                assist_second_heading_hindi: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Line Heading Hindi
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Line Heading English</Form.Label>
                          <Form.Control
                            value={
                              addOtherBooksObj?.assist_second_heading_english
                            }
                            type="text"
                            required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                assist_second_heading_english: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Line Heading English
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Line Heading Transliteration</Form.Label>
                          <Form.Control
                            value={addOtherBooksObj?.assist_second_heading_lit}
                            type="text"
                            required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                assist_second_heading_lit: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Line Heading Transliteration
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-6 mb-4">
                          <Form.Label>E-Book Link Urdu</Form.Label>
                          <Form.Control
                            value={addOtherBooksObj?.ebookLinkUrdu}
                            type="text"
                            // required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                ebookLinkUrdu: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter E-Book Link Urdu
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-6 mb-4">
                          <Form.Label>E-Book Link Hindi</Form.Label>
                          <Form.Control
                            value={addOtherBooksObj?.ebookLinkHindi}
                            type="text"
                            // required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                ebookLinkHindi: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter E-Book Link Hindi
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-6 mb-4">
                          <Form.Label>E-Book Link English</Form.Label>
                          <Form.Control
                            value={addOtherBooksObj?.ebookLinkEnglish}
                            type="text"
                            // required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                ebookLinkEnglish: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter E-Book Link English
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-6 mb-4">
                          <Form.Label>E-Book Link Tranliteration</Form.Label>
                          <Form.Control
                            value={addOtherBooksObj?.ebookLinkTranslit}
                            type="text"
                            // required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                ebookLinkTranslit: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter E-Book Link Tranliteration
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-12 mb-4">
                          <Form.Label>Description Urdu</Form.Label>
                          <Form.Control
                            value={addOtherBooksObj?.assist_desc_urdu}
                            as="textarea"
                            required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                assist_desc_urdu: e.target.value,
                              });
                            }}
                            rows={3}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description Urdu.
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-4">
                          <Form.Label>Description Hindi</Form.Label>
                          <Form.Control
                            value={addOtherBooksObj?.assist_desc_hindi}
                            as="textarea"
                            required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                assist_desc_hindi: e.target.value,
                              });
                            }}
                            rows={3}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description Hindi.
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-4">
                          <Form.Label>Description English</Form.Label>
                          <Form.Control
                            value={addOtherBooksObj?.assist_desc_english}
                            as="textarea"
                            required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                assist_desc_english: e.target.value,
                              });
                            }}
                            rows={3}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description English.
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-12 mb-4">
                          <Form.Label>Description Transliteration</Form.Label>
                          <Form.Control
                            value={addOtherBooksObj?.assist_desc_lit}
                            as="textarea"
                            required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                assist_desc_lit: e.target.value,
                              });
                            }}
                            rows={3}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description Transliteration.
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-12 mb-4">
                          {/* <Form.Label>Read E-Book</Form.Label> */}
                          <Form.Label>Open App</Form.Label>
                          <Form.Control
                            value={addOtherBooksObj?.open_app_link}
                            type="text"
                            // required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                open_app_link: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Link
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-4">
                          <Form.Label>Buy Now</Form.Label>
                          <Form.Control
                            value={addOtherBooksObj?.buy_now_link}
                            type="text"
                            // required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                buy_now_link: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Link
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-12 mb-4">
                          {/* <Form.Label>Read E-Book</Form.Label> */}
                          <Form.Label>Play Store</Form.Label>
                          <Form.Control
                            value={addOtherBooksObj?.play_store_link}
                            type="text"
                            // required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                play_store_link: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Link
                          </Form.Control.Feedback>
                        </div>

                        <div className="col-md-12 mb-4">
                          {/* <Form.Label>Read E-Book</Form.Label> */}
                          <Form.Label>App Store</Form.Label>
                          <Form.Control
                            value={addOtherBooksObj?.app_store_link}
                            type="text"
                            // required
                            onChange={(e) => {
                              setAddOtherBooksObj({
                                ...addOtherBooksObj,
                                app_store_link: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Link
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="buttons-main">
                        {/* <button className="cancel-btn">Cancel</button> */}

                        <button
                          className="submit-btn"
                        // onClick={submitAddOtherBooks}
                        >
                          {isEditBooks ? "Update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </main>
  );
};

export default Publication;
