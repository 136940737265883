import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "./../context/AppContext";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Chart from "react-apexcharts";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import ic_install from "../assets/images/ic_install.svg";
import ic_newReturn from "../assets/images/ic_newReturn.svg";
import ic_activeUser from "../assets/images/ic_activeUser.svg";
import ic_location from "../assets/images/ic_location.svg";
import ic_device from "../assets/images/ic_device.svg";
import ic_registered from "../assets/images/ic_registered.svg";
import Modal from "react-bootstrap/Modal";
import ic_time from "../assets/images/ic_time.svg";
import thumb1 from "../assets/images/video_thumbnails/1.png";
import Aqaaid_active from "../assets/images/Aqaaid_active.svg";
import Seerat_active from "../assets/images/Seerat_active.svg";
import Noraani_Qaaidah_active from "../assets/images/Noraani_Qaaidah_active.svg";
import Dua_Sunnat_active from "../assets/images/Dua_Sunnat_active.svg";
import Hifz_e_Surah_active from "../assets/images/Hifz_e_Surah_active.svg";
import Namaaz_active from "../assets/images/Namaaz_active.svg";
import Masaail_active from "../assets/images/Masaail_active.svg";
import Umrah_Hajj_active from "../assets/images/Umrah_Hajj_active.svg";
import Form from "react-bootstrap/Form";
import ic_edit from "../assets/images/ic_edit.svg";
import axios from "axios";
import { ToastMsg } from "./TostMsg";
import {
  putCallWithAuthTokenWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
const Dashboard = () => {
  const { sidebar, adminData } = useContext(AppContext);
  const navigate = useNavigate();
  const [MainVideo, setMainVideo] = useState(false);
  const handleMainVideoClose = () => setMainVideo(false);
  const handleMainVideoShow = () => setMainVideo(true);
  const [loader, setLoader] = useState(false);
  const [vedioApiList, setvedioApiList] = useState([]);
  const [countryNames, setCountryNames] = useState([]);
  console.log("countryNames", countryNames)
  const [totalCountryCount, setTotalCountryCount] = useState(0)
  const [totalUsersCount, setTotalUsersCount] = useState([]);
  console.log("totalUsersCount", totalUsersCount);

  const [osName, setOsName] = useState([]);
  console.log("OS NAME", osName);

  const [totalIosUsersCount, setTotalIosUsersCount] = useState(0)


  const dummyAPIData = [
    {
      totalInstall: "3052",
      activeUsers: "3035",
      newUsers: "3012",
      returningUsers: "1973",
      screenTime: "4308052",
      countries: [
        {
          totalCountries: 51,
        },
        {
          countryName: "India",
          totalUsers: "2807",
        },
        {
          countryName: "Saudi Arabia",
          totalUsers: "70",
        },
        {
          countryName: "United Arab Emirates",
          totalUsers: "58",
        },
        {
          countryName: "United States",
          totalUsers: "35",
        },
        {
          countryName: "Pakistan",
          totalUsers: "25",
        },
        {
          countryName: "United Kingdom",
          totalUsers: "25",
        },
        {
          countryName: "Qatar",
          totalUsers: "24",
        },
        {
          countryName: "Ireland",
          totalUsers: "11",
        },
        {
          countryName: "(not set)",
          totalUsers: "9",
        },
        {
          countryName: "Kuwait",
          totalUsers: "7",
        },
        {
          countryName: "Canada",
          totalUsers: "6",
        },
        {
          countryName: "France",
          totalUsers: "6",
        },
        {
          countryName: "Germany",
          totalUsers: "6",
        },
        {
          countryName: "Türkiye",
          totalUsers: "6",
        },
        {
          countryName: "Australia",
          totalUsers: "5",
        },
        {
          countryName: "Bahrain",
          totalUsers: "4",
        },
        {
          countryName: "Oman",
          totalUsers: "4",
        },
        {
          countryName: "Singapore",
          totalUsers: "4",
        },
        {
          countryName: "Bangladesh",
          totalUsers: "3",
        },
        {
          countryName: "Netherlands",
          totalUsers: "3",
        },
        {
          countryName: "Algeria",
          totalUsers: "2",
        },
        {
          countryName: "Austria",
          totalUsers: "2",
        },
        {
          countryName: "Chile",
          totalUsers: "2",
        },
        {
          countryName: "Côte d’Ivoire",
          totalUsers: "2",
        },
        {
          countryName: "Finland",
          totalUsers: "2",
        },
        {
          countryName: "Iran",
          totalUsers: "2",
        },
        {
          countryName: "Kenya",
          totalUsers: "2",
        },
        {
          countryName: "Morocco",
          totalUsers: "2",
        },
        {
          countryName: "Philippines",
          totalUsers: "2",
        },
        {
          countryName: "Russia",
          totalUsers: "2",
        },
        {
          countryName: "South Africa",
          totalUsers: "2",
        },
        {
          countryName: "South Korea",
          totalUsers: "2",
        },
        {
          countryName: "Afghanistan",
          totalUsers: "1",
        },
        {
          countryName: "Belgium",
          totalUsers: "1",
        },
        {
          countryName: "Cambodia",
          totalUsers: "1",
        },
        {
          countryName: "China",
          totalUsers: "1",
        },
        {
          countryName: "Croatia",
          totalUsers: "1",
        },
        {
          countryName: "Ethiopia",
          totalUsers: "1",
        },
        {
          countryName: "Georgia",
          totalUsers: "1",
        },
        {
          countryName: "Ghana",
          totalUsers: "1",
        },
        {
          countryName: "Indonesia",
          totalUsers: "1",
        },
        {
          countryName: "Lebanon",
          totalUsers: "1",
        },
        {
          countryName: "Malawi",
          totalUsers: "1",
        },
        {
          countryName: "Mauritius",
          totalUsers: "1",
        },
        {
          countryName: "Mozambique",
          totalUsers: "1",
        },
        {
          countryName: "Myanmar (Burma)",
          totalUsers: "1",
        },
        {
          countryName: "Nepal",
          totalUsers: "1",
        },
        {
          countryName: "New Zealand",
          totalUsers: "1",
        },
        {
          countryName: "Portugal",
          totalUsers: "1",
        },
        {
          countryName: "Sri Lanka",
          totalUsers: "1",
        },
        {
          countryName: "Switzerland",
          totalUsers: "1",
        },
      ],
      operatingSystem: [
        {
          osName: "Android",
          osVersion: "12",
          totalUsers: "817",
        },
        {
          osName: "Android",
          osVersion: "13",
          totalUsers: "679",
        },
        {
          osName: "Android",
          osVersion: "11",
          totalUsers: "566",
        },
        {
          osName: "Android",
          osVersion: "10",
          totalUsers: "252",
        },
        {
          osName: "Android",
          osVersion: "9",
          totalUsers: "225",
        },
        {
          osName: "iOS",
          osVersion: "16.4.1",
          totalUsers: "131",
        },
        {
          osName: "iOS",
          osVersion: "16.3.1",
          totalUsers: "121",
        },
        {
          osName: "Android",
          osVersion: "8.1.0",
          totalUsers: "66",
        },
        {
          osName: "iOS",
          osVersion: "16.5",
          totalUsers: "64",
        },
        {
          osName: "iOS",
          osVersion: "16.2",
          totalUsers: "36",
        },
        {
          osName: "iOS",
          osVersion: "12.5.6",
          totalUsers: "35",
        },
        {
          osName: "Android",
          osVersion: "8.0.0",
          totalUsers: "23",
        },
        {
          osName: "Android",
          osVersion: "6.0.1",
          totalUsers: "15",
        },
        {
          osName: "Android",
          osVersion: "7.0",
          totalUsers: "15",
        },
        {
          osName: "iOS",
          osVersion: "15.7.5",
          totalUsers: "12",
        },
        {
          osName: "iOS",
          osVersion: "16.1",
          totalUsers: "9",
        },
        {
          osName: "iOS",
          osVersion: "16.1.1",
          totalUsers: "9",
        },
        {
          osName: "iOS",
          osVersion: "16.3",
          totalUsers: "9",
        },
        {
          osName: "iOS",
          osVersion: "16.4",
          totalUsers: "8",
        },
        {
          osName: "Android",
          osVersion: "5.1.1",
          totalUsers: "7",
        },
        {
          osName: "Android",
          osVersion: "7.1.1",
          totalUsers: "7",
        },
        {
          osName: "iOS",
          osVersion: "12.5.7",
          totalUsers: "7",
        },
        {
          osName: "iOS",
          osVersion: "15.7.3",
          totalUsers: "7",
        },
        {
          osName: "iOS",
          osVersion: "15.7.6",
          totalUsers: "7",
        },
        {
          osName: "iOS",
          osVersion: "15.5",
          totalUsers: "6",
        },
        {
          osName: "iOS",
          osVersion: "16.0.3",
          totalUsers: "6",
        },
        {
          osName: "iOS",
          osVersion: "14.8.1",
          totalUsers: "5",
        },
        {
          osName: "iOS",
          osVersion: "15.6.1",
          totalUsers: "5",
        },
        {
          osName: "iOS",
          osVersion: "16.1.2",
          totalUsers: "5",
        },
        {
          osName: "iOS",
          osVersion: "15.6",
          totalUsers: "4",
        },
        {
          osName: "iOS",
          osVersion: "16.0.2",
          totalUsers: "4",
        },
        {
          osName: "Android",
          osVersion: "5.1",
          totalUsers: "3",
        },
        {
          osName: "iOS",
          osVersion: "16.6",
          totalUsers: "3",
        },
        {
          osName: "Android",
          osVersion: "6.0",
          totalUsers: "2",
        },
        {
          osName: "Android",
          osVersion: "7.1.2",
          totalUsers: "2",
        },
        {
          osName: "iOS",
          osVersion: "14.4",
          totalUsers: "2",
        },
        {
          osName: "iOS",
          osVersion: "15.3.1",
          totalUsers: "2",
        },
        {
          osName: "iOS",
          osVersion: "15.7.4",
          totalUsers: "2",
        },
        {
          osName: "iOS",
          osVersion: "16.0",
          totalUsers: "2",
        },
        {
          osName: "iOS",
          osVersion: "17.0",
          totalUsers: "2",
        },
        {
          osName: "Android",
          osVersion: "14",
          totalUsers: "1",
        },
        {
          osName: "Android",
          osVersion: "5.0",
          totalUsers: "1",
        },
        {
          osName: "iOS",
          osVersion: "12.5.5",
          totalUsers: "1",
        },
        {
          osName: "iOS",
          osVersion: "13.6",
          totalUsers: "1",
        },
        {
          osName: "iOS",
          osVersion: "14.7",
          totalUsers: "1",
        },
        {
          osName: "iOS",
          osVersion: "15.4",
          totalUsers: "1",
        },
        {
          osName: "iOS",
          osVersion: "15.4.1",
          totalUsers: "1",
        },
        {
          osName: "iOS",
          osVersion: "15.7",
          totalUsers: "1",
        },
        {
          osName: "iOS",
          osVersion: "15.7.2",
          totalUsers: "1",
        },
      ],
    },
  ];

  // Function to calculate the total number of users for a given OS name
const getTotalUsersByOS = (osName) => {
  let totalUsers = 0;
  dummyAPIData[0]?.operatingSystem.forEach((os) => {
    if (os.osName === osName) {
      totalUsers += parseInt(os.totalUsers);
    }
  });
  return totalUsers;
};

// Calculate the total number of users for Android and iOS
const androidTotalUsers = getTotalUsersByOS("Android");
const iOSTotalUsers = getTotalUsersByOS("iOS");



  useEffect(() => {
    // getDashboardData();
    const totalCountry = dummyAPIData[0]?.countries[0]?.totalCountries;
    console.log("Total Countries:", totalCountry);
    setTotalCountryCount(totalCountry);

    // Extract country data from dummyAPIData
    const countries = dummyAPIData[0]?.countries?.slice(1);

    console.log("countries", countries)

    const countryTotalUsers = countries?.map((users) => users.totalUsers);
    setTotalUsersCount(countryTotalUsers)

    // Create a new array of country names
    const countryNamesArray = countries?.map((country) => country.countryName);
    

    // Set the country names array in the state
    setCountryNames(countryNamesArray);


    const iOSCount = dummyAPIData[0]?.operatingSystem.filter(
      (item) => item.osName === 'iOS'
    ).length;
    setTotalIosUsersCount(iOSCount)
    console.log("iOSCount:", iOSCount);

  }, []);



  const [locationChart, setlocationChart] = useState({
    options: {
      chart: {
        type: 'bar',
        height: 380
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          horizontal: true,
          dataLabels: {
            position: 'bottom'
          },
        }
      },
      
      xaxis: {
        categories: dummyAPIData[0].countries
          .map((country) => country.countryName)
          .slice(1), // Slicing the array to exclude the first index
      
      },
    },
    series: [
      {
        name: "series-1",
        data:
          dummyAPIData[0].countries
            .map((country) => country.totalUsers)
            .slice(1), // Slicing the array to exclude the first index
      },
    ],

  });


  const [iOSChart, setiOSChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      xaxis: {
        categories: [...new Set(dummyAPIData[0]?.operatingSystem?.map((brand) => brand.osName))]
      },
    },
    series: [
      {
        name: "series-1",
        data:
         [androidTotalUsers, iOSTotalUsers],
      },
    ],
  });


  const [AndroidChart, setAndroidChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      xaxis: {
       categories: dummyAPIData[0].operatingSystem
          .map((os) => `${os.osName} ${os.osVersion}`)
      },
    },
    series: [
      {
        name: "series-1",
        data: dummyAPIData[0].operatingSystem
        .map((item) => item.totalUsers)
      },
    ],
  });

  const [location, setlocation] = useState(false);
  const handlelocationClose = () => setlocation(false);
  const handlelocationShow = () => setlocation(true);

  const [devices, setDevice] = useState(false);
  const handledevicesClose = () => setDevice(false);
  const handledevicesShow = () => setDevice(true);

  // useEffect(() => {
  //   $(document).ready(function () {
  //     $("#table_id").DataTable();
  //   });
  //   app();
  //   videoApiList();
  // }, []);

  const getDashboardData = () => {
    // setLoader(true);
    simpleGetCallWithErrorResponse(ApiConfig.GET_GOOGLE_ANALYTICS)
      .then((res) => {
        console.log("Google Analyics Response", res);
      })
      .catch((err) => {
        // setLoader(false);
        ToastMsg("error", "something went wrong");
      });
  }


  const [validated, setValidated] = useState(false);

  const handleSubmit = (event, section) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (section === "videos") {
        updateMediaApi(event);
      }
    }

    setValidated(true);
  };
  useEffect(() => {
    videoApiList();
  }, []);

  const videoApiList = () => {
    setLoader(true);
    // ?subcategory_id=3&school_workshop_ten_step_id=10
    simpleGetCallWithErrorResponse(
      ApiConfig.GET_MEDIEA + `?subcategory_id=${1}`
    )
      .then((res) => {
        console.log(res);
        if (res.json && res.json?.result === true) {
          setLoader(false);

          setvedioApiList(res.json.media);
          setVideoEditData(res.json.editmedia);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);
        // ToastMsg("error", "something went wrong");
      });
  };

  const [videoEditData, setVideoEditData] = useState({
    subcategory_media_VideoName_hindi: "",
    subcategory_media_VideoPath_hindi: "",
    subcategory_media_VideoDuaration_hindi: "",
    subcategory_media_VideoName_lit: " ",
    subcategory_media_VideoPath_lit: "",
    subcategory_media_VideoDuaration_lit: " ",
    subcategory_media_VideoName_urdu: "",
    subcategory_media_VideoPath_urdu: "",
    subcategory_media_VideoDuaration_urdu: "",
    subcategory_media_VideoName_english: " ",
    subcategory_media_VideoPath_english: "",
    subcategory_media_VideoDuaration_english: "",
    subcategory_id: 2,
  });
  const handleChangeVideoEdit = (event) => {
    const { name, value } = event.target;
    const videoEditDatacopy = { ...videoEditData };
    videoEditDatacopy[name] = value;
    setVideoEditData(videoEditDatacopy);
  };

  const updateMediaApi = (e) => {
    e.preventDefault();
    handleMainVideoClose();
    putCallWithAuthTokenWithErrorResponse(
      ApiConfig.UPDATE_MEDIEA + `?subcategory_id=${1}`,
      JSON.stringify({
        ...videoEditData,
        modified_by_id: adminData.user_id,
      })
    )
      .then((res) => {
        if (res.json && res.json?.result === true) {
          ToastMsg("success", res.json.message);
          videoApiList();
          // tenStepsListApi(addBtn);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  return (
    <main
      id="cx-main"
      className={sidebar ? "dashboard-main" : "cx-active dashboard-main"}
    >
      <div className="dashboard-wrapper">
        <div className="heading-common">
          <label htmlFor="">DASHBOARD</label>
        </div>

        <div className="main-wrapper dashboard-video mt-4" id="maktab-main">
          <div className="main-all-video ">
            {vedioApiList?.length > 0 && (
              <div className="right text-end">
                <div className="add-btn">
                  <button onClick={handleMainVideoShow}>
                    <img src={ic_edit} alt="" />
                  </button>
                </div>
              </div>
            )}

            {vedioApiList?.length > 0 ? (
              vedioApiList?.map((videolist, index) => {
                return (
                  <div className="outer-main-video row" key={index}>
                    {videolist.sub_category_media.map((subitem, index) => {
                      return (
                        <div className="col-md-3 outer-main-video" key={index}>
                          <h6>
                            {(subitem.language_id === 4 && (
                              <span>Hindi</span>
                            )) ||
                              (subitem.language_id === 1 && (
                                <span>English</span>
                              )) ||
                              (subitem.language_id === 2 && (
                                <span>Urdu</span>
                              )) ||
                              (subitem.language_id === 3 && (
                                <span>Transliteration</span>
                              ))}{" "}
                            Video
                          </h6>
                          <div className="video">
                            <a
                              href={subitem.subcategory_media_VideoPath}
                              target="_blank"
                            >
                              <img src={thumb1} alt="" />
                              <div className="play-btn">
                                <button class="button">
                                  <span class="arrow"></span>
                                  Play
                                </button>
                              </div>
                            </a>
                            {/* <video controls poster={thumb1} >
                              <source
                                src={subitem.subcategory_media_VideoPath}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video> */}
                          </div>
                          <div className="content">
                            <div className="left">
                              <div className="step-name">
                                <label htmlFor="" className="key">
                                  Name:
                                </label>
                                <label htmlFor="" className="value ms-2">
                                  {subitem.subcategory_media_VideoName}
                                </label>
                              </div>
                              <div className="duration">
                                <label htmlFor="" className="key">
                                  Duration:
                                </label>
                                <label htmlFor="" className="value ms-2">
                                  {subitem.subcategory_media_VideoDuaration}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            ) : (
              <h5 className="text-center text-danger">No Data Found</h5>
            )}

            <Modal
              show={MainVideo}
              onHide={handleMainVideoClose}
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Update Video</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form
                  noValidate
                  validated={validated}
                  // onSubmit={(e) => handleSubmit(e,"videos")}
                  onSubmit={(e) => handleSubmit(e, "videos")}
                >
                  <div className="feature-wrapper">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <Form.Label>English URL</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="subcategory_media_VideoPath_english"
                          value={
                            videoEditData.subcategory_media_VideoPath_english
                          }
                          onChange={handleChangeVideoEdit}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter English URL
                        </Form.Control.Feedback>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label>Hindi URL</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="subcategory_media_VideoPath_hindi"
                          value={
                            videoEditData.subcategory_media_VideoPath_hindi
                          }
                          onChange={handleChangeVideoEdit}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Hindi URL
                        </Form.Control.Feedback>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label>Urdu URL</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="subcategory_media_VideoPath_urdu"
                          value={videoEditData.subcategory_media_VideoPath_urdu}
                          onChange={handleChangeVideoEdit}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Urdu URL
                        </Form.Control.Feedback>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label>Transliteration URL</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="subcategory_media_VideoPath_lit"
                          value={videoEditData.subcategory_media_VideoPath_lit}
                          onChange={handleChangeVideoEdit}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Transliteration URL
                        </Form.Control.Feedback>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label>Name English</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="subcategory_media_VideoName_english"
                          value={
                            videoEditData.subcategory_media_VideoName_english
                          }
                          onChange={handleChangeVideoEdit}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Name English
                        </Form.Control.Feedback>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label>Name Hindi</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="subcategory_media_VideoName_hindi"
                          value={
                            videoEditData.subcategory_media_VideoName_hindi
                          }
                          onChange={handleChangeVideoEdit}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Name Hindi
                        </Form.Control.Feedback>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label>Name Urdu</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="subcategory_media_VideoName_urdu"
                          value={videoEditData.subcategory_media_VideoName_urdu}
                          onChange={handleChangeVideoEdit}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Sabaq Name
                        </Form.Control.Feedback>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label>Name Transliteration</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="subcategory_media_VideoName_lit"
                          value={videoEditData.subcategory_media_VideoName_lit}
                          onChange={handleChangeVideoEdit}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Name Urdu
                        </Form.Control.Feedback>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label>Duration English</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="subcategory_media_VideoDuaration_english"
                          value={
                            videoEditData.subcategory_media_VideoDuaration_english
                          }
                          onChange={handleChangeVideoEdit}
                          onKeyDown={(e) => {
                            const key = e.key;
                            const allowedKeys = [
                              "Backspace",
                              "Delete",
                              "ArrowLeft",
                              "ArrowRight",
                            ];
                            if (
                              !/[0-9:]/.test(key) &&
                              !allowedKeys.includes(key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Duration English
                        </Form.Control.Feedback>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label>Duration Hindi</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="subcategory_media_VideoDuaration_hindi"
                          value={
                            videoEditData.subcategory_media_VideoDuaration_hindi
                          }
                          onChange={handleChangeVideoEdit}
                          onKeyDown={(e) => {
                            const key = e.key;
                            const allowedKeys = [
                              "Backspace",
                              "Delete",
                              "ArrowLeft",
                              "ArrowRight",
                            ];
                            if (
                              !/[0-9:]/.test(key) &&
                              !allowedKeys.includes(key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Duration Hindi
                        </Form.Control.Feedback>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label>Duration Urdu</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="subcategory_media_VideoDuaration_urdu"
                          value={
                            videoEditData.subcategory_media_VideoDuaration_urdu
                          }
                          onChange={handleChangeVideoEdit}
                          onKeyDown={(e) => {
                            const key = e.key;
                            const allowedKeys = [
                              "Backspace",
                              "Delete",
                              "ArrowLeft",
                              "ArrowRight",
                            ];
                            if (
                              !/[0-9:]/.test(key) &&
                              !allowedKeys.includes(key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Duration Urdu
                        </Form.Control.Feedback>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label>Duration Transliteration</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="subcategory_media_VideoDuaration_lit"
                          value={
                            videoEditData.subcategory_media_VideoDuaration_lit
                          }
                          onChange={handleChangeVideoEdit}
                          onKeyDown={(e) => {
                            const key = e.key;
                            const allowedKeys = [
                              "Backspace",
                              "Delete",
                              "ArrowLeft",
                              "ArrowRight",
                            ];
                            if (
                              !/[0-9:]/.test(key) &&
                              !allowedKeys.includes(key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Duration Transliteration
                        </Form.Control.Feedback>
                      </div>
                      {/* english to hindi */}
                      <div className="buttons-modal  text-center mt-2  ">
                        <button className="me-2 ">MEDIA UPDATE</button>
                      </div>
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              {/* <Modal.Footer className="justify-content-center">
           
            </Modal.Footer> */}
            </Modal>
          </div>
          <hr />
        </div>
        <div className="row mt-3 main-section">
          <div className="right-section ">
            <div className="row">
              <h5>Analytics (Provided custom data for now...)</h5>
              {dummyAPIData?.length > 0 ? (
                <>
                  <div className="col-md-4">
                    <Link className="mainCards" to="#">
                      <div className="ineerCard">
                        <div className="innerIcon">
                          <img src={ic_install} alt="" />
                        </div>
                        <div className="innerText">
                          <div className="col headingText">Total Install</div>
                          <div className="col count">{dummyAPIData[0].totalInstall}</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link className="mainCards" to="#">
                      <div className="ineerCard">
                        <div className="innerIcon">
                          <img src={ic_newReturn} alt="" />
                        </div>
                        <div className="innerText">
                          <div className="col headingText">
                            New/Returning Users
                          </div>
                          <div className="col count">
                            <span>{dummyAPIData[0].newUsers}</span>/<span>{dummyAPIData[0].returningUsers}</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link
                      className="mainCards"
                      to="#"
                      onClick={handlelocationShow}
                    >
                      <div className="ineerCard">
                        <div className="innerIcon">
                          <img src={ic_location} alt="" />
                        </div>
                        <div className="innerText">
                          <div className="col headingText">Location</div>
                          <div className="col count">{totalCountryCount}</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link
                      className="mainCards"
                      to="#"
                      onClick={handledevicesShow}
                    >
                      <div className="ineerCard">
                        <div className="innerIcon">
                          <img src={ic_device} alt="" />
                        </div>
                        <div className="innerText">
                          <div className="col headingText">Android/IOS</div>
                          <div className="col count">{androidTotalUsers} / {iOSTotalUsers}</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-4">
                    <Link className="mainCards" to="#">
                      <div className="ineerCard">
                        <div className="innerIcon">
                          <img src={ic_time} alt="" />
                        </div>
                        <div className="innerText">
                          <div className="col headingText">Screen Time</div>
                          <div className="col count">{dummyAPIData[0].screenTime}</div>
                        </div>
                      </div>
                    </Link>
                  </div>
{/* 
                  <div className="col-md-4">
                    <Link className="mainCards" to="#">
                      <div className="ineerCard">
                        <div className="innerIcon">
                          <img src={ic_registered} alt="" />
                        </div>
                        <div className="innerText">
                          <div className="col headingText">Registered</div>
                          <div className="col count">dummy 78</div>
                        </div>
                      </div>
                    </Link>
                  </div> */}

                  <div className="col-md-4">
                    <Link className="mainCards" to="#">
                      <div className="ineerCard mb-0">
                        <div className="innerIcon">
                          <img src={ic_activeUser} alt="" />
                        </div>
                        <div className="innerText">
                          <div className="col headingText">Active User</div>
                          <div className="col count">{dummyAPIData[0].activeUsers}</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              ) : (
                <h5 className="text-center text-danger">No Data Found</h5>
              )}
            </div>

            <Modal
              show={location}
              onHide={handlelocationClose}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Location</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5 className="mb-3">Cumulative total of countries : {totalCountryCount}</h5>
                <Chart
                  options={locationChart?.options}
                  series={locationChart?.series}

                  type="bar"
                  width="100%"
                />
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal
              show={devices}
              onHide={handledevicesClose}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Devices</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5 className="mb-3">Cumulative total of OS : {totalIosUsersCount || 0}</h5>

                {/* iOS Chart */}
                <Chart
                  options={iOSChart?.options}
                  series={iOSChart?.series}
                  type="bar"
                  width="100%"
                />
                {/* Android chart */}
                <Chart
                  options={AndroidChart?.options}
                  series={AndroidChart?.series}
                  type="bar"
                  width="100%"
                />
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Dashboard;
