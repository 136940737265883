import React from "react";
import { RotatingLines } from "react-loader-spinner";
import { ScaleLoader, HashLoader } from "react-spinners";
function FormLoader({ isLoading }) {
  return (
    <div>
      {/* <div
        style={{
          position:"absolute",
          display:"flex",
          alignItems:"center",
          justifyContent:"center",
          zIndex:"10",
          opacity: "1",
          marginTop:"20%",
          marginLeft:"38%",
        }}
      >
        <RotatingLines
          strokeColor="red"
          strokeWidth="5"
          animationDuration="0.75"
          width="150"
          visible={isLoading}
        /> */}
{/* <HashLoader color="#747b26" /> */}
      <HashLoader
        color="#747b26"
        loading={isLoading}
        style={{
          display: "block",
          textAlign: "center",
          marginTop:"100px"
        }}
        size={50}
      />

      {/* </div> */}
    </div>
  );
}

export default FormLoader;
