import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import React, { useState, useEffect } from "react";
import "./assets/styles/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./sharedComponents/Header";
import Sidebar from "./sharedComponents/Sidebar";
import Login from "./pages/Login";
import PushNotification from "./pages/PushNotification";
import GoogleAnalytics from "./pages/GoogleAnalytics";
import Maktab from "./pages/Education/Maktab";
import School from "./pages/Education/School";
import Employment from "./pages/Khidmat/Employment";
import FreeFood from "./pages/Khidmat/FreeFood";
import Medical from "./pages/Khidmat/Medical";
import DailyPost from "./pages/Supports/DailyPost";
import FAQ from "./pages/Supports/FAQ";
import Gallery from "./pages/Supports/Gallery";
import MobileApps from "./pages/Supports/MobileApps/MobileApps";
import Products from "./pages/Supports/Products";
import Publication from "./pages/Supports/Publication/Publication";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Quraan from "./pages/Supports/Publication/Quraan";
import Syllabus from "./pages/Supports/Publication/Syllabus";
import OtherBooks from "./pages/Supports/Publication/OtherBooks";
import SyllabusDetails from "./pages/Supports/Publication/SyllabusDetails";
import QuraanApps from "./pages/Supports/MobileApps/QuraanApps";
import OtherApps from "./pages/Supports/MobileApps/OtherApps";
import MaktabApps from "./pages/Supports/MobileApps/MaktabApps";
import Assessment from "./pages/Assessment";
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  let currentRoute = location.pathname;
  const [logedIn, setLoggedIn] = useState(
    localStorage.getItem("logedIn") ? localStorage.getItem("logedIn") : false
  );

  useEffect(() => {
    if (!logedIn) {
      navigate("/");
    } else {
      navigate("/Dashboard");
    }
  }, [logedIn]);
  useEffect(() => {
    if (!logedIn && currentRoute != "/") {
      navigate("/");
    } else {
      navigate(currentRoute);
    }
  }, [currentRoute]);

  return (
    <div className="App">
      <ToastContainer />
      {currentRoute !== "/" && (
        <>
          <Header setLoggedIn={setLoggedIn} />
          <Sidebar />
        </>
      )}

      <Routes>
        <Route path="/" element={<Login setLoggedIn={setLoggedIn} />} />
        <Route path="Dashboard" element={<Dashboard />} />
        <Route path="PushNotification" element={<PushNotification />} />
        <Route path="GoogleAnalytics" element={<GoogleAnalytics />} />
        <Route path="Maktab" element={<Maktab />} />
        <Route path="Employment" element={<Employment />} />
        <Route path="FreeFood" element={<FreeFood />} />
        <Route path="Medical" element={<Medical />} />
        <Route path="DailyPost" element={<DailyPost />} />
        <Route path="FAQ" element={<FAQ />} />
        <Route path="Gallery" element={<Gallery />} />
        <Route path="MobileApps" element={<MobileApps />} />
        <Route path="Products" element={<Products />} />
        <Route path="Publication" element={<Publication />} />
        <Route path="School" element={<School />} />
        <Route path="Quraan" element={<Quraan />} />
        <Route path="Syllabus" element={<Syllabus />} />
        <Route path="OtherBooks" element={<OtherBooks />} />
        <Route path="SyllabusDetails" element={<SyllabusDetails />} />
        <Route path="QuraanApps" element={<QuraanApps />} />
        <Route path="OtherApps" element={<OtherApps />} />
        <Route path="MaktabApps" element={<MaktabApps />} />
        <Route path="Assessment" element={<Assessment />} />

      </Routes>
    </div>
  );
}

export default App;
