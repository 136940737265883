import { AppContext } from "./AppContext";
import React, { useState } from "react";
const AppState = (props) => {
  const [sidebar, setSidebar] = useState(true);
  const [adminData, setAdminData] = useState({
    authorization: localStorage.getItem("authorization")
      ? localStorage.getItem("authorization")
      : null,

    user_id: localStorage.getItem("user_id")
      ? localStorage.getItem("user_id")
      : null,
  });

  return (
    <div>
      <AppContext.Provider
        value={{ sidebar, setSidebar, adminData, setAdminData }}
      >
        {props.children}
      </AppContext.Provider>
    </div>
  );
};

export default AppState;
