import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { Link, useLocation } from "react-router-dom";
import quraan_kareem_cover_9_line from "../assets/images/quraan_kareem_cover_9_line.png";
import ic_back from "../assets/images/ic_back.svg";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import ic_delete from "../assets/images/ic_delete.svg";
import ic_edit from "../assets/images/ic_edit.svg";
import ic_eyes from "../assets/images/ic_eyes.svg";
import ic_nursery_tr_1 from "../assets/images/ic_nursery_tr_1.png";
import panch_min_ka_madrasa from "../assets/images/panch_min_ka_madrasa.png";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import {
  PostCallWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
  putWithAuthCall,
  simpleDeleteCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import { ToastMsg } from "./TostMsg";
import FormLoader from "./FormLoader";

const Assessment = () => {
  const [UpdatedId, setUpdatedId] = useState(16);
  console.log("UpdatedId", UpdatedId);
  const [subCategoryId, setSubCategoryId] = useState(0);
  let job_desc_id = localStorage.getItem("jd_id");
  let subCatID;
  let categoryName;
  let school_workshop_ten_step_id;
  let medicalNames = "";
  let assessmentId;
  let workshop_ten_step_id;
  if (typeof window !== "undefined") {
    subCatID = localStorage.getItem("subCat");
    categoryName = localStorage.getItem("CategoryName");
    medicalNames = localStorage.getItem("medicalNames");
    school_workshop_ten_step_id = localStorage.getItem(
      "school_workshop_ten_step_id"
    );
    assessmentId = localStorage.getItem("assessmentId");
    workshop_ten_step_id = localStorage.getItem("workshop_ten_step_id");
  }
  console.log("medicalNames", medicalNames);

  useEffect(() => {
    setSubCategoryId(subCatID); // Correct: updating state based on the previous state
  }, []);

  const [isEdit, setIsEdit] = useState(false);
  const [validated, setValidated] = useState(false);

  const [assessmentList, setAssessmentList] = useState([]);
  const { sidebar, adminData } = useContext(AppContext);
  const [loader, setLoader] = useState(false);

  const [groupId, setGroupId] = useState(0);

  const [addQuraan, setAddQuraan] = useState(false);
  const handleAddQuraanClose = () => setAddQuraan(false);
  const handleAddQuraanShow = () => setAddQuraan(true);

  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);

  // const [assessmentId, setAssessmentId] = useState(0);

  const [updateAssessmentObj, setUpdateAssessmentObj] = useState([]);

  useEffect(() => {
    if (school_workshop_ten_step_id == 17) {
      setUpdatedId(9);
    }
    if (school_workshop_ten_step_id == 16) {
      setUpdatedId(15);
    }
    if (school_workshop_ten_step_id == 18) {
      setUpdatedId(16);
    }
    if (school_workshop_ten_step_id == 20) {
      setUpdatedId(17);
    }
    if (school_workshop_ten_step_id == 21) {
      setUpdatedId(18);
    }
    if (school_workshop_ten_step_id == 22) {
      setUpdatedId(19);
    }
    if (school_workshop_ten_step_id == 23) {
      setUpdatedId(20);
    }
    if (school_workshop_ten_step_id == 24) {
      setUpdatedId(21);
    }
    if (school_workshop_ten_step_id == 25) {
      setUpdatedId(22);
    }
    if (school_workshop_ten_step_id == 26) {
      setUpdatedId(23);
    }
  }, []);

  const [assessmentObj, setAssessmentObj] = useState({
    english_question: "",
    urdu_question: "",
    hindi_question: "",
    transliteration_question: "",
    marks: 10,
    time_limit: 30,
    group_id: null,
    options: [
      {
        english_option: "",
        urdu_option: "",
        hindi_option: "",
        transliteration_option: "",
        is_answer: false,
      },
      {
        english_option: "",
        urdu_option: "",
        hindi_option: "",
        transliteration_option: "",
        is_answer: false,
      },
      {
        english_option: "",
        urdu_option: "",
        hindi_option: "",
        transliteration_option: "",
        is_answer: false,
      },
      {
        english_option: "",
        urdu_option: "",
        hindi_option: "",
        transliteration_option: "",
        is_answer: false,
      },
    ],
  });

  console.log("assessmentObj", assessmentObj);

  const handleOptionChange = (index, field, value) => {
    setAssessmentObj((prevQuestion) => {
      const updatedOptions = prevQuestion.options.map((option, i) => {
        if (i == index) {
          if (field != "is_answer") {
            return { ...option, [field]: value };
          } else {
            return { ...option, [field]: true };
          }
        }
        if (field == "is_answer") {
          return { ...option, [field]: false };
        }
        return option;
      });

      return {
        ...prevQuestion,
        options: updatedOptions,
      };
    });
  };

  const handleSubmit = (event) => {
    console.log("handleSubmit Clicked", event);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      submitAssessment(event);
    }

    setValidated(true);
  };

  const submitAssessment = (e) => {
    e.preventDefault();
    handleAddQuraanClose();
    setLoader(true);
    !isEdit
      ? PostCallWithErrorResponse(
          ApiConfig.ADD_ASSESSMENT_FORM +
            `?assessment_id=${assessmentId || UpdatedId}`,
          JSON.stringify(assessmentObj)
        )
          .then((res) => {
            setLoader(false);
            if (res.json && res.json?.result === true) {
              assessmentListApi(
                subCatID == 2 && assessmentId <= 23
                  ? ApiConfig.GET_ASSESSMENT_LIST +
                      `?subcategory_id=${subCatID || 2}&jd_id=${job_desc_id}`
                  : subCatID == 5
                  ? ApiConfig.GET_ASSESSMENT_LIST +
                    `?subcategory_id=${
                      subCatID || 5
                    }&school_workshop_ten_step_id=${school_workshop_ten_step_id}`
                  : subCatID == 2 && assessmentId >= 24 && assessmentId <= 33
                  ? ApiConfig.GET_ASSESSMENT_LIST +
                    `?subcategory_id=${
                      subCatID || 2
                    }&workshop_ten_step_id=${workshop_ten_step_id}`
                  : ApiConfig.GET_ASSESSMENT_LIST +
                    `?subcategory_id=${subCatID}`
              );
              ToastMsg("success", "Successfully Added Assessment");
            } else {
              ToastMsg("error", res.json.message);
            }
          })
          .catch((err) => {
            setLoader(false);
            ToastMsg("error", "something went wrong");
          })
      : putWithAuthCall(
          ApiConfig.UPDATE_ASSESSMENT_FORM,
          JSON.stringify(assessmentObj)
        )
          .then((res) => {
            setLoader(false);
            if (res && res.result === true) {
              setLoader(false);
              assessmentListApi(
                subCatID == 2 && assessmentId <= 23
                  ? ApiConfig.GET_ASSESSMENT_LIST +
                      `?subcategory_id=${subCatID || 2}&jd_id=${job_desc_id}`
                  : subCatID == 5
                  ? ApiConfig.GET_ASSESSMENT_LIST +
                    `?subcategory_id=${
                      subCatID || 5
                    }&school_workshop_ten_step_id=${school_workshop_ten_step_id}`
                  : subCatID == 2 && assessmentId >= 24 && assessmentId <= 33
                  ? ApiConfig.GET_ASSESSMENT_LIST +
                    `?subcategory_id=${
                      subCatID || 2
                    }&workshop_ten_step_id=${workshop_ten_step_id}`
                  : ApiConfig.GET_ASSESSMENT_LIST +
                    `?subcategory_id=${subCatID}`
              );

              ToastMsg("success", "Successfully Updated Assessment");
            } else {
              ToastMsg("error", res.json.message);
            }
          })
          .catch((err) => {
            setLoader(false);
            // ToastMsg("error", "something went wrong");
          });
  };

  const assessmentListApi = (urldata) => {
    setLoader(true);
    simpleGetCallWithErrorResponse(urldata)
      .then((res) => {
        console.log("response", res);
        // setAssessmentId(res.json.data.assessment_details?.assessment_id);
        setLoader(false);
        setAssessmentList(res.json.data?.assessment_questions);
        let filterData =
          res.json.data?.assessment_questions?.assessment_questions.map(
            (cardData) => {
              return cardData.group;
            }
          );
        let ultraFilterData = filterData?.map((groupData) => {
          return groupData;
        });
        const newArry = [ultraFilterData];
      })
      .catch((err) => {
        setLoader(false);
        ToastMsg("error", "something went wrong");
      });
  };

  useEffect(() => {
    if (subCatID == 2 && assessmentId <= 23) {
      assessmentListApi(
        ApiConfig.GET_ASSESSMENT_LIST +
          `?subcategory_id=${subCatID || 2}&jd_id=${job_desc_id}`
      );
    } else if (subCatID == 5) {
      assessmentListApi(
        ApiConfig.GET_ASSESSMENT_LIST +
          `?subcategory_id=${
            subCatID || 5
          }&school_workshop_ten_step_id=${school_workshop_ten_step_id}`
      );
    } else if (subCatID == 2 && assessmentId >= 24 && assessmentId <= 33) {
      assessmentListApi(
        ApiConfig.GET_ASSESSMENT_LIST +
          `?subcategory_id=${
            subCatID || 2
          }&workshop_ten_step_id=${workshop_ten_step_id}`
      );
    } else {
      assessmentListApi(
        ApiConfig.GET_ASSESSMENT_LIST + `?subcategory_id=${subCatID}`
      );
    }
  }, [subCategoryId]);

  // get Group Id of Assessment
  const deleteAssessment = (id) => {
    setGroupId(id);
  };

  // Delete Assessment
  const deleteAssessmentPopUp = () => {
    handleDeleteClose();
    setLoader(true);
    simpleDeleteCallWithErrorResponse(
      ApiConfig.DELETE_ASSESSMENT_FORM + `?group_id=${groupId}`
    )
      .then((res) => {
        setLoader(false);
        handleDeleteClose();
        assessmentListApi(
          subCatID == 2 && assessmentId <= 23
            ? ApiConfig.GET_ASSESSMENT_LIST +
                `?subcategory_id=${subCatID || 2}&jd_id=${job_desc_id}`
            : subCatID == 5
            ? ApiConfig.GET_ASSESSMENT_LIST +
              `?subcategory_id=${
                subCatID || 5
              }&school_workshop_ten_step_id=${school_workshop_ten_step_id}`
            : subCatID == 2 && assessmentId >= 24 && assessmentId <= 33
            ? ApiConfig.GET_ASSESSMENT_LIST +
              `?subcategory_id=${
                subCatID || 2
              }&workshop_ten_step_id=${workshop_ten_step_id}`
            : ApiConfig.GET_ASSESSMENT_LIST + `?subcategory_id=${subCatID}`
        );

        ToastMsg("success", "Successfully Deleted");
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        assessmentListApi(
          subCatID == 2 && assessmentId <= 23
            ? ApiConfig.GET_ASSESSMENT_LIST +
                `?subcategory_id=${subCatID || 2}&jd_id=${job_desc_id}`
            : subCatID == 5
            ? ApiConfig.GET_ASSESSMENT_LIST +
              `?subcategory_id=${
                subCatID || 5
              }&school_workshop_ten_step_id=${school_workshop_ten_step_id}`
            : subCatID == 2 && assessmentId >= 24 && assessmentId <= 33
            ? ApiConfig.GET_ASSESSMENT_LIST +
              `?subcategory_id=${
                subCatID || 2
              }&workshop_ten_step_id=${workshop_ten_step_id}`
            : ApiConfig.GET_ASSESSMENT_LIST + `?subcategory_id=${subCatID}`
        );
      });
  };

  // Edit Assessment
  const getEditData = (id) => {
    // setGroupId(id);
    simpleGetCallWithErrorResponse(
      ApiConfig.GET_EDIT_ASSESSMENT_FORM + `?group_id=${id}`
    )
      .then((res) => {
        var assessmentID = res.json.data[0]?.assessment_id;
        let data = res.json.data;
        let optionIDArray1 = [];
        let optionIDArray2 = [];
        let optionIDArray3 = [];
        let optionIDArray4 = [];

        data.map((obj) => {
          if (obj.language_id == "1") {
            optionIDArray1[0] = obj.options[0].option_id;
            optionIDArray2[0] = obj.options[1].option_id;
            optionIDArray3[0] = obj.options[2].option_id;
            optionIDArray4[0] = obj.options[3].option_id;
          } else if (obj.language_id == "2") {
            optionIDArray1[1] = obj.options[0].option_id;
            optionIDArray2[1] = obj.options[1].option_id;
            optionIDArray3[1] = obj.options[2].option_id;
            optionIDArray4[1] = obj.options[3].option_id;
          } else if (obj.language_id == "3") {
            optionIDArray1[2] = obj.options[0].option_id;
            optionIDArray2[2] = obj.options[1].option_id;
            optionIDArray3[2] = obj.options[2].option_id;
            optionIDArray4[2] = obj.options[3].option_id;
          } else if (obj.language_id == "4") {
            optionIDArray1[3] = obj.options[0].option_id;
            optionIDArray2[3] = obj.options[1].option_id;
            optionIDArray3[3] = obj.options[2].option_id;
            optionIDArray4[3] = obj.options[3].option_id;
          }
        });

        setAssessmentObj({
          english_question: data[0]?.question,
          urdu_question: data[1]?.question,
          hindi_question: data[3]?.question,
          transliteration_question: data[2]?.question,
          marks: 10,
          assessment_id: assessmentID,
          time_limit: 30,
          group_id: Number(id),
          options: [
            {
              option_id: optionIDArray1,
              english_option: data[0]?.options[0]?.option,
              urdu_option: data[1]?.options[0]?.option,
              hindi_option: data[3]?.options[0]?.option,
              transliteration_option: data[2]?.options[0]?.option,
              is_answer: false,
            },
            {
              option_id: optionIDArray2,
              english_option: data[0]?.options[1]?.option,
              urdu_option: data[1]?.options[1]?.option,
              hindi_option: data[3]?.options[1]?.option,
              transliteration_option: data[2]?.options[1]?.option,
              is_answer: false,
            },
            {
              option_id: optionIDArray3,
              english_option: data[0]?.options[2]?.option,
              urdu_option: data[1]?.options[2]?.option,
              hindi_option: data[3]?.options[2]?.option,
              transliteration_option: data[2]?.options[2]?.option,
              is_answer: false,
            },
            {
              option_id: optionIDArray4,
              english_option: data[0]?.options[3]?.option,
              urdu_option: data[1]?.options[3]?.option,
              hindi_option: data[3]?.options[3]?.option,
              transliteration_option: data[2]?.options[3]?.option,
              is_answer: false,
            },
          ],
        });
      })
      .catch((err) => {
        console.log(err);
        ToastMsg("error", "something went wrong");
      });
  };

  const handleAddAssessmentShow = () => {
    setAssessmentObj({
      english_question: "",
      urdu_question: "",
      hindi_question: "",
      transliteration_question: "",
      marks: 10,
      time_limit: 30,
      group_id: null,
      options: [
        {
          english_option: "",
          urdu_option: "",
          hindi_option: "",
          transliteration_option: "",
          is_answer: false,
        },
        {
          english_option: "",
          urdu_option: "",
          hindi_option: "",
          transliteration_option: "",
          is_answer: false,
        },
        {
          english_option: "",
          urdu_option: "",
          hindi_option: "",
          transliteration_option: "",
          is_answer: false,
        },
        {
          english_option: "",
          urdu_option: "",
          hindi_option: "",
          transliteration_option: "",
          is_answer: false,
        },
      ],
    });
    setValidated(false);
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">
            {/* {medicalNames ? `${categoryName} ${medicalNames}` : categoryName} Assessment */}

            {categoryName == "MEDICAL" && medicalNames ? (
              <>{`${medicalNames}`} Assessment</>
            ) : (
              <>{categoryName || null} Assessment</>
            )}
          </label>
        </div>
      </div>

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"
              onClick={deleteAssessmentPopUp}
            >
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>

      {/* ======================= END DELETE ========================== */}

      <div className="main-wrapper" id="maktab-main">
        <hr />
        <div className="" id="assessment-main">
          <div className="feature-lists publication-tab-content row g-3">
            <div className="add-btn">
              <button
                onClick={() => {
                  handleAddQuraanShow();
                  setIsEdit(false);
                  handleAddAssessmentShow();
                }}
              >
                Add +
              </button>
            </div>

            {loader ? (
              <FormLoader />
            ) : (
              <>
                {assessmentList?.assessment_questions &&
                assessmentList?.assessment_questions.length > 0 ? (
                  assessmentList?.assessment_questions?.map((card, index) => {
                    console.log("Question ID", card);
                    return (
                      <div className="col-md-4" key={index}>
                        <div className="books-card-main">
                          <div className="btns-top">
                            <div className="q-number">
                              <h4>
                                Q.<span>{index + 1}</span>
                              </h4>
                            </div>
                            <div>
                              <button
                                className="delete "
                                onClick={() => {
                                  handleDeleteShow();
                                  deleteAssessment(card.group_id);
                                }}
                              >
                                <img src={ic_delete} alt="" />
                              </button>
                              <button
                                onClick={() => {
                                  handleAddQuraanShow();
                                  setIsEdit(true);
                                  getEditData(card.group_id);
                                }}
                                className="edit"
                              >
                                <img src={ic_edit} alt="" />
                              </button>
                            </div>
                          </div>
                          <div className="assessment-card">
                            <div>
                              {card.group &&
                                card.group?.map((group) => {
                                  return (
                                    <>
                                      <p className="q-key">{group.question}</p>

                                      {group?.options?.map((op, index) => {
                                        return (
                                          <>
                                            <ol className="options">
                                              {op.is_answer ? (
                                                <li
                                                  style={{
                                                    listStyleType: "none",
                                                  }}
                                                >
                                                  <label
                                                    htmlFor=""
                                                    className="correct-ans"
                                                  >
                                                    {op.option}
                                                  </label>
                                                </li>
                                              ) : (
                                                <li
                                                  style={{
                                                    listStyleType: "none",
                                                  }}
                                                >
                                                  <label htmlFor="">
                                                    {op.option}
                                                  </label>
                                                </li>
                                              )}
                                            </ol>
                                          </>
                                        );
                                      })}
                                      <hr />
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <h5 className="text-center text-danger">No Data Found</h5>
                  </>
                )}
              </>
            )}
          </div>

          <Modal
            show={addQuraan}
            onHide={handleAddQuraanClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {isEdit ? "Update Assessment" : "Add Assessment"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="feature-wrapper">
                  <div className="row">
                    {/* english */}
                    <div className="col-md-12 mb-4">
                      <Form.Label>English Question</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        value={assessmentObj?.english_question}
                        onChange={(e) => {
                          setAssessmentObj({
                            ...assessmentObj,
                            english_question: e.target.value,
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter English Question
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>English Option 1 </Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          type="radio"
                          required
                          className="me-3"
                          name="option1"
                          onChange={(e) => {
                            handleOptionChange(
                              0,
                              "is_answer",
                              e.target.checked
                            );
                          }}
                        />
                        <Form.Control
                          type="text"
                          required
                          value={assessmentObj?.options[0]?.english_option}
                          onChange={(e) =>
                            handleOptionChange(
                              0,
                              "english_option",
                              e.target.value
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Option 1
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>English Option 2 </Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          type="radio"
                          required
                          className="me-3"
                          name="option1"
                          onChange={(e) =>
                            handleOptionChange(1, "is_answer", e.target.checked)
                          }
                        />
                        <Form.Control
                          type="text"
                          required
                          value={assessmentObj?.options[1]?.english_option}
                          onChange={(e) =>
                            handleOptionChange(
                              1,
                              "english_option",
                              e.target.value
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Option 2
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>English Option 3 </Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          required
                          type="radio"
                          className="me-3"
                          name="option1"
                          onChange={(e) =>
                            handleOptionChange(2, "is_answer", e.target.checked)
                          }
                        />
                        <Form.Control
                          type="text"
                          required
                          value={assessmentObj?.options[2]?.english_option}
                          onChange={(e) =>
                            handleOptionChange(
                              2,
                              "english_option",
                              e.target.value
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Option 3
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>English Option 4 </Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          required
                          type="radio"
                          className="me-3"
                          name="option1"
                          onChange={(e) =>
                            handleOptionChange(3, "is_answer", e.target.checked)
                          }
                        />
                        <Form.Control
                          type="text"
                          required
                          value={assessmentObj?.options[3]?.english_option}
                          onChange={(e) =>
                            handleOptionChange(
                              3,
                              "english_option",
                              e.target.value
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Option 4
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <hr />
                    {/* urdu */}
                    <div className="col-md-12 mb-4">
                      <Form.Label>Urdu Question</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        value={assessmentObj?.urdu_question}
                        onChange={(e) => {
                          setAssessmentObj({
                            ...assessmentObj,
                            urdu_question: e.target.value,
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Urdu Question
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Urdu Option 1 </Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          required
                          type="radio"
                          className="me-3"
                          name="option2"
                          onChange={(e) =>
                            handleOptionChange(0, "is_answer", e.target.checked)
                          }
                        />
                        <Form.Control
                          type="text"
                          required
                          value={assessmentObj?.options[0]?.urdu_option}
                          onChange={(e) =>
                            handleOptionChange(0, "urdu_option", e.target.value)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Option 1
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Urdu Option 2</Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          required
                          type="radio"
                          className="me-3"
                          name="option2"
                          onChange={(e) =>
                            handleOptionChange(1, "is_answer", e.target.checked)
                          }
                        />
                        <Form.Control
                          type="text"
                          required
                          style={{ direction: "rtl", unicodeBidi: "plaintext" }}
                          value={assessmentObj?.options[1]?.urdu_option}
                          onChange={(e) =>
                            // handleOptionChange(1, "urdu_option", e.target.value)
                            handleOptionChange(
                              1,
                              "urdu_option",
                              e.target.value.toLocaleString("ur-PK")
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Option 2
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Urdu Option 3 </Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          required
                          type="radio"
                          className="me-3"
                          name="option2"
                          onChange={(e) =>
                            handleOptionChange(2, "is_answer", e.target.checked)
                          }
                        />
                        <Form.Control
                          type="text"
                          required
                          value={assessmentObj?.options[2]?.urdu_option}
                          onChange={(e) =>
                            handleOptionChange(2, "urdu_option", e.target.value)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Option 3
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Urdu Option 4 </Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          required
                          type="radio"
                          className="me-3"
                          name="option2"
                          onChange={(e) =>
                            handleOptionChange(3, "is_answer", e.target.checked)
                          }
                        />
                        <Form.Control
                          type="text"
                          required
                          value={assessmentObj?.options[3]?.urdu_option}
                          onChange={(e) =>
                            handleOptionChange(3, "urdu_option", e.target.value)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Option 4
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <hr />
                    <div className="col-md-12 mb-4">
                      <Form.Label>Hindi Question</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        value={assessmentObj?.hindi_question}
                        onChange={(e) => {
                          setAssessmentObj({
                            ...assessmentObj,
                            hindi_question: e.target.value,
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Hindi Question
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Hindi Option 1 </Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          required
                          type="radio"
                          className="me-3"
                          name="option3"
                          onChange={(e) =>
                            handleOptionChange(0, "is_answer", e.target.checked)
                          }
                        />
                        <Form.Control
                          type="text"
                          required
                          value={assessmentObj?.options[0]?.hindi_option}
                          onChange={(e) =>
                            handleOptionChange(
                              0,
                              "hindi_option",
                              e.target.value
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Option 1
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Hindi Option 2 </Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          required
                          type="radio"
                          className="me-3"
                          name="option3"
                          onChange={(e) =>
                            handleOptionChange(1, "is_answer", e.target.checked)
                          }
                        />
                        <Form.Control
                          type="text"
                          required
                          value={assessmentObj?.options[1]?.hindi_option}
                          onChange={(e) =>
                            handleOptionChange(
                              1,
                              "hindi_option",
                              e.target.value
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Option 2
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Hindi Option 3 </Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          required
                          type="radio"
                          className="me-3"
                          name="option3"
                          onChange={(e) =>
                            handleOptionChange(2, "is_answer", e.target.checked)
                          }
                        />
                        <Form.Control
                          type="text"
                          required
                          value={assessmentObj?.options[2]?.hindi_option}
                          onChange={(e) =>
                            handleOptionChange(
                              2,
                              "hindi_option",
                              e.target.value
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Option 3
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Hindi Option 4 </Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          required
                          type="radio"
                          className="me-3"
                          name="option3"
                          onChange={(e) =>
                            handleOptionChange(3, "is_answer", e.target.checked)
                          }
                        />
                        <Form.Control
                          type="text"
                          required
                          value={assessmentObj?.options[3]?.hindi_option}
                          onChange={(e) =>
                            handleOptionChange(
                              3,
                              "hindi_option",
                              e.target.value
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Option 4
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <hr />
                    {/* Transleteration */}
                    <div className="col-md-12 mb-4">
                      <Form.Label>Transleteration Question</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={assessmentObj?.transliteration_question}
                        onChange={(e) => {
                          setAssessmentObj({
                            ...assessmentObj,
                            transliteration_question: e.target.value,
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Transleteration Question
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Transleteration Option 1 </Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          required
                          type="radio"
                          className="me-3"
                          name="option4"
                          onChange={(e) =>
                            handleOptionChange(0, "is_answer", e.target.checked)
                          }
                        />
                        <Form.Control
                          type="text"
                          required
                          value={
                            assessmentObj?.options[0]?.transliteration_option
                          }
                          onChange={(e) =>
                            handleOptionChange(
                              0,
                              "transliteration_option",
                              e.target.value
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Option 1
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Transleteration Option 2 </Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          required
                          type="radio"
                          className="me-3"
                          name="option4"
                          onChange={(e) =>
                            handleOptionChange(1, "is_answer", e.target.checked)
                          }
                        />
                        <Form.Control
                          type="text"
                          required
                          value={
                            assessmentObj?.options[1]?.transliteration_option
                          }
                          onChange={(e) =>
                            handleOptionChange(
                              1,
                              "transliteration_option",
                              e.target.value
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Option 2
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Transleteration Option 3 </Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          required
                          type="radio"
                          className="me-3"
                          name="option4"
                          onChange={(e) =>
                            handleOptionChange(2, "is_answer", e.target.checked)
                          }
                        />
                        <Form.Control
                          type="text"
                          required
                          value={
                            assessmentObj?.options[2]?.transliteration_option
                          }
                          onChange={(e) =>
                            handleOptionChange(
                              2,
                              "transliteration_option",
                              e.target.value
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Option 3
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Label>Transleteration Option 4 </Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Check
                          required
                          type="radio"
                          className="me-3"
                          name="option4"
                          onChange={(e) =>
                            handleOptionChange(3, "is_answer", e.target.checked)
                          }
                        />
                        <Form.Control
                          type="text"
                          required
                          value={
                            assessmentObj?.options[3]?.transliteration_option
                          }
                          onChange={(e) =>
                            handleOptionChange(
                              3,
                              "transliteration_option",
                              e.target.value
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Option 4
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>
                  <div className="buttons-main">
                    {/* <button className="cancel-btn">Cancel</button> */}

                    <button
                      className="submit-btn"
                      // onClick={submitAssessment}
                    >
                      {isEdit ? "Update" : "Submit"}
                    </button>
                  </div>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </main>
  );
};

export default Assessment;
