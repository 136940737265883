import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import thumb1 from "../../assets/images/video_thumbnails/1.png";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import ic_maktab_icon from "../../assets/images/ic_maktab_icon.png";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import SurahFatiha from "../../assets/SurahFatiha.mp3";
import ic_delete from "../../assets/images/ic_delete.svg";
import ic_edit from "../../assets/images/ic_edit.svg";
import ic_eyes from "../../assets/images/ic_eyes.svg";
import upload from "../../assets/images/upload.svg";

import Modal from "react-bootstrap/Modal";
import {
  DeleteCallWithErrorResponse,
  PostCallWithErrorResponse,
  putCallWithAuthTokenWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import FormLoader from "../FormLoader";
const FreeFood = () => {
  const { sidebar, adminData } = useContext(AppContext);
  const [idEdit, setIdEdit] = useState("");
  const [deleteId, setDeleteId] = useState("");

  const [videoEditData, setVideoEditData] = useState({
    subcategory_media_VideoName_hindi: "",
    subcategory_media_VideoPath_hindi: "",
    subcategory_media_VideoDuaration_hindi: "",
    subcategory_media_VideoName_lit: " ",
    subcategory_media_VideoPath_lit: "",
    subcategory_media_VideoDuaration_lit: " ",
    subcategory_media_VideoName_urdu: "",
    subcategory_media_VideoPath_urdu: "",
    subcategory_media_VideoDuaration_urdu: "",
    subcategory_media_VideoName_english: " ",
    subcategory_media_VideoPath_english: "",
    subcategory_media_VideoDuaration_english: "",
    subcategory_id: 2,
  });
  const handleChangeVideoEdit = (event) => {
    const { name, value } = event.target;
    const videoEditDatacopy = { ...videoEditData };
    videoEditDatacopy[name] = value;
    setVideoEditData(videoEditDatacopy);
  };
  const [addWorkShopSteps, setAddWorkShopSteps] = useState({
    urdu_name: "",
    english_name: "",
    english_lit_name: "",
    hindi_name: "",
    english_lit_description: "",
    urdu_description: "",
    english_description: "",
    hindi_description: "",
    urdu_video_path: "-",
    hindi_video_path: "-",
    english_lit_video_path: "-",
    english_video_path: "-",
    urdu_duaration: "-",
    english_duaration: "-",
    english_lit_duaration: "-",
    hindi_duaration: "-",
    step_no_hindi: "-",
    step_no_lit: "-",
    step_no_english: "-",
    step_no_urdu: "-",
    is_active: true,
    subcategory_id: 4,
  });
  const handleWorkShopSteps = (event) => {
    const { name, value } = event.target;
    const addWorkShopStepsCopy = { ...addWorkShopSteps };
    addWorkShopStepsCopy[name] = value;
    setAddWorkShopSteps(addWorkShopStepsCopy);
  };

  const [AddNewGroup, setAddNewGroup] = useState(0);

  const [addFeature, setAddFeature] = useState(false);
  const handleAddFeatureClose = () => setAddFeature(false);
  const handleAddFeatureShow = () => setAddFeature(true);

  const [addWorksrhop, setAddWorksrhop] = useState(false);
  const handleAddWorksrhopClose = () => setAddWorksrhop(false);
  const handleAddWorksrhopShow = () => setAddWorksrhop(true);

  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);

  const [MainVideo, setMainVideo] = useState(false);
  const handleMainVideoClose = () => setMainVideo(false);
  const handleMainVideoShow = () => setMainVideo(true);

  const [AddSteps, setAddSteps] = useState(false);
  const handleAddStepsClose = () => {
    setIdEdit("");
    setAddWorkShopSteps({
      urdu_name: "",
      english_name: "",
      english_lit_name: "",
      hindi_name: "",
      english_lit_description: "",
      urdu_description: "",
      english_description: "",
      hindi_description: "",
      urdu_video_path: "-",
      hindi_video_path: "-",
      english_lit_video_path: "-",
      english_video_path: "-",
      urdu_duaration: "-",
      english_duaration: "-",
      english_lit_duaration: "-",
      hindi_duaration: "-",
      step_no_hindi: "-",
      step_no_lit: "-",
      step_no_english: "-",
      step_no_urdu: "-",
      is_active: true,
      subcategory_id: 4,
    });
    setAddSteps(false);
  };
  const handleAddStepsShow = () => setAddSteps(true);
  const [loader, setLoader] = useState(false);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event, section) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (section == "workshop") {
        addWorkShopPostApi(event);
      }
      if (section === "videos") {
        updateMediaApi(event);
      }
    }

    setValidated(true);
  };
  useEffect(() => {
    workShopListApi(1);
    videoApiList();
  }, []);
  const [workShopList, setWorkShopList] = useState([]);
  const [vedioApiList, setvedioApiList] = useState([]);
  const addWorkShopPostApi = (e) => {
    e.preventDefault();
    handleAddStepsClose();
    setLoader(true);
    idEdit === "edit"
      ? putCallWithAuthTokenWithErrorResponse(
          ApiConfig.WORKSHOP_UPDATE + `?subcategory_id=${4}`,
          JSON.stringify({
            ...addWorkShopSteps,
            modified_by_id: adminData.user_id,
            created_by_id: adminData.user_id,
            is_active: true,
          })
        )
          .then((res) => {
            if (res.json && res.json?.result === true) {
              ToastMsg("success", "Successfully Updated");
              workShopListApi();
            } else {
              ToastMsg("error", res.json.message);
            }
          })
          .catch((err) => {
            setLoader(false);

            ToastMsg("error", "something went wrong");
          })
      : PostCallWithErrorResponse(
          ApiConfig.WORKSHOP_ADD + `?subcategory_id=${4}`,

          JSON.stringify({
            ...addWorkShopSteps,
            created_by_id: adminData.user_id,
            // school_workshop_ten_step_id: addBtn,
          })
        )
          .then((res) => {
            if (res.json && res.json?.result === true) {
              ToastMsg("success", "Successfully Added");
              workShopListApi();
            } else {
              ToastMsg("error", res.json.message);
            }
          })
          .catch((err) => {
            setLoader(false);

            ToastMsg("error", "something went wrong");
          });
  };
  const workShopListApi = (id) => {
    setLoader(true);
    // ?subcategory_id=3&school_workshop_ten_step_id=10
    simpleGetCallWithErrorResponse(
      ApiConfig.WORKSHOP_LIST + `?subcategory_id=${4}`
    )
      .then((res) => {
        console.log(res);
        if (res.json && res.json?.result === true) {
          setLoader(false);

          setWorkShopList(res.json.workshopData);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const videoApiList = () => {
    setLoader(true);
    // ?subcategory_id=3&school_workshop_ten_step_id=10
    simpleGetCallWithErrorResponse(
      ApiConfig.GET_MEDIEA + `?subcategory_id=${4}`
    )
      .then((res) => {
        console.log(res);
        if (res.json && res.json?.result === true) {
          setLoader(false);

          setvedioApiList(res.json.media);
          setVideoEditData(res.json.editmedia);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  const updateMediaApi = (e) => {
    e.preventDefault();
    handleMainVideoClose();
    putCallWithAuthTokenWithErrorResponse(
      ApiConfig.UPDATE_MEDIEA + `?subcategory_id=${4}`,
      JSON.stringify({
        ...videoEditData,
        modified_by_id: adminData.user_id,
      })
    )
      .then((res) => {
        if (res.json && res.json?.result === true) {
          ToastMsg("success", "Video Successfully Updated");
          videoApiList();
          // tenStepsListApi(addBtn);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  const featureDeleteApi = () => {
    handleDeleteClose();

    // setIsLoading(true);
    DeleteCallWithErrorResponse(
      ApiConfig.WORKSHOP_DELETEAPI + `?workshop_ten_step_id=${deleteId}`
    )
      .then((res) => {
        setLoader(false);
        if (res.json && res.json?.result === true) {
          workShopListApi();
          ToastMsg("success", "Successfully Deleted");
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  const tenStepsListApiEdit = (get) => {
    setLoader(true);
    simpleGetCallWithErrorResponse(
      ApiConfig.WORKSHOP_EDIT + `?workshop_ten_step_id=${get}`
    )
      .then((res) => {
        if (res.response.status === 200) {
          setLoader(false);
          setAddWorkShopSteps(res.json.editwsk);
          // setTenStepsList(res.json.data);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  const navigate = useNavigate();
  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Free Food</label>
        </div>
      </div>

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              type="submit"
              onClick={() => featureDeleteApi()}
            >
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>

      {/* ======================= END DELETE ========================== */}

      <div className="main-wrapper" id="maktab-main">
        <div className="main-all-video ">
          {vedioApiList?.length > 0 && (
            <div className="right">
              <div className="add-btn">
                <button onClick={handleMainVideoShow}>
                  <img src={ic_edit} alt="" />
                </button>
              </div>
            </div>
          )}

          {vedioApiList?.length > 0 ? (
            vedioApiList?.map((videolist, index) => {
              return (
                <div className="outer-main-video row" key={index}>
                  {videolist.sub_category_media.map((subitem, index) => {
                    return (
                      <div className="col-md-3 outer-main-video" key={index}>
                        <h6>
                          {(subitem.language_id === 4 && <span>Hindi</span>) ||
                            (subitem.language_id === 1 && (
                              <span>English</span>
                            )) ||
                            (subitem.language_id === 2 && <span>Urdu</span>) ||
                            (subitem.language_id === 3 && (
                              <span>Tansliteration </span>
                            ))}{" "}
                          Video
                        </h6>
                        <div className="video">
                        <a href={subitem.subcategory_media_VideoPath}
                              target="_blank"
                            >
                              <img src={thumb1} alt="" />
                              <div className="play-btn">
                              <button class="button">
                                <span class="arrow"></span>
                                Play
                              </button>
                            </div>
                            </a>
                          {/* <video controls poster={thumb1}>
                            <source
                              src={subitem.subcategory_media_VideoPath}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video> */}
                        </div>
                        <div className="content">
                          <div className="left">
                            <div className="step-name">
                              <label htmlFor="" className="key">
                                Name:
                              </label>
                              <label htmlFor="" className="value ms-2">
                                {subitem.subcategory_media_VideoName}
                              </label>
                            </div>
                            <div className="duration">
                              <label htmlFor="" className="key">
                                Duration:
                              </label>
                              <label htmlFor="" className="value ms-2">
                                {subitem.subcategory_media_VideoDuaration}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <h5 className="text-center text-danger">No Data Found</h5>
          )}

          <Modal
            show={MainVideo}
            onHide={handleMainVideoClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                noValidate
                validated={validated}
                // onSubmit={(e) => handleSubmit(e,"videos")}
                onSubmit={(e) => handleSubmit(e, "videos")}
              >
                <div className="feature-wrapper">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <Form.Label>English URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_english"
                        value={
                          videoEditData.subcategory_media_VideoPath_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter English URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Hindi URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_hindi"
                        value={videoEditData.subcategory_media_VideoPath_hindi}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Hindi URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Urdu URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_urdu"
                        value={videoEditData.subcategory_media_VideoPath_urdu}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Urdu URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Transliteration URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_lit"
                        value={videoEditData.subcategory_media_VideoPath_lit}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Transliteration URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name English</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_english"
                        value={
                          videoEditData.subcategory_media_VideoName_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name English
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Hindi</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_hindi"
                        value={videoEditData.subcategory_media_VideoName_hindi}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name Hindi
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Urdu</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_urdu"
                        value={videoEditData.subcategory_media_VideoName_urdu}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Transliteration</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_lit"
                        value={videoEditData.subcategory_media_VideoName_lit}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name Urdu
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration English</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_english"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration English
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Hindi</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_hindi"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_hindi
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Hindi
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Urdu</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_urdu"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_urdu
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Urdu
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Transliteration</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_lit"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_lit
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Transliteration
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Form.Group
                          controlId="formBasicPassword"
                        >
                          <Form.Label>English Video Thumbnail</Form.Label>
                          <Form.Control
                            type="file"
                            placeholder="Select Thumbnail for English Video"
                          />
                        </Form.Group>
                      </div>
                      
                      <div className="col-md-6 mb-3">
                        <Form.Group
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Hindi Video Thumbnail</Form.Label>
                          <Form.Control
                            type="file"
                            placeholder="Select Thumbnail for Hindi Video"
                          />
                        </Form.Group>
                      </div>

                      <div className="col-md-6 mb-3">
                        <Form.Group
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Urdu Video Thumbnail</Form.Label>
                          <Form.Control
                            type="file"
                            placeholder="Select Thumbnail for Urdu Video"
                          />
                        </Form.Group>
                      </div>
                       
                      <div className="col-md-6 mb-3">
                        <Form.Group
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Transliteration Video Thumbnail</Form.Label>
                          <Form.Control
                            type="file"
                            placeholder="Select Thumbnail for Transliteration Video"
                          />
                        </Form.Group>
                      </div>

                    <div className="buttons-modal  text-center mt-2  ">
                      <button className="me-2 ">MEDIA UPDATE</button>
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            {/* <Modal.Footer className="justify-content-center">
           
            </Modal.Footer> */}
          </Modal>
        </div>
        <hr />
        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
          <Nav variant="pills" className="flex-row">
            <Nav.Item>
              <Nav.Link eventKey="1">Workshop</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            {/* ======= Steps ======= */}
            <Tab.Pane eventKey="1">
              <div className="jobDescriptio-main">
                <div className="wrapper">
                  <div className="add-btn">
                    <button onClick={handleAddStepsShow}>Add +</button>
                    <button
                      onClick={() => {
                        localStorage.setItem("subCat", 4);
                        localStorage.setItem("assessmentId", 8);
                        // handleAddTrusteeShow(idJOBSCategory);
                        navigate("/Assessment");
                      }}
                      className="ms-2"
                    >
                      Assessment
                    </button>
                  </div>
                 
                  <div className="row jd-main g-3">
                    {loader ? (
                      <FormLoader />
                    ) : (
                      <>
                        {workShopList && workShopList.length > 0 ? (
                          workShopList.map((foodList, index) => {
                            return (
                              <div className="col-md-4">
                              <div className=" jd-cards" key={index}>
                                <div className="add-btn">
                                  <button
                                    className="delete"
                                    onClick={() => {
                                      handleDeleteShow();
                                      setDeleteId(
                                        foodList.workshop_ten_step_id
                                      );
                                    }}
                                  >
                                    <img src={ic_delete} alt="" />
                                  </button>
                                  <button
                                    onClick={() => {
                                      handleAddStepsShow();
                                      tenStepsListApiEdit(
                                        foodList.workshop_ten_step_id
                                      );
                                      setIdEdit("edit");
                                    }}
                                  >
                                    <img src={ic_edit} alt="" />
                                  </button>
                                </div>
                                {foodList.list.map((subitems, index) => {
                                  return (
                                    <div key={index}>
                                      <h6>{subitems.step_name}</h6>
                                      <p>{subitems.description}</p>
                                      <hr />
                                    </div>
                                  );
                                })}
                              </div>
                              </div>
                            );
                          })
                        ) : (
                          <h5 className="text-center text-danger">
                            No Data Found
                          </h5>
                        )}
                      </>
                    )}
                  </div>

                  <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                    <div className="row">
                      <div className="">
                        <Tab.Content>
                          {/* ==== Steps 2 ==== */}
                          <Tab.Pane eventKey="1"></Tab.Pane>
                        </Tab.Content>
                      </div>
                    </div>
                    <Modal
                      show={AddSteps}
                      onHide={handleAddStepsClose}
                      size="xl"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          {idEdit === "edit"
                            ? "Update Free Food "
                            : "Add Free Food "}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form
                          noValidate
                          validated={validated}
                          onSubmit={(e) => handleSubmit(e, "workshop")}
                        >
                          <div className="feature-wrapper">
                            <div className="row">
                              <div className="col-md-3 mb-4">
                                <Form.Label>Title English</Form.Label>
                                <Form.Control
                                  type="text"
                                  required
                                  maxLength="95"
                                  name="english_name"
                                  value={addWorkShopSteps?.english_name}
                                  onChange={handleWorkShopSteps}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Title English
                                </Form.Control.Feedback>
                              </div>
                              <div className="col-md-3 mb-4">
                                <Form.Label>Title Urdu</Form.Label>
                                <Form.Control
                                  type="text"
                                  required
                                  maxLength="95"
                                  name="urdu_name"
                                  value={addWorkShopSteps?.urdu_name}
                                  onChange={handleWorkShopSteps}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Title Urdu
                                </Form.Control.Feedback>
                              </div>
                              <div className="col-md-3 mb-4">
                                <Form.Label>Title Hindi</Form.Label>
                                <Form.Control
                                  type="text"
                                  required
                                  maxLength="95"
                                  name="hindi_name"
                                  value={addWorkShopSteps?.hindi_name}
                                  onChange={handleWorkShopSteps}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Title Hindi
                                </Form.Control.Feedback>
                              </div>
                              <div className="col-md-3 mb-4">
                                <Form.Label>Title Translitration</Form.Label>
                                <Form.Control
                                  type="text"
                                  required
                                  maxLength="95"
                                  name="english_lit_name"
                                  value={addWorkShopSteps?.english_lit_name}
                                  onChange={handleWorkShopSteps}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Title Translitration
                                </Form.Control.Feedback>
                              </div>
                              <div className="col-md-6 mb-4">
                                <Form.Label>Description English</Form.Label>
                                <Form.Control
                                  required
                                  as="textarea"
                                  rows={3}
                                  name="english_description"
                                  value={addWorkShopSteps?.english_description}
                                  onChange={handleWorkShopSteps}
                                  maxLength="1800"
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Description English.
                                </Form.Control.Feedback>
                              </div>
                              <div className="col-md-6 mb-4">
                                <Form.Label>Description Urdu</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  required
                                  rows={3}
                                  name="urdu_description"
                                  value={addWorkShopSteps?.urdu_description}
                                  onChange={handleWorkShopSteps}
                                  maxLength="1800"
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Description Urdu.
                                </Form.Control.Feedback>
                              </div>
                              <div className="col-md-6 mb-4">
                                <Form.Label>Description Hindi</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  maxLength="1800"
                                  name="hindi_description"
                                  required
                                  value={addWorkShopSteps?.hindi_description}
                                  onChange={handleWorkShopSteps}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Description Hindi.
                                </Form.Control.Feedback>
                              </div>
                              <div className="col-md-6 mb-4">
                                <Form.Label>
                                  Description Translitration
                                </Form.Label>
                                <Form.Control
                                  as="textarea"
                                  required
                                  maxLength="1800"
                                  rows={3}
                                  name="english_lit_description"
                                  value={
                                    addWorkShopSteps?.english_lit_description
                                  }
                                  onChange={handleWorkShopSteps}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Description Translitration.
                                </Form.Control.Feedback>
                              </div>
                              <hr />
                            </div>
                            <div className="buttons-main">
                              {/* <button className="cancel-btn">Cancel</button> */}

                              <button
                                className="submit-btn"
                                onClick={(e) => {
                                  // addWorkShopPostApi(e);
                                  // handleAddStepsClose();
                                }}
                              >
                                {idEdit === "edit" ? "Update" : "Submit"}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </Modal.Body>
                    </Modal>
                  </Tab.Container>
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </main>
  );
};

export default FreeFood;
