import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import thumb1 from "../../assets/images/video_thumbnails/1.png";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import ic_maktab_icon from "../../assets/images/ic_maktab_icon.png";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import SurahFatiha from "../../assets/SurahFatiha.mp3";
import ic_delete from "../../assets/images/ic_delete.svg";
import ic_edit from "../../assets/images/ic_edit.svg";
import ic_eyes from "../../assets/images/ic_eyes.svg";
import upload from "../../assets/images/upload.svg";

import Modal from "react-bootstrap/Modal";
import {
  DeleteCallWithErrorResponse,
  PostCallWithErrorResponse,
  putCallWithAuthTokenWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import FormLoader from "../FormLoader";
import { Alert } from "bootstrap";
const steps = [
  { eventKey: 1, step_id: 17, name: " Medical Guidance" , assessmentId : 9 },
  { eventKey: 2, step_id: 16, name: "Doctors", assessmentId : 15 },
  { eventKey: 3, step_id: 18, name: " Hospital", assessmentId : 16 },
  { eventKey: 4, step_id: 20, name: " Medical Store", assessmentId : 17 },
  { eventKey: 5, step_id: 21, name: " Diagnostic Center", assessmentId : 18 },
  { eventKey: 6, step_id: 22, name: " Dialysis Center", assessmentId : 19 },
  { eventKey: 7, step_id: 23, name: " Ambulance", assessmentId : 20 },
  { eventKey: 8, step_id: 24, name: " Trust", assessmentId : 21 },
  { eventKey: 9, step_id: 25, name: " Blood Bank", assessmentId : 22 },
  { eventKey: 10, step_id: 26, name: "  Oxygen Center", assessmentId : 23 },
];
const Medical = () => {
  const { sidebar, adminData } = useContext(AppContext);
  console.log(adminData.user_id, "bashgfsa");
  const [featurelist, setFeaturelist] = useState([]);
  const [tenStepsList, setTenStepsList] = useState([]);
  const [addBtn, setAddBtn] = useState(17);
  console.log("addBtn", addBtn);
  const [event, setEvent] = useState();
  console.log("EVENTKEY", event);
  const [addBtnName, setAddBtnName] = useState("Medical Guidance");
  const [idEdit, setIdEdit] = useState("");
  const [deleteStepurl, setFeleteStepurl] = useState();
  const handleAddStepsClose = () => {
    setAddSteps(false);
    setIdEdit("");
    setAddWorkShopSteps({
      urdu_title: "",
      english_title: "",
      english_lit_title: "",
      hindi_title: "",
      english_lit_desc: "",
      urdu_desc: "",
      english_desc: "",
      hindi_desc: "",
      english_desc1: "",
      hindi_title1: "",
      hindi_desc1: "",
      english_lit_desc1: "",
      urdu_desc1: "",
      urdu_title1: "",
      is_active: true,
    });
  };
  const [addWorkShopSteps, setAddWorkShopSteps] = useState({
    urdu_title: "",
    english_title: "",
    english_lit_title: "",
    hindi_title: "",
    english_lit_desc: "",
    urdu_desc: "",
    english_desc: "",
    hindi_desc: "",

    is_active: true,
  });

  const handleWorkShopSteps = (event) => {
    const { name, value } = event.target;
    const addWorkShopStepsCopy = { ...addWorkShopSteps };
    addWorkShopStepsCopy[name] = value;
    setAddWorkShopSteps(addWorkShopStepsCopy);
  };
  const [conditionApilist, setConditionApilist] = useState(
    ApiConfig.FEATURE_LIST + `?subcategory_id=${2}`
  );

  const [addFeature, setAddFeature] = useState(false);
  const handleAddFeatureClose = () => setAddFeature(false);
  const handleAddFeatureShow = () => setAddFeature(true);

  const [addWorksrhop, setAddWorksrhop] = useState(false);
  const handleAddWorksrhopClose = () => setAddWorksrhop(false);
  const handleAddWorksrhopShow = () => setAddWorksrhop(true);

  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);

  const [MainVideo, setMainVideo] = useState(false);
  const handleMainVideoClose = () => setMainVideo(false);
  const handleMainVideoShow = () => setMainVideo(true);

  const [AddSteps, setAddSteps] = useState(false);
  const handleAddStepsShow = () => setAddSteps(true);
  const [loader, setLoader] = useState(false);
  const [workShopList, setWorkShopList] = useState([]);
  const [vedioApiList, setvedioApiList] = useState([]);
  console.log(vedioApiList, "vedioApiList");
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event, section) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (section == "medical") {
        addWorkShopPostApi(event);
      }
      if (section === "videos") {
        updateMediaApi(event);
      }
    }

    setValidated(true);
  };
  const [videoEditData, setVideoEditData] = useState({
    subcategory_media_VideoName_hindi: "",
    subcategory_media_VideoPath_hindi: "",
    subcategory_media_VideoDuaration_hindi: "",
    subcategory_media_VideoName_lit: " ",
    subcategory_media_VideoPath_lit: "",
    subcategory_media_VideoDuaration_lit: " ",
    subcategory_media_VideoName_urdu: "",
    subcategory_media_VideoPath_urdu: "",
    subcategory_media_VideoDuaration_urdu: "",
    subcategory_media_VideoName_english: " ",
    subcategory_media_VideoPath_english: "",
    subcategory_media_VideoDuaration_english: "",
    subcategory_id: 2,
  });
  const handleChangeVideoEdit = (event) => {
    const { name, value } = event.target;
    const videoEditDatacopy = { ...videoEditData };
    videoEditDatacopy[name] = value;
    setVideoEditData(videoEditDatacopy);
  };

  useEffect(() => {
    videoApiList();
    tenStepsListApi(17);
    localStorage.setItem("school_workshop_ten_step_id", 17);
    localStorage.setItem("medicalNames",  "Medical Guidance");
    // localStorage.setItem("")
  }, []);

  const tenStepsListApi = (addBtn) => {
    setLoader(true);
    simpleGetCallWithErrorResponse(
      ApiConfig.SCHOOL_STEP_LIST +
        `?subcategory_id=${5}&&school_workshop_ten_step_id=${addBtn}`
    )
      .then((res) => {
        console.log(res);
        if (res.response.status === 200) {
          // if (res.json && res.json?.result === true)
          setLoader(false);

          setTenStepsList(res.json.scwdata);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const tenStepsListApiEdit = (get) => {
    setLoader(true);
    simpleGetCallWithErrorResponse(
      ApiConfig.SCHOOL_STEP_EDIT_LIST + `?chapter_id=${get}`
    )
      .then((res) => {
        console.log(res);
        if (res.response.status === 200) {
          if (res.json && res.json?.result === true) {
            setAddWorkShopSteps(res.json.schoolwsEdit);
          }
          setLoader(false);
          // setTenStepsList(res.json.data);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const featureDeleteApi = (url) => {
    handleDeleteClose();

    // setIsLoading(true);
    DeleteCallWithErrorResponse(url)
      .then((res) => {
        setLoader(false);
        if (res.json && res.json?.result === true) {
          tenStepsListApi(addBtn);
          ToastMsg("success", "Successfully Deleted");
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const videoApiList = () => {
    setLoader(true);
    // ?subcategory_id=3&school_workshop_ten_step_id=10
    simpleGetCallWithErrorResponse(
      ApiConfig.GET_MEDIEA + `?subcategory_id=${5}`
    )
      .then((res) => {
        console.log(res);
        if (res.json && res.json?.result === true) {
          setLoader(false);

          setvedioApiList(res.json.media);
          setVideoEditData(res.json.editmedia);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const updateMediaApi = (e) => {
    e.preventDefault();
    handleMainVideoClose();
    putCallWithAuthTokenWithErrorResponse(
      ApiConfig.UPDATE_MEDIEA + `?subcategory_id=${5}`,
      JSON.stringify({
        ...videoEditData,
        modified_by_id: adminData.user_id,
      })
    )
      .then((res) => {
        videoApiList();
        if (res.json && res.json?.result === true) {
          ToastMsg("success", "Video Successfully Updated");
          // tenStepsListApi(addBtn);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const addWorkShopPostApi = (e) => {
    e.preventDefault();
    handleAddStepsClose();

    setLoader(true);
    idEdit === "edit"
      ? putCallWithAuthTokenWithErrorResponse(
          ApiConfig.SCHOOL_STEP_UPDATE,
          JSON.stringify({
            ...addWorkShopSteps,
            modified_by_id: adminData.user_id,
            // created_by_id: adminData.user_id,
            // school_workshop_ten_step_id:addBtn
            is_active: true,
          })
        )
          .then((res) => {
            if (res.json && res.json?.result === true) {
              ToastMsg("success", "Successfully Updated");
              tenStepsListApi(addBtn);
            } else {
              ToastMsg("error", res.json.message);
            }
          })
          .catch((err) => {
            setLoader(false);

            ToastMsg("error", "something went wrong");
          })
      : PostCallWithErrorResponse(
          ApiConfig.SCHOOL_STEP_ADD,
          JSON.stringify({
            ...addWorkShopSteps,
            school_workshop_ten_step_id: addBtn,
            created_by_id: adminData.user_id,
          })
        )
          .then((res) => {
            if (res.json && res.json?.result === true) {
              ToastMsg("success", "Successfully Added");
              tenStepsListApi(addBtn);
            } else {
              ToastMsg("error", res.json.message);
            }
          })
          .catch((err) => {
            setLoader(false);

            ToastMsg("error", "something went wrong");
          });
  };
  const navigate = useNavigate();
  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">Medical</label>
        </div>
      </div>

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              onClick={() => {
                featureDeleteApi(deleteStepurl);
              }}
            >
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>

      {/* ======================= END DELETE ========================== */}

      <div className="main-wrapper" id="maktab-main">
        <div className="main-all-video position-relative">
          {vedioApiList?.length > 0 && (
            <div className="right">
              <div className="add-btn">
                <button onClick={handleMainVideoShow}>
                  <img src={ic_edit} alt="" />
                </button>
              </div>
            </div>
          )}

          {vedioApiList?.length > 0 ? (
            vedioApiList?.map((videolist, index) => {
              return (
                <div className="outer-main-video row" key={index}>
                  {videolist.sub_category_media.map((subitem, index) => {
                    return (
                      <div className="col-md-3 outer-main-video" key={index}>
                        <h6>
                          {(subitem.language_id === 4 && <span>Hindi</span>) ||
                            (subitem.language_id === 1 && (
                              <span>English</span>
                            )) ||
                            (subitem.language_id === 2 && <span>Urdu</span>) ||
                            (subitem.language_id === 3 && (
                              <span>Transliteration</span>
                            ))}{" "}
                          Video
                        </h6>
                        <div className="video">
                        <a href={subitem.subcategory_media_VideoPath}
                              target="_blank"
                            >
                              <img src={thumb1} alt="" />
                              <div className="play-btn">
                              <button class="button">
                                <span class="arrow"></span>
                                Play
                              </button>
                            </div>
                            </a>
                          {/* <video controls poster={thumb1}>
                            <source
                              src={subitem.subcategory_media_VideoPath}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video> */}
                        </div>
                        <div className="content">
                          <div className="left">
                            <div className="step-name">
                              <label htmlFor="" className="key">
                                Name:
                              </label>
                              <label htmlFor="" className="value ms-2">
                                {subitem.subcategory_media_VideoName}
                              </label>
                            </div>
                            <div className="duration">
                              <label htmlFor="" className="key">
                                Duration:
                              </label>
                              <label htmlFor="" className="value ms-2">
                                {subitem.subcategory_media_VideoDuaration}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <h5 className="text-center text-danger">No Data Found</h5>
          )}

          <Modal
            show={MainVideo}
            onHide={handleMainVideoClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                noValidate
                validated={validated}
                // onSubmit={(e) => handleSubmit(e,"videos")}
                onSubmit={(e) => handleSubmit(e, "videos")}
              >
                <div className="feature-wrapper">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <Form.Label>English URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_english"
                        value={
                          videoEditData.subcategory_media_VideoPath_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter English URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Hindi URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_hindi"
                        value={videoEditData.subcategory_media_VideoPath_hindi}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Hindi URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Urdu URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_urdu"
                        value={videoEditData.subcategory_media_VideoPath_urdu}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Urdu URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Transliteration URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_lit"
                        value={videoEditData.subcategory_media_VideoPath_lit}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Transliteration URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name English</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_english"
                        value={
                          videoEditData.subcategory_media_VideoName_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name English
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Hindi</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_hindi"
                        value={videoEditData.subcategory_media_VideoName_hindi}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name Hindi
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Urdu</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_urdu"
                        value={videoEditData.subcategory_media_VideoName_urdu}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Transliteration</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_lit"
                        value={videoEditData.subcategory_media_VideoName_lit}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name Urdu
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration English</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_english"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration English
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Hindi</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_hindi"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_hindi
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Hindi
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Urdu</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_urdu"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_urdu
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Urdu
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Transliteration</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_lit"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_lit
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Transliteration
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>English Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for English Video"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Hindi Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for Hindi Video"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Urdu Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for Urdu Video"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Transliteration Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for Transliteration Video"
                        />
                      </Form.Group>
                    </div>
                    <div className="buttons-modal  text-center mt-2  ">
                      <button className="me-2 ">MEDIA UPDATE</button>
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            {/* <Modal.Footer className="justify-content-center">
           
            </Modal.Footer> */}
          </Modal>
        </div>

        <hr />

        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
          <Nav variant="pills" className="flex-row">
            <Nav.Item>
              <Nav.Link eventKey="1">Workshop</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="1">
              <div className="jobDescriptio-main">
                <div className="wrapper">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey={addBtn}
                    // defaultActiveKey=""
                  >
                    <div className="row">
                      <div className="">
                        <Nav variant="pills" className="flex-row">
                          {steps.map((stepNew, index) => {
                            return (
                              <Nav.Item 
                              key={"step" + index}
                              >
                                <Nav.Link
                                  eventKey={stepNew.step_id}
                                  onClick={() => {
                                    localStorage.setItem("school_workshop_ten_step_id", stepNew.step_id);
                                    localStorage.setItem("medicalNames", stepNew.name);
                                    localStorage.setItem("assessmentId", stepNew.assessmentId);
                                    setAddBtn(stepNew.step_id);
                                    setAddBtnName(stepNew.name);
                                    setEvent(stepNew.eventKey);
                                    tenStepsListApi(stepNew.step_id);
                                  }}
                                >
                                  {stepNew.name}
                                </Nav.Link>
                              </Nav.Item>
                            );
                          })}
                        </Nav>
                      </div>
                      <div className="">
                        <Tab.Content>
                          {/* ==== Steps 1 ==== */}

                          <Tab.Pane eventKey={addBtn}>
                            <div className="add-btn">
                              <button onClick={handleAddStepsShow}>
                                Add +
                              </button>
                              <button
                                onClick={() => {
                                  localStorage.setItem("subCat", 5);
                                  // handleAddTrusteeShow(idJOBSCategory);
                                  navigate("/Assessment");
                                }}
                                className="ms-2"
                              >
                                Assessment
                              </button>
                            </div>

                            {loader ? (
                              <FormLoader />
                            ) : (
                              <div className="row jd-main g-3">
                                {tenStepsList && tenStepsList.length > 0 ? (
                                  tenStepsList.map((card, index) => {
                                    return (
                                      <>
                                        <div className="col-md-4">
                                          <div className=" jd-cards">
                                            <div className="add-btn">
                                              <button
                                                className="delete"
                                                onClick={() => {
                                                  handleDeleteShow();
                                                  setFeleteStepurl(
                                                    ApiConfig.SCHOOL_STEP_DELETE +
                                                      `?chapter_id=${card.chapter_id}`
                                                  );
                                                }}
                                              >
                                                <img src={ic_delete} alt="" />
                                              </button>
                                              <button
                                                onClick={() => {
                                                  handleAddStepsShow();
                                                  tenStepsListApiEdit(
                                                    card.chapter_id
                                                  );
                                                  setIdEdit("edit");
                                                }}
                                              >
                                                <img src={ic_edit} alt="" />
                                              </button>
                                            </div>
                                            {card.list.map((subitme, index) => {
                                              return (
                                                <>
                                                  <h6>{subitme.title}</h6>
                                                  <p>{subitme.description}</p>
                                                  <hr />
                                                </>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })
                                ) : (
                                  <h5 className="text-center text-danger">
                                    No Data Found
                                  </h5>
                                )}
                              </div>
                            )}
                          </Tab.Pane>

                          <Modal
                            show={AddSteps}
                            onHide={handleAddStepsClose}
                            size="xl"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>
                                {" "}
                                {idEdit === "edit"
                                  ? `Update ${addBtnName}`
                                  : `Add  ${addBtnName}`}{" "}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Form
                                noValidate
                                validated={validated}
                                // onSubmit={(e)=>handleSubmit(e,"medical")}
                                onSubmit={(e) => handleSubmit(e, "medical")}
                              >
                                <div className="feature-wrapper">
                                  <div className="row">
                                    <div className="col-md-3 mb-4">
                                      <Form.Label>Title English</Form.Label>
                                      <Form.Control
                                        type="text"
                                        required
                                        maxLength="1000"
                                        name="english_title"
                                        value={addWorkShopSteps.english_title}
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Title English
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-3 mb-4">
                                      <Form.Label>Title Urdu</Form.Label>
                                      <Form.Control
                                        type="text"
                                        required
                                        maxLength="1000"
                                        name="urdu_title"
                                        value={addWorkShopSteps.urdu_title}
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Title Urdu
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-3 mb-4">
                                      <Form.Label>Title Hindi</Form.Label>
                                      <Form.Control
                                        type="text"
                                        required
                                        maxLength="1000"
                                        name="hindi_title"
                                        value={addWorkShopSteps.hindi_title}
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Title Hindi
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-3 mb-4">
                                      <Form.Label>
                                        Title Transliteration
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        required
                                        name="english_lit_title"
                                        maxLength="1000"
                                        value={
                                          addWorkShopSteps.english_lit_title
                                        }
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Title Translitration
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                      <Form.Label>
                                        Description English
                                      </Form.Label>
                                      <Form.Control
                                        required
                                        as="textarea"
                                        maxLength="10000"
                                        rows={3}
                                        name="english_desc"
                                        value={addWorkShopSteps.english_desc}
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Description English.
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                      <Form.Label>Description Urdu</Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        required
                                        maxLength="10000"
                                        rows={3}
                                        name="urdu_desc"
                                        value={addWorkShopSteps.urdu_desc}
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Description Urdu.
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                      <Form.Label>Description Hindi</Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        rows={3}
                                        name="hindi_desc"
                                        maxLength="10000"
                                        required
                                        value={addWorkShopSteps.hindi_desc}
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Description Hindi.
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                      <Form.Label>
                                        Description Transliteration
                                      </Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        required
                                        rows={3}
                                        maxLength="10000"
                                        name="english_lit_desc"
                                        value={
                                          addWorkShopSteps.english_lit_desc
                                        }
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Description Transliteration.
                                      </Form.Control.Feedback>
                                    </div>
                                    <hr />
                                  </div>
                                  <div className="buttons-main">
                                    {/* <button className="cancel-btn">Cancel</button> */}

                                    <button className="submit-btn">
                                      {idEdit === "edit" ? "Update" : "Submit"}
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            </Modal.Body>
                          </Modal>
                        </Tab.Content>
                      </div>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </main>
  );
};

export default Medical;
