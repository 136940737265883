import React ,{useContext} from 'react'
import { AppContext } from "../../context/AppContext";
const FAQ = () => {
  const { sidebar, adminData } = useContext(AppContext);

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common ">
          <label htmlFor="" >FAQ Work In Progress</label>
        </div>
      </div>
      </main>
  )
}

export default FAQ;