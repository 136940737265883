import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import ic_sidemenu_toggle from "../assets/images/ic_sidemenu_toggle.svg";
import { useMediaQuery } from "react-responsive";
import Deeniyat_logo from "../assets/images/logo_big.png";
import deeniyatAppicon from "../assets/images/logo_small.png";

import ic_dashboard from "../assets/images/ic_dashboard.svg";

import support from "../assets/images/support.svg";
import support_active from "../assets/images/support_active.svg";

import maktab_inside from "../assets/images/maktab_inside.png";

import khidmat from "../assets/images/khidmat.svg";
import khidmat_active from "../assets/images/kidmat_active.svg";
import Noraani_Qaaidah from "../assets/images/education.svg";
import Noraani_Qaaidah_active from "../assets/images/education_active.svg";
import ic_setting from "../assets/images/ic_setting.svg";
import ic_setting_active from "../assets/images/ic_setting_active.svg";

import analytics from "../assets/images/analytics.svg";
import push_notification from "../assets/images/push_notification.svg";
import Hifz_e_Surah from "../assets/images/Hifz_e_Surah.svg";
import Hifz_e_Surah_active from "../assets/images/Hifz_e_Surah_active.svg";
import ic_maktab_icon from "../assets/images/ic_maktab_icon.png";
import khidmat_app from "../assets/images/khidmat_app.png";
import ic_school from "../assets/images/ic_school.png";
import ic_food from "../assets/images/ic_food.png";
import ic_medical from "../assets/images/ic_medical.png";
import assesment from "../assets/images/assesment.svg";
import assesment_active from "../assets/images/assesment_active.svg";

import { AppContext } from "../context/AppContext";
import Gallery from "./../pages/Supports/Gallery";

const Sidebar = () => {
  const navigate = useNavigate();
  const currentRoute = useLocation().pathname;

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  useEffect(() => {
    isMobile ? setSidebar(false) : setSidebar(true);
    return () => { };
  }, [isMobile]);

  const { sidebar, setSidebar, Dark } = useContext(AppContext);
  const handleSidebar = () => {
    setSidebar(!sidebar);
  };

  const [setting, setsetting] = useState(false);
  const [Education, setEducation] = useState(false);
  const [Khidmat, setKhidmat] = useState(false);
  const [Support, setSupport] = useState(false);

  return (
    <main className="cx-sidebar">
      <div
        className={
          sidebar ? "cx-sidebar-wrapper " : "cx-sidebar-wrapper sidebarActive"
        }
      >
        <aside>
          <div className="logo-main" style={{ cursor: "pointer" }}>
            {sidebar ? (
              <div className="big" onClick={() => navigate("/Dashboard")}>
                <img src={Deeniyat_logo} alt="" />
              </div>
            ) : (
              <div className="small" onClick={() => navigate("/Dashboard")}>
                <img src={deeniyatAppicon} alt="" />
              </div>
            )}
          </div>

          <div className="left-arrow"></div>

          <div className="menus-main">
            <div className="menus-items" id="arrow-double">
              <div
                className={sidebar ? "text-end " : "doubleArrowActive"}
                onClick={handleSidebar}
                id="arrow-inner"
              >
                <img src={ic_sidemenu_toggle} alt="" />
              </div>
            </div>

            <div
              className={
                currentRoute === "/Dashboard"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link to="/Dashboard" className="icon-menu-name">
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/dashboard" className="me-2">
                      {currentRoute === "/Dashboard" ? (
                        <img src={ic_dashboard} alt="" />
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#707070"
                            d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z"
                          />
                        </svg>
                      )}
                    </Link>
                    Dashboard
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Dashboard" ? (
                      <img src={ic_dashboard} alt="" />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#16173b"
                          d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </Link>
            </div>

            <div
              className={
                currentRoute === "/School" || currentRoute === "/Maktab" || currentRoute === '/Assessment'
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link
                to="#"
                className="icon-menu-name"
                onClick={() => {
                  setEducation(!Education);
                  setKhidmat(false);
                  setSupport(false);
                  setsetting(false);
                }}
              >
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="#" className="me-2">
                      {currentRoute === "/School" ||
                        currentRoute === "/Maktab" || currentRoute === '/Assessment' ? (
                        <img src={Noraani_Qaaidah_active} alt="" />
                      ) : (
                        <img src={Noraani_Qaaidah} alt="" />
                      )}
                    </Link>
                    Education
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/School" ||
                      currentRoute === "/Maktab" || currentRoute === '/Assessment' ? (
                      <img src={Noraani_Qaaidah_active} alt="" />
                    ) : (
                      <img src={Noraani_Qaaidah} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            {(sidebar && Education) == true ? (
              <div className="sub-menu-main">
                <Link to="/Maktab" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/Maktab" || currentRoute === '/Assessment'
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                    onClick={() => localStorage.setItem("CategoryName", "MAKTAB")}
                  >
                    <img
                      src={maktab_inside}
                      alt=""
                      className="logo-sub-menues"
                    />
                    <span>Maktab</span>
                  </div>
                </Link>

                <Link to="/School" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/School"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    <img src={ic_school} alt="" className="logo-sub-menues" />
                    <span>School</span>
                  </div>
                </Link>
              </div>
            ) : (
              ""
            )}

            <div
              className={
                currentRoute === "/FreeFood" ||
                  currentRoute === "/Medical" ||
                  currentRoute === "/Employment"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link
                to="#"
                className="icon-menu-name"
                onClick={() => {
                  setKhidmat(!Khidmat);
                  setEducation(false);
                  setSupport(false);
                  setsetting(false);
                }}
              >
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/HifzESurah" className="me-2">
                      {currentRoute === "/FreeFood" ||
                        currentRoute === "/Medical" ||
                        currentRoute === "/Employment" ? (
                        <img src={khidmat_active} alt="" />
                      ) : (
                        <img src={khidmat} alt="" />
                      )}
                    </Link>
                    Khidmat
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/FreeFood" ||
                      currentRoute === "/Medical" ||
                      currentRoute === "/Employment" ? (
                      <img src={khidmat_active} alt="" />
                    ) : (
                      <img src={khidmat} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            {(sidebar && Khidmat) == true ? (
              <div className="sub-menu-main">
                <Link to="/FreeFood" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/FreeFood"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                    onClick={() => localStorage.setItem("CategoryName", "FREE FOOD")}
                  >
                    <img
                      src={maktab_inside}
                      alt=""
                      className="logo-sub-menues"
                    />
                    <span>Free Food</span>
                  </div>
                </Link>

                <Link to="/Medical" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/Medical"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                    onClick={() => {
                      localStorage.setItem("CategoryName", "MEDICAL");
                      localStorage.setItem("assessmentId", 9);

                  }}
                  >
                    <img src={ic_school} alt="" className="logo-sub-menues" />
                    <span>Medical</span>
                  </div>
                </Link>
                <Link to="/Employment" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/Employment"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                    onClick={() => localStorage.setItem("CategoryName", "EMPLOYMENT")}
                  >
                    <img src={ic_school} alt="" className="logo-sub-menues" />
                    <span>Employment</span>
                  </div>
                </Link>
              </div>
            ) : (
              ""
            )}



            <div
              className={
                currentRoute === "/Publication" ||
                  currentRoute === "/Products" ||
                  currentRoute === "/MobileApps" ||
                  currentRoute === "/Gallery" ||
                  currentRoute === "/FAQ" ||
                  currentRoute === "/Syllabus" ||
                  currentRoute === "/Quraan" ||
                  currentRoute === "/SyllabusDetails" ||
                  currentRoute === "/OtherBooks" ||
                  currentRoute === "/DailyPost"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link
                to="#"
                className="icon-menu-name"
                onClick={() => {
                  setSupport(!Support);
                  setEducation(false);
                  setKhidmat(false);
                  setsetting(false);
                }}
              >
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="/DuaSunnat" className="me-2">
                      {currentRoute === "/Publication" ||
                        currentRoute === "/Products" ||
                        currentRoute === "/MobileApps" ||
                        currentRoute === "/Gallery" ||
                        currentRoute === "/FAQ" ||
                        currentRoute === "/Syllabus" ||
                        currentRoute === "/Quraan" ||
                        currentRoute === "/SyllabusDetails" ||
                        currentRoute === "/OtherBooks" ||
                        currentRoute === "/DailyPost" ? (
                        <img src={support_active} alt="" />
                      ) : (
                        <img src={support} alt="" />
                      )}
                    </Link>
                    Support
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/Publication" ||
                      currentRoute === "/Products" ||
                      currentRoute === "/MobileApps" ||
                      currentRoute === "/Gallery" ||
                      currentRoute === "/FAQ" ||
                      currentRoute === "/Syllabus" ||
                      currentRoute === "/Quraan" ||
                      currentRoute === "/SyllabusDetails" ||
                      currentRoute === "/OtherBooks" ||
                      currentRoute === "/DailyPost" ? (
                      <img src={support_active} alt="" />
                    ) : (
                      <img src={support} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            {(sidebar && Support) == true ? (
              <div className="sub-menu-main">
                <Link to="/Publication" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/Publication" ||
                        currentRoute === "/Syllabus" ||
                        currentRoute === "/Quraan" ||
                        currentRoute === "/SyllabusDetails" ||
                        currentRoute === "/OtherBooks"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    <img
                      src={maktab_inside}
                      alt=""
                      className="logo-sub-menues"
                    />
                    <span>Publication</span>
                  </div>
                </Link>

                <Link to="/Products" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/Products"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    <img src={ic_school} alt="" className="logo-sub-menues" />
                    <span>Products</span>
                  </div>
                </Link>

                <Link to="/MobileApps" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/MobileApps"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    <img src={ic_school} alt="" className="logo-sub-menues" />
                    <span>Mobile Apps</span>
                  </div>
                </Link>

                <Link to="/DailyPost" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/DailyPost"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    <img src={ic_school} alt="" className="logo-sub-menues" />
                    <span>Daily Post</span>
                  </div>
                </Link>

                <Link to="/FAQ" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/FAQ"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    <img src={ic_school} alt="" className="logo-sub-menues" />
                    <span>F.A.Q</span>
                  </div>
                </Link>
                <Link to="/Gallery" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/Gallery"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    <img src={ic_school} alt="" className="logo-sub-menues" />
                    <span>Gallery</span>
                  </div>
                </Link>
              </div>
            ) : (
              ""
            )}

            <div
              className={
                currentRoute === "/GoogleAnalytics" ||
                  currentRoute === "/PushNotification"
                  ? "menus-items  active-menu-sidebar"
                  : "menus-items"
              }
            >
              <Link
                to="#"
                className="icon-menu-name"
                onClick={() => {
                  setsetting(!setting);
                  setEducation(false);
                  setKhidmat(false);
                  setSupport(false);
                }}
              >
                {sidebar ? (
                  <div className={"menu-name"}>
                    <Link to="#" className="me-2">
                      {currentRoute === "/GoogleAnalytics" ||
                        currentRoute === "/PushNotification" ? (
                        <img src={ic_setting_active} alt="" />
                      ) : (
                        <img src={ic_setting} alt="" />
                      )}
                    </Link>
                    Setting
                  </div>
                ) : (
                  <div className="icon-left">
                    {currentRoute === "/GoogleAnalytics" ||
                      currentRoute === "/PushNotification" ? (
                      <img src={ic_setting_active} alt="" />
                    ) : (
                      <img src={ic_setting} alt="" />
                    )}
                  </div>
                )}
              </Link>
            </div>

            {(sidebar && setting) == true ? (
              <div className="sub-menu-main">
                {/* <Link to="/GoogleAnalytics" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/GoogleAnalytics"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    <img src={analytics} alt="" className="logo-sub-menues" />
                    <span>Google Analytics</span>
                  </div>
                </Link> */}

                <Link to="/PushNotification" className="sub-menu-item">
                  <div
                    className={
                      currentRoute === "/PushNotification"
                        ? "sub-menu active-sub-menu"
                        : "sub-menu"
                    }
                  >
                    <img
                      src={push_notification}
                      alt=""
                      className="logo-sub-menues"
                    />
                    <span>Push Notification</span>
                  </div>
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </aside>
      </div>
    </main>
  );
};

export default Sidebar;
