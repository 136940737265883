import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import thumb1 from "../../assets/images/video_thumbnails/1.png";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link } from "react-router-dom";
import ic_maktab_icon from "../../assets/images/ic_maktab_icon.png";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import SurahFatiha from "../../assets/SurahFatiha.mp3";
import ic_delete from "../../assets/images/ic_delete.svg";
import ic_edit from "../../assets/images/ic_edit.svg";
import ic_eyes from "../../assets/images/ic_eyes.svg";
import upload from "../../assets/images/upload.svg";

import Modal from "react-bootstrap/Modal";
import {
  DeleteCallWithErrorResponse,
  multipartPostCallWithErrorResponse,
  PostCallWithErrorResponse,
  putCallWithAuthTokenWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import { ToastMsg } from "../TostMsg";
import ApiConfig, { BASE_URL, BASE_URL_FOR_IMAGES } from "../../api/ApiConfig";
import FormLoader from "../FormLoader";
const steps = [
  { step_id: 1, name: "1" },
  { step_id: 2, name: "2" },
  { step_id: 3, name: "3" },
  { step_id: 7, name: "4" },
  { step_id: 8, name: "5" },
  { step_id: 9, name: "6" },
  { step_id: 10, name: "7" },
  { step_id: 11, name: "8" },
  { step_id: 12, name: "9" },
  { step_id: 13, name: "10" },
];

const School = () => {
  const { sidebar, adminData } = useContext(AppContext);
  const [loader, setLoader] = useState(false);

  const [AddNewGroup, setAddNewGroup] = useState(0);

  const [addFeature, setAddFeature] = useState(false);
  const handleAddFeatureShow = () => setAddFeature(true);
  const handleAddFeatureClose = () => {
    setAddFeatureData({
      urdu_title: "",
      urdu_desc: "",
      hindi_title: "",
      hindi_desc: "",
      english_title: "",
      english_desc: "",
      translitration_title: "",
      translitration_desc: "",
      file: "",
    });
    setAddFeature(false);
    setIdEdit("");
  };

  const [addWorksrhop, setAddWorksrhop] = useState(false);
  const handleAddWorksrhopClose = () => setAddWorksrhop(false);
  const handleAddWorksrhopShow = () => setAddWorksrhop(true);

  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);

  const [MainVideo, setMainVideo] = useState(false);
  const handleMainVideoClose = () => setMainVideo(false);
  const handleMainVideoShow = () => setMainVideo(true);

  const [AddSteps, setAddSteps] = useState(false);
  const handleAddStepsClose = () => {
    setAddSteps(false);
    setIdEdit("");
    setAddWorkShopSteps({
      urdu_title: "",
      english_title: "",
      english_lit_title: "",
      hindi_title: "",
      english_lit_desc: "",
      urdu_desc: "",
      english_desc: "",
      hindi_desc: "",
      english_desc1: "",
      hindi_title1: "",
      hindi_desc1: "",
      english_lit_desc1: "",
      urdu_desc1: "",
      urdu_title1: "",
      is_active: true,
    });
  };
  const handleAddStepsShow = () => setAddSteps(true);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event, section) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (section == "videos") {
        updateMediaApi(event);
      }
      if (section == "feature") {
        addFeatureApi(event);
      }
      if (section == "workshop") {
        addWorkShopPostApi(event);
      }
    }

    setValidated(true);
  };
  const [featurelist, setFeaturelist] = useState([]);
  const [tenStepsList, setTenStepsList] = useState([]);
  const [addBtn, setAddBtn] = useState(1);
  const [addBtnName, setAddBtnName] = useState(1);
  const [imageWidth, setImageWidth] = useState("100%");

  const [idUpdate, setIdUpdate] = useState("");

  const [addWorkShopSteps, setAddWorkShopSteps] = useState({
    urdu_title: "",
    english_title: "",
    english_lit_title: "",
    hindi_title: "",
    english_lit_desc: "",
    urdu_desc: "",
    english_desc: "",
    hindi_desc: "",
    english_title1: "",
    english_desc1: "",
    hindi_title1: "",
    hindi_desc1: "",
    english_lit_title1: "",
    english_lit_desc1: "",
    urdu_desc1: "",
    urdu_title1: "",
    is_active: true,
  });

  const handleWorkShopSteps = (event) => {
    const { name, value } = event.target;
    const addWorkShopStepsCopy = { ...addWorkShopSteps };
    addWorkShopStepsCopy[name] = value;
    setAddWorkShopSteps(addWorkShopStepsCopy);
  };
  const [videoEditData, setVideoEditData] = useState({
    subcategory_media_VideoName_hindi: "अच्छा माहौल",
    subcategory_media_VideoPath_hindi:
      "http://player.vimeo.com/progressive_redirect/playback/736735285/rendition/1080p/file.mp4?loc=external&signature=46a461280e4072b0019fd6156b3aea72a0701168d5debc2639119fadfd6467a7",
    subcategory_media_VideoDuaration_hindi: "13",
    subcategory_media_VideoName_lit: "Bahereen Maahol",
    subcategory_media_VideoPath_lit:
      "http://player.vimeo.com/progressive_redirect/playback/736735285/rendition/1080p/file.mp4?loc=external&signature=46a461280e4072b0019fd6156b3aea72a0701168d5debc2639119fadfd6467a7",
    subcategory_media_VideoDuaration_lit: " 14",
    subcategory_media_VideoName_urdu: "اچھا ماحول",
    subcategory_media_VideoPath_urdu:
      "http://player.vimeo.com/progressive_redirect/playback/736735285/rendition/1080p/file.mp4?loc=external&signature=46a461280e4072b0019fd6156b3aea72a0701168d5debc2639119fadfd6467a7",
    subcategory_media_VideoDuaration_urdu: "11",
    subcategory_media_VideoName_english: "Good Environment",
    subcategory_media_VideoPath_english:
      "http://player.vimeo.com/progressive_redirect/playback/736735285/rendition/1080p/file.mp4?loc=external&signature=46a461280e4072b0019fd6156b3aea72a0701168d5debc2639119fadfd6467a7",
    subcategory_media_VideoDuaration_english: "12",
    subcategory_id: 3,
  });
  const handleChangeVideoEdit = (event) => {
    const { name, value } = event.target;
    const videoEditDatacopy = { ...videoEditData };
    videoEditDatacopy[name] = value;
    setVideoEditData(videoEditDatacopy);
  };
  const addWorkShopPostApi = (e) => {
    e.preventDefault();
    handleAddStepsClose();
    setLoader(true);
    idEdit === "edit"
      ? putCallWithAuthTokenWithErrorResponse(
        ApiConfig.SCHOOL_STEP_UPDATE,
        JSON.stringify({
          ...addWorkShopSteps,
          modified_by_id: adminData.user_id,
          // created_by_id: adminData.user_id,
          // school_workshop_ten_step_id:addBtn
          is_active: true,
        })
      )
        .then((res) => {
          if (res.json && res.json?.result === true) {
            ToastMsg("success", res.json.message);
            tenStepsListApi(addBtn);
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          setLoader(false);

          ToastMsg("error", "something went wrong");
        })
      : PostCallWithErrorResponse(
        ApiConfig.SCHOOL_STEP_ADD,
        JSON.stringify({
          ...addWorkShopSteps,
          created_by_id: adminData.user_id,
          school_workshop_ten_step_id: addBtn,
        })
      )
        .then((res) => {
          if (res.json && res.json?.result === true) {
            ToastMsg("success", res.json.message);
            tenStepsListApi(addBtn);
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          setLoader(false);

          ToastMsg("error", "something went wrong");
        });
  };

  // subcategory_id: 3
  // school_workshop_ten_step_id: 2

  //main vidieo api integration

  useEffect(() => {
    featurListApi();
    tenStepsListApi(1);
    videoApiList();
  }, []);

  const featurListApi = () => {
    setLoader(true);

    simpleGetCallWithErrorResponse(
      ApiConfig.FEATURE_LIST + `?subcategory_id=${3}`
    )
      .then((res) => {
        setLoader(false);
        if (res.json && res.json?.result === true) {
          setFeaturelist(res.json.featuresAndBenefits);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const tenStepsListApi = (addBtn) => {
    setLoader(true);
    simpleGetCallWithErrorResponse(
      ApiConfig.SCHOOL_STEP_LIST +
      `?subcategory_id=${3}&&school_workshop_ten_step_id=${addBtn}`
    )
      .then((res) => {
        console.log(res);
        if (res.response.status === 200) {
          // if (res.json && res.json?.result === true)
          setLoader(false);

          setTenStepsList(res.json.scwdata);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const tenStepsListApiEdit = (get) => {
    setLoader(true);
    simpleGetCallWithErrorResponse(
      ApiConfig.SCHOOL_STEP_EDIT_LIST + `?chapter_id=${get}`
    )
      .then((res) => {
        console.log(res);
        if (res.response.status === 200) {
          // if (res.json && res.json?.result === true)
          setLoader(false);
          setAddWorkShopSteps(res.json.schoolwsEdit);
          // setTenStepsList(res.json.data);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    const addFeatureDatacopy = { ...addFeatureData };
    addFeatureDatacopy[name] = value;
    setAddFeatureData(addFeatureDatacopy);
  };

  const [idEdit, setIdEdit] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [updateID, setUpdateID] = useState("");
  const [addFeatureData, setAddFeatureData] = useState({
    urdu_title: "",
    urdu_desc: "",
    hindi_title: "",
    hindi_desc: "",
    english_title: "",
    english_desc: "",
    translitration_title: "",
    translitration_desc: "",
    file: "",
  });

  const [vedioApiList, setvedioApiList] = useState([]);
  const [idSteps, setIdSteps] = useState(1);
  const [deleteStepurl, setFeleteStepurl] = useState();

  // =============== ADD API FETURE LIST =================

  const addFeatureApi = (e) => {
    e.preventDefault();
    handleAddFeatureClose();
    let payLoadFormData = new FormData();
    payLoadFormData.append("file", addFeatureData.file);
    payLoadFormData.append("urdu_title", addFeatureData.urdu_title);
    payLoadFormData.append("urdu_desc", addFeatureData.urdu_desc);
    payLoadFormData.append("hindi_title", addFeatureData.hindi_title);
    payLoadFormData.append("hindi_desc", addFeatureData.hindi_desc);
    payLoadFormData.append("english_title", addFeatureData.english_title);
    payLoadFormData.append("english_desc", addFeatureData.english_desc);
    payLoadFormData.append(
      "translitration_title",
      addFeatureData.translitration_title
    );
    payLoadFormData.append(
      "translitration_desc",
      addFeatureData.translitration_desc
    );

    setLoader(true);
    idEdit === "edit"
      ? putMultipartWithAuthCallWithErrorResponse(
        ApiConfig.FEATURE_UPDATE +
        `?subcategory_id=2&details_ids=${JSON.stringify(
          updateID
        )}&fb_id=${deleteId}`,
        payLoadFormData
      )
        .then((res) => {
          if (res.json && res.json?.result === true) {
            ToastMsg("success", res.json.message);
            featurListApi();
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          setLoader(false);

          ToastMsg("error", "something went wrong");
        })
      : multipartPostCallWithErrorResponse(
        ApiConfig.FEATURE_ADD + `?subcategory_id=${3}`,
        payLoadFormData
      )
        .then((res) => {
          if (res.json && res.json?.result === true) {
            featurListApi();
            ToastMsg("success", res.json.message);
          } else {
            ToastMsg("error", res.json.message);
          }
        })
        .catch((err) => {
          setLoader(false);
          ToastMsg("error", "something went wrong");
        });
  };

  const featureDeleteApi = (url) => {
    handleDeleteClose();

    // setIsLoading(true);
    DeleteCallWithErrorResponse(url)
      .then((res) => {
        setLoader(false);
        if (res.json && res.json?.result === true) {
          if (
            res.json.message === "Features And Benifits deleted Successfully"
          ) {
            featurListApi();
          }
          tenStepsListApi(addBtn);
          ToastMsg("success", res.json.message);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const filterData = (id) => {
    let data = featurelist.filter((ele) => ele.fb_id === id);
    let filteredData = data[0].list;
    let filterimage = data[0];

    let idDat = data[0].fb_id;
    let dataArrayID = filteredData.map((ele) => ele.fb_detail_id);
    // let updateObj = {
    //   file: filterimage.image_path,
    //   english_title: filteredData[0].title,
    //   english_desc: filteredData[0].description,
    //   urdu_title: filteredData[1].title,
    //   urdu_desc: filteredData[1].description,
    //   translitration_title: filteredData[2].title,
    //   translitration_desc: filteredData[2].description,
    //   hindi_title: filteredData[3].title,
    //   hindi_desc: filteredData[3].description,
    // };

    // setAddFeatureData(updateObj);
    setUpdateID(dataArrayID);
    setDeleteId(idDat);
  };

  const videoApiList = () => {
    setLoader(true);
    simpleGetCallWithErrorResponse(
      ApiConfig.GET_MEDIEA + `?subcategory_id=${3}`
    )
      .then((res) => {
        console.log(res);
        if (res.json && res.json?.result === true) {
          setLoader(false);

          setvedioApiList(res.json.media);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  const updateMediaApi = (e) => {
    e.preventDefault();
    handleMainVideoClose();
    putCallWithAuthTokenWithErrorResponse(
      ApiConfig.UPDATE_MEDIEA + `?subcategory_id=${1}`,
      JSON.stringify({
        ...videoEditData,
        modified_by_id: adminData.user_id,
      })
    )
      .then((res) => {
        if (res.json && res.json?.result === true) {
          ToastMsg("success", res.json.message);
          videoApiList();
          // tenStepsListApi(addBtn);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };

  const ListApiEdit = (get) => {
    setLoader(true);
    simpleGetCallWithErrorResponse(ApiConfig.FEATURE_EDIT + `?fb_id=${get}`)
      .then((res) => {
        console.log(res);
        if (res.response.status === 200) {
          // if (res.json && res.json?.result === true)
          setLoader(false);
          setAddFeatureData(res.json.featuresAndBenefitsData);
          // setTenStepsList(res.json.data);
        } else {
          ToastMsg("error", res.json.message);
        }
      })
      .catch((err) => {
        // setLoader(false);

        ToastMsg("error", "something went wrong");
      });
  };
  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <label htmlFor="">School</label>
        </div>
      </div>

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button
              className="me-2 bg-danger"
              onClick={() => featureDeleteApi(deleteStepurl)}
            >
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      {/* ======================= END DELETE ========================== */}

      <div className="main-wrapper" id="maktab-main">
        <div className="main-all-video ">
          {vedioApiList?.length > 0 && (
            <div className="right">
              <div className="add-btn">
                <button onClick={handleMainVideoShow}>
                  <img src={ic_edit} alt="" />
                </button>
              </div>
            </div>
          )}

          {vedioApiList?.length > 0 ? (
            vedioApiList?.map((videolist, index) => {
              return (
                <div className="outer-main-video row" key={index}>
                  {videolist.sub_category_media.map((subitem, index) => {
                    return (
                      <div className="col-md-3 outer-main-video" key={index}>
                        <h6>
                          {(subitem.language_id === 4 && <span>Hindi</span>) ||
                            (subitem.language_id === 1 && (
                              <span>English</span>
                            )) ||
                            (subitem.language_id === 2 && <span>Urdu</span>) ||
                            (subitem.language_id === 3 && (
                              <span>Transliteration</span>
                            ))}{" "}
                          Video
                        </h6>
                        <div className="video">
                          <a href={subitem.subcategory_media_VideoPath}
                            target="_blank"
                          >
                            <img src={thumb1} alt="" />
                            <div className="play-btn">
                              <button class="button">
                                <span class="arrow"></span>
                                Play
                              </button>
                            </div>
                          </a>
                          {/* <video controls poster={thumb1}>
                            <source
                              src={subitem.subcategory_media_VideoPath}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video> */}
                        </div>
                        <div className="content">
                          <div className="left">
                            <div className="step-name">
                              <label htmlFor="" className="key">
                                Name:
                              </label>
                              <label htmlFor="" className="value ms-2">
                                {subitem.subcategory_media_VideoName}
                              </label>
                            </div>
                            <div className="duration">
                              <label htmlFor="" className="key">
                                Duration:
                              </label>
                              <label htmlFor="" className="value ms-2">
                                {subitem.subcategory_media_VideoDuaration}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <h5 className="text-center text-danger">No Data Found</h5>
          )}

          <Modal
            show={MainVideo}
            onHide={handleMainVideoClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                noValidate
                validated={validated}
                // onSubmit={(e) => handleSubmit(e,"videos")}
                onSubmit={(e) => handleSubmit(e, "videos")}
              >
                <div className="feature-wrapper">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <Form.Label>English URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_english"
                        value={
                          videoEditData.subcategory_media_VideoPath_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter English URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Hindi URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_hindi"
                        value={videoEditData.subcategory_media_VideoPath_hindi}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Hindi URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Urdu URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_urdu"
                        value={videoEditData.subcategory_media_VideoPath_urdu}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Urdu URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Transliteration URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoPath_lit"
                        value={videoEditData.subcategory_media_VideoPath_lit}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Transliteration URL
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name English</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_english"
                        value={
                          videoEditData.subcategory_media_VideoName_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name English
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Hindi</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_hindi"
                        value={videoEditData.subcategory_media_VideoName_hindi}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name Hindi
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Urdu</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_urdu"
                        value={videoEditData.subcategory_media_VideoName_urdu}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Sabaq Name
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Name Transliteration</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoName_lit"
                        value={videoEditData.subcategory_media_VideoName_lit}
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name Urdu
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration English</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_english"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_english
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration English
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Hindi</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_hindi"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_hindi
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Hindi
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Urdu</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_urdu"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_urdu
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Urdu
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Label>Duration Transliteration</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="subcategory_media_VideoDuaration_lit"
                        value={
                          videoEditData.subcategory_media_VideoDuaration_lit
                        }
                        onChange={handleChangeVideoEdit}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Duration Transliteration
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>English Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for English Video"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Hindi Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for Hindi Video"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Urdu Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for Urdu Video"
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Transliteration Video Thumbnail</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="Select Thumbnail for Transliteration Video"
                        />
                      </Form.Group>
                    </div>
                    <div className="buttons-modal  text-center mt-2  ">
                      <button className="me-2 ">MEDIA UPDATE</button>
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            {/* <Modal.Footer className="justify-content-center">
           
            </Modal.Footer> */}
          </Modal>
        </div>
        <hr />
        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
          <Nav variant="pills" className="flex-row">
            <Nav.Item>
              <Nav.Link eventKey="1">Features & Benefits</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="2">Workshop in 10 Steps</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            {/*   ====== Feature ===== */}
            <Tab.Pane eventKey="1">
              <div className="feature-lists row g-3">
                <div className="add-btn">
                  <button onClick={handleAddFeatureShow}>Add +</button>
                </div>

                {loader ? (
                  <FormLoader />
                ) : (
                  <>
                    {featurelist &&
                      featurelist.length > 0 &&
                      featurelist.map((card, index) => {
                        return (
                          <div className="col-md-4">
                            <div
                              className="feature-card "
                              key={"index" + index}
                            >
                              <div className="img">
                                <img
                                  src={
                                    BASE_URL_FOR_IMAGES + card.image_path
                                      ? BASE_URL_FOR_IMAGES + card.image_path
                                      : ic_maktab_icon
                                  }
                                  alt=""
                                  className="f-icon"
                                />
                                <div className="add-btn">
                                  <button
                                    className="delete "
                                    onClick={() => {
                                      handleDeleteShow();
                                      setDeleteId(card.fb_id);
                                      setFeleteStepurl(
                                        ApiConfig.DELETEAPI +
                                        `?fb_id=${card.fb_id}`
                                      );
                                    }}
                                  >
                                    <img src={ic_delete} alt="" />
                                  </button>
                                  <button
                                    onClick={() => {
                                      filterData(card.fb_id);
                                      ListApiEdit(card.fb_id);
                                      setIdEdit("edit");
                                      handleAddFeatureShow();
                                      setImageWidth("117px");
                                    }}
                                  >
                                    <img src={ic_edit} alt="" />
                                  </button>
                                </div>
                              </div>
                              {card.list.map((subitem, index) => {
                                return (
                                  <>
                                    <div
                                      className="heading"
                                      key={"newindex" + index}
                                    >
                                      <label htmlFor="">{subitem?.title}</label>
                                    </div>
                                    <p className="content">
                                      {subitem?.description}
                                    </p>
                                    <hr />
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                  </>
                )}
              </div>

              <Modal
                show={addFeature}
                onHide={handleAddFeatureClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {idEdit === "edit" ? "Update Features" : "Add Features"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form
                    noValidate
                    validated={validated}
                    // onSubmit={handleSubmit}
                    onSubmit={(e) => handleSubmit(e, "feature")}
                  >
                    <div className="feature-wrapper">
                      <div className="row">
                        {/* <div className="col-md-12 mb-4">
                          <Form.Label>Feature Image</Form.Label>
                          <Form.Control
                            required
                            type="file"
                            name="file"
                            value={addFeature.file}
                            onChange={(e) =>
                              setAddFeatureData({
                                ...addFeatureData,
                                file: e.target.files[0],
                              })
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Choose Picture
                          </Form.Control.Feedback>
                        </div> */}
                        {idEdit ? (
                          <>
                            <div>
                              {" "}
                              <Form.Label>Feature Image</Form.Label>
                            </div>
                            <div className="col-md-12 mb-4  d-flex">
                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "col-md-12 "
                                    : "col-md-2 "
                                }
                              >
                                <Form.Control
                                  style={{ width: imageWidth }}
                                  type="file"
                                  name="file"
                                  accept="image/x-png,image/gif,image/jpeg"
                                  // value={addFeatureData.file}
                                  onChange={(e) => {
                                    setAddFeatureData({
                                      ...addFeatureData,
                                      file: e.target.files[0],
                                    });
                                    setImageWidth("100%");
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Feature Image
                                </Form.Control.Feedback>
                              </div>

                              <div
                                className={
                                  imageWidth === "100%"
                                    ? "d-none"
                                    : "col-md-10 "
                                }
                              >
                                <Form.Control
                                  disabled={true}
                                  required
                                  type="text"
                                  name="file"
                                  value={addFeatureData.file}
                                  onChange={handleChange}
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Feature Image
                                </Form.Control.Feedback>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12 mb-4">
                            <Form.Label>Feature Image</Form.Label>
                            <Form.Control
                              required
                              type="file"
                              name="file"
                              accept="image/x-png,image/gif,image/jpeg"
                              // value={addFeatureData?.file}
                              onChange={(e) =>
                                setAddFeatureData({
                                  ...addFeatureData,
                                  file: e.target.files[0],
                                })
                              }
                            />

                            <Form.Control.Feedback type="invalid">
                              Please Enter Feature Image
                            </Form.Control.Feedback>
                          </div>
                        )}
                        <div className="col-md-6 mb-4">
                          <Form.Label>Feature Title Urdu</Form.Label>
                          <Form.Control
                            type="text"
                            name="urdu_title"
                            maxLength="200"
                            value={addFeatureData.urdu_title}
                            onChange={handleChange}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Title Urdu.
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Feature Title Hindi</Form.Label>
                          <Form.Control
                            type="text"
                            name="hindi_title"
                            maxLength="200"
                            value={addFeatureData.hindi_title}
                            onChange={handleChange}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Title Hindi.
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Feature Title English</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            maxLength="200"
                            name="english_title"
                            value={addFeatureData.english_title}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Title English.
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Form.Label>Feature Title Transliteration</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            maxLength="200"
                            name="translitration_title"
                            value={addFeatureData.translitration_title}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Title Transliteration.
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-4">
                          <Form.Label>Feature Description Urdu</Form.Label>
                          <Form.Control
                            as="textarea"
                            required
                            rows={3}
                            maxLength="10000"
                            name="urdu_desc"
                            value={addFeatureData.urdu_desc}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description Urdu.
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-4">
                          <Form.Label>Feature Description Hindi</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            required
                            maxLength="10000"
                            name="hindi_desc"
                            value={addFeatureData.hindi_desc}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description Hindi.
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-4">
                          <Form.Label>Feature Description English</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            maxLength="10000"
                            name="english_desc"
                            value={addFeatureData.english_desc}
                            onChange={handleChange}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Description English.
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-md-12 mb-4">
                          <Form.Label>
                            Feature Description Transliteration
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            required
                            maxLength="10000"
                            name="translitration_desc"
                            value={addFeatureData.translitration_desc}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Transliteration Description.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="buttons-main">
                        {/* <button className="cancel-btn">Cancel</button> */}

                        <button
                          className="submit-btn"
                        // onClick={(e) => addFeatureApi(e)}
                        >
                          {idEdit === "edit" ? "update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
            </Tab.Pane>
            {/* ======= Steps ======= */}
            <Tab.Pane eventKey="2">
              <div className="jobDescriptio-main">
                <div className="wrapper">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                    <div className="row g-3">
                      <div className="">
                        <Nav variant="pills" className="flex-row">
                          {steps.map((stepNew, index) => {
                            return (
                              <Nav.Item key={"step" + index}>
                                <Nav.Link
                                  eventKey={stepNew.step_id}
                                  onClick={() => {
                                    setAddBtn(stepNew.step_id);
                                    setAddBtnName(stepNew.name);

                                    tenStepsListApi(stepNew.step_id);
                                  }}
                                >
                                  Step {stepNew.name}
                                </Nav.Link>
                              </Nav.Item>
                            );
                          })}
                        </Nav>
                      </div>
                      <div className="">
                        <Tab.Content>
                          {/* ==== Steps 1 ==== */}

                          <Tab.Pane eventKey={addBtn}>
                            <div className="add-btn">
                              <button onClick={handleAddStepsShow}>
                                Add +
                              </button>
                            </div>

                            <div className="row jd-main g-3">

                              {
                                loader ? 
                                <FormLoader/>
                                :
                                <>
                                {
                                  tenStepsList && tenStepsList.length > 0 ? 
                                  tenStepsList.map((card, index) => {
                                    return (
                                      <>
                                        {loader ? (
                                          <FormLoader />
                                        ) : (
                                          <div className="col-md-4">
                                            <div className=" jd-cards">
                                              <div className="add-btn">
                                                <button
                                                  className="delete"
                                                  onClick={() => {
                                                    handleDeleteShow();
                                                    setFeleteStepurl(
                                                      ApiConfig.SCHOOL_STEP_DELETE +
                                                      `?chapter_id=${card.chapter_id}`
                                                    );
                                                  }}
                                                >
                                                  <img src={ic_delete} alt="" />
                                                </button>
                                                <button
                                                  onClick={() => {
                                                    handleAddStepsShow();
                                                    tenStepsListApiEdit(
                                                      card.chapter_id
                                                    );
                                                    setIdEdit("edit");
                                                  }}
                                                >
                                                  <img src={ic_edit} alt="" />
                                                </button>
                                              </div>
                                              {card.list.map((subitme, index) => {
                                                console.log(
                                                  subitme,
                                                  "test subdata"
                                                );
                                                return (
                                                  <>
                                                    <h6>{subitme.title}</h6>
                                                    <p>{subitme.description}</p>
                                                    <hr />
                                                  </>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    );
                                  })
                                  :
                                  <h5 className="text-center text-danger">No Data Found</h5>
                                }
                                </>
                              }
                              {/* {tenStepsList &&
                                tenStepsList.length > 0 &&
                                tenStepsList.map((card, index) => {
                                  console.log(card);
                                  return (
                                    <>
                                      {loader ? (
                                        <FormLoader />
                                      ) : (
                                        <div className="col-md-4">
                                          <div className=" jd-cards">
                                            <div className="add-btn">
                                              <button
                                                className="delete"
                                                onClick={() => {
                                                  handleDeleteShow();
                                                  setFeleteStepurl(
                                                    ApiConfig.SCHOOL_STEP_DELETE +
                                                    `?chapter_id=${card.chapter_id}`
                                                  );
                                                }}
                                              >
                                                <img src={ic_delete} alt="" />
                                              </button>
                                              <button
                                                onClick={() => {
                                                  handleAddStepsShow();
                                                  tenStepsListApiEdit(
                                                    card.chapter_id
                                                  );
                                                  setIdEdit("edit");
                                                }}
                                              >
                                                <img src={ic_edit} alt="" />
                                              </button>
                                            </div>
                                            {card.list.map((subitme, index) => {
                                              console.log(
                                                subitme,
                                                "test subdata"
                                              );
                                              return (
                                                <>
                                                  <h6>{subitme.title}</h6>
                                                  <p>{subitme.description}</p>
                                                  <hr />
                                                </>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  );
                                })} */}
                            </div>
                          </Tab.Pane>

                          <Modal
                            show={AddSteps}
                            onHide={handleAddStepsClose}
                            size="xl"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>
                                {" "}
                                {idEdit === "edit"
                                  ? `Update Steps ${addBtnName}`
                                  : `Add Steps ${addBtnName}`}{" "}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Form
                                noValidate
                                validated={validated}
                                // onSubmit={handleSubmit}
                                onSubmit={(e) => handleSubmit(e, "workshop")}
                              >
                                <div className="feature-wrapper">
                                  <div className="row">
                                    <div className="col-md-3 mb-4">
                                      <Form.Label>Title English</Form.Label>
                                      <Form.Control
                                        // type="text"
                                        as="textarea"
                                        rows={3}
                                        maxLength="10000"
                                        required
                                        name="english_title"
                                        value={addWorkShopSteps.english_title}
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Title English
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-3 mb-4">
                                      <Form.Label>Title Urdu</Form.Label>
                                      <Form.Control
                                        // type="text"
                                        as="textarea"
                                        rows={3}
                                        maxLength="10000"
                                        required
                                        name="urdu_title"
                                        value={addWorkShopSteps.urdu_title}
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Title Urdu
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-3 mb-4">
                                      <Form.Label>Title Hindi</Form.Label>
                                      <Form.Control
                                        // type="text"
                                        as="textarea"
                                        rows={3}
                                        maxLength="10000"
                                        required
                                        name="hindi_title"
                                        value={addWorkShopSteps.hindi_title}
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Title Hindi
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-3 mb-4">
                                      <Form.Label>
                                        Title Transliteration
                                      </Form.Label>
                                      <Form.Control
                                        // type="text"
                                        as="textarea"
                                        rows={3}
                                        maxLength="10000"
                                        required
                                        name="english_lit_title"
                                        value={
                                          addWorkShopSteps.english_lit_title
                                        }
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Title Transliteration
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                      <Form.Label>
                                        Description English
                                      </Form.Label>
                                      <Form.Control
                                        required
                                        as="textarea"
                                        rows={3}
                                        maxLength="10000"
                                        name="english_desc"
                                        value={addWorkShopSteps.english_desc}
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Description English.
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                      <Form.Label>Description Urdu</Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        required
                                        rows={3}
                                        maxLength="10000"
                                        name="urdu_desc"
                                        value={addWorkShopSteps.urdu_desc}
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Description Urdu.
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                      <Form.Label>Description Hindi</Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        rows={3}
                                        name="hindi_desc"
                                        maxLength="10000"
                                        required
                                        value={addWorkShopSteps.hindi_desc}
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Description Hindi.
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                      <Form.Label>
                                        Description Transliteration
                                      </Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        required
                                        rows={3}
                                        maxLength="10000"
                                        name="english_lit_desc"
                                        value={
                                          addWorkShopSteps.english_lit_desc
                                        }
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Description
                                        Transliteration.
                                      </Form.Control.Feedback>
                                    </div>
                                    <hr />
                                    <div className="col-md-3 mb-4">
                                      <Form.Label>Title English 1</Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="english_title1"
                                        value={addWorkShopSteps.english_title1}
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Step
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-3 mb-4">
                                      <Form.Label>Title Urdu 1</Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="urdu_title1"
                                        value={addWorkShopSteps.urdu_title1}
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Step
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-3 mb-4">
                                      <Form.Label>Title Hindi 1</Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="hindi_title1"
                                        value={addWorkShopSteps.hindi_title1}
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Step
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-3 mb-4">
                                      <Form.Label>
                                        Title Transliteration 1
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="english_lit_title1"
                                        value={
                                          addWorkShopSteps.english_lit_title1
                                        }
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Step
                                      </Form.Control.Feedback>
                                    </div>

                                    <div className="col-md-6 mb-4">
                                      <Form.Label>
                                        Description English 1
                                      </Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        rows={3}
                                        maxLength="10000"
                                        name="english_desc1"
                                        value={addWorkShopSteps.english_desc1}
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Tarjama.
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                      <Form.Label>
                                        Description Urdu 1
                                      </Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        rows={3}
                                        maxLength="10000"
                                        name="urdu_desc1"
                                        value={addWorkShopSteps.urdu_desc1}
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Tarjama.
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                      <Form.Label>
                                        Description Hindi 1
                                      </Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        rows={3}
                                        maxLength="10000"
                                        name="hindi_desc1"
                                        value={addWorkShopSteps.hindi_desc1}
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Tarjama.
                                      </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                      <Form.Label>
                                        Description Transliteration 1
                                      </Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        maxLength="10000"
                                        rows={3}
                                        name="english_lit_desc1"
                                        value={
                                          addWorkShopSteps.english_lit_desc1
                                        }
                                        onChange={handleWorkShopSteps}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please Enter Tarjama.
                                      </Form.Control.Feedback>
                                    </div>
                                  </div>
                                  <div className="buttons-main">
                                    {/* <button className="cancel-btn">Cancel</button> */}

                                    <button
                                      className="submit-btn"
                                      onClick={(e) => {
                                        // addWorkShopPostApi(e);
                                        // handleAddStepsClose();
                                      }}
                                    >
                                      {idEdit === "edit" ? "Update" : "Submit"}
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            </Modal.Body>
                          </Modal>
                        </Tab.Content>
                      </div>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </main>
  );
};

export default School;
