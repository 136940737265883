// export const BASE_URL = "https://deeniyat-plus.app.redbytes.in:3010/";
export const BASE_URL = "https://api.deeniyatplus.com:3010/";
export const BASE_URL_FOR_IMAGES = "https://api.deeniyatplus.com:3010/api/public/uploads/";
export const IMAGE_URL = "https://api.deeniyatplus.com:3010/api/public/assist/";
// export const BASE_URL_FOR_IMAGES = "https://deeniyat-plus.app.redbytes.in:3010/api/public/uploads/";
const testing = "https://api.deeniyatplus.com:3010/api/admin/exam-file-category"
// const testing = "https://deeniyat-plus.app.redbytes.in:3010/api/admin/exam-file-category"
const ADMIN = "api/admin/"
const FEATURE = "features-and-benefits-list"
const FEATURE_ADD = "features-and-benefits-add"
const FEATURE_UPDATE = "features-and-benefits-update"
const FEATURE_EDIT = "features-and-benefits-edit"
const FEATURE_DELETE = "features-and-benefits-delete"
const WORKSHOP_LIST = "work-shop-ten-steps-list"
const WORKSHOP_ADD = "work-shop-ten-steps-add"
const WORKSHOP_UPDATE = "work-shop-ten-steps-update"
const WORKSHOP_EDIT = "work-shop-ten-steps-edit"
const WORKSHOP_DELETE = "work-shop-ten-steps-delete"

const SCHOOL_STEP_LIST = "school-work-shop-ten-steps-list"
const SCHOOL_STEP_ADD = "school-work-shop-ten-steps-add"
const SCHOOL_STEP_UPDATE = "school-work-shop-ten-steps-update"
const SCHOOL_STEP_DELETE = "school-work-shop-ten-steps-delete"
const SCHOOL_STEP_EDIT_LIST = "school-work-shop-ten-steps-edit"

const JOBDESC_LIST = "job-description-list"
const JOBDESC_LIST_DETAILS = "job-description-details-list"
const JOBDESC_LIST_DETAILS_ADD = "job-description-details-add"
const JOBDESC_LIST_DETAILS_UPDATE = "job-description-details-update"
const JOBDESC_ADD = "job-description-add"
const JOBDESC_UPDATE = "job-description-update"
const JOBDESC_EDIT = "job-description-details-edit"

const JOBDESC_DELETE = "job-description-details-delete"
//supervision
const SUPERVISION_MONTH_DETAILS = "supervision-notes"
const SUPERVISION_MONTH_ADD = "supervision-notes-add"
const SUPERVISION_MONTH_EDIT = "edit-notes"
const SUPERVISION_MONTH_UPDATE = "supervision-notes-update"
const SUPERVISION_MONTH_DELETE = "supervision-notes-delete"
const GET_MEDIEA = "get-subcategory-media"
const UPDATE_MEDIEA = "subcategory-media-update"
//exam section
const EXAM_PAPER_ADD = "exam-paper-add"
const EXAM_PAPER_EDIT = "exam-paper-edit"
const EXAM_PAPER_UPDATE = "exam-paper-update"
const EXAM_PAPER_LIST = "exam-paper-list"
const EXAM_PAPER_DELETE = "exam-paper-delete"
const EXAM_PAPER_DELETE_CATEGORY = "exam-paper-categorie-delete"
const EXAM_PAPER_ADD_CATEGORY = "add-exam-paper-categorie"
const EXAM_PAPER_CATEGORY_LIST = "exam-file-category"

//daily search
const DAILY_POST_LIST = "daily-posts";
const DAILY_POST_EDIT = "daily-post-edit";
const DAILY_POST_ADD = "daily-post-add";
const DAILY_POST_UPDATE = "daily-post-update";
const DAILY_POST_DELETE = "daily-post-delete";

// qurane kareem 
const QURANE_KAREEM = "assist-list";
const ADD_QURAAN = "assist-add";
const UPDATE_DATA = "assist-update";
const ASSIST_DELETE = "assist-delete";
const EDIT_DATA = "assist-edit";

// SYLLABUS
const SYLLABUS = "syllabus-list"
const ADD_SYLLABUS = "syllabus-add";
const UPDATE_SYLLABUS = "syllabus-update";
const SULLABUS_DELETE = "syllabus-delete";
const EDIT_SYLLABUS = "syllabus-view";

// Syllabus Details
const  ADD_SYLLABUS_DETAILS = "syllabus-details-add";
const UPDATE_SYLLABUS_DETAILS = "syllabus-details-update";
const SYLLABUS_DETAILS_LIST = "syllabus-details-list";
const SYLLABUS_DETAILS_VIEW = "syllabus-details-view";
const SYLLABUS_DETAILS_DELETE = "syllabus-details-delete";

// Assessment 
const GET_ASSESSMENT = "get-assessment"
const QUESTION_WITH_OPTION = "add-question-options"
const DELETE_ASSESSMENT = "delete-question-options"
const EDIT_ASSESSMENT = "edit-question-options"
const UPDATE_ASSESSMENT = "update-question-options"


// Google Analytics
const GOOGLE_ANALYTICS = "dashboard/analytics"


const NOTIFICATION = "pushb"
const ApiConfig = {
  BASE_URL: BASE_URL,
  LOGIN: BASE_URL + "api/auth/login",
  LOGOUT: BASE_URL + "api/auth/logout",
  DASHBOARD: BASE_URL + "api/admin/dashboard",
  //  =============> SCHOOL API <==============
  SCHOOL_STEP_LIST: BASE_URL + ADMIN + SCHOOL_STEP_LIST,
  SCHOOL_STEP_ADD: BASE_URL + ADMIN + SCHOOL_STEP_ADD,
  SCHOOL_STEP_UPDATE: BASE_URL + ADMIN + SCHOOL_STEP_UPDATE,
  SCHOOL_STEP_DELETE: BASE_URL + ADMIN + SCHOOL_STEP_DELETE,
  SCHOOL_STEP_EDIT_LIST: BASE_URL + ADMIN + SCHOOL_STEP_EDIT_LIST,



  //  =============> SCHOOL API <==============

  //Feature set
  FEATURE_LIST: BASE_URL + ADMIN + FEATURE,
  FEATURE_ADD: BASE_URL + ADMIN + FEATURE_ADD,
  FEATURE_UPDATE: BASE_URL + ADMIN + FEATURE_UPDATE,
  FEATURE_EDIT: BASE_URL + ADMIN + FEATURE_EDIT,
  DELETEAPI: BASE_URL + ADMIN + FEATURE_DELETE,
  //workshope
  WORKSHOP_LIST: BASE_URL + ADMIN + WORKSHOP_LIST,
  WORKSHOP_ADD: BASE_URL + ADMIN + WORKSHOP_ADD,
  WORKSHOP_UPDATE: BASE_URL + ADMIN + WORKSHOP_UPDATE,
  WORKSHOP_EDIT: BASE_URL + ADMIN + WORKSHOP_EDIT,
  WORKSHOP_DELETEAPI: BASE_URL + ADMIN + WORKSHOP_DELETE,

  //job description
  JOBDESC_LIST: BASE_URL + ADMIN + JOBDESC_LIST,
  JOBDESC_LIST_DETAILS: BASE_URL + ADMIN + JOBDESC_LIST_DETAILS,
  JOBDESC_LIST_DETAILS_ADD: BASE_URL + ADMIN + JOBDESC_LIST_DETAILS_ADD,
  JOBDESC_LIST_DETAILS_UPDATE: BASE_URL + ADMIN + JOBDESC_LIST_DETAILS_UPDATE,
  JOBDESC_ADD: BASE_URL + ADMIN + JOBDESC_ADD,
  JOBDESC_UPDATE: BASE_URL + ADMIN + JOBDESC_UPDATE,
  JOBDESC_EDIT: BASE_URL + ADMIN + JOBDESC_EDIT,
  JOBDESC_DELETE: BASE_URL + ADMIN + JOBDESC_DELETE,
  //supervision api

  SUPERVISION_MONTH_DETAILS: BASE_URL + ADMIN + SUPERVISION_MONTH_DETAILS,
  SUPERVISION_MONTH_ADD: BASE_URL + ADMIN + SUPERVISION_MONTH_ADD,
  SUPERVISION_MONTH_EDIT: BASE_URL + ADMIN + SUPERVISION_MONTH_EDIT,
  SUPERVISION_MONTH_UPDATE: BASE_URL + ADMIN + SUPERVISION_MONTH_UPDATE,
  SUPERVISION_MONTH_DELETE: BASE_URL + ADMIN + SUPERVISION_MONTH_DELETE,
  //exam
  EXAM_PAPER_ADD: BASE_URL + ADMIN + EXAM_PAPER_ADD,
  EXAM_PAPER_EDIT: BASE_URL + ADMIN + EXAM_PAPER_EDIT,
  EXAM_PAPER_UPDATE: BASE_URL + ADMIN + EXAM_PAPER_UPDATE,
  EXAM_PAPER_LIST: BASE_URL + ADMIN + EXAM_PAPER_LIST,
  EXAM_PAPER_DELETE: BASE_URL + ADMIN + EXAM_PAPER_DELETE,
  EXAM_PAPER_DELETE_CATEGORY: BASE_URL + ADMIN + EXAM_PAPER_DELETE_CATEGORY,
  EXAM_PAPER_ADD_CATEGORY: BASE_URL + ADMIN + EXAM_PAPER_ADD_CATEGORY,
  EXAM_PAPER_CATEGORY_LIST: BASE_URL + ADMIN + EXAM_PAPER_CATEGORY_LIST,
  testing: testing,
  //daily search
  DAILY_POST_LIST: BASE_URL + ADMIN + DAILY_POST_LIST,
  DAILY_POST_EDIT: BASE_URL + ADMIN + DAILY_POST_EDIT,
  DAILY_POST_ADD: BASE_URL + ADMIN + DAILY_POST_ADD,
  DAILY_POST_UPDATE: BASE_URL + ADMIN + DAILY_POST_UPDATE,
  DAILY_POST_DELETE: BASE_URL + ADMIN + DAILY_POST_DELETE,

  //notifications
  NOTIFICATION: BASE_URL + ADMIN + NOTIFICATION,

  // =============MEDIA API==========
  GET_MEDIEA: BASE_URL + ADMIN + GET_MEDIEA,
  UPDATE_MEDIEA: BASE_URL + ADMIN + UPDATE_MEDIEA,

  // =============MEDIA API==========

  // support
  QURANE_KAREEM_LIST: BASE_URL + ADMIN + QURANE_KAREEM,
  ASSIST_DELETE_ID: BASE_URL + ADMIN + ASSIST_DELETE,
  ADD_QURAAN_FORM: BASE_URL + ADMIN + ADD_QURAAN,
  GET_EDIT_DATA: BASE_URL + ADMIN + EDIT_DATA,
  UPDATE_ASSIST_DATA: BASE_URL + ADMIN + UPDATE_DATA,

  // Syllabus
  ADD_SYLLABUS_FORM : BASE_URL + ADMIN + ADD_SYLLABUS,
  SYLLABUS_LIST : BASE_URL + ADMIN + SYLLABUS,
  UPDATE_SYLLABUS_FORM : BASE_URL + ADMIN + UPDATE_SYLLABUS,
  SYLLABUS_DELETE_ID : BASE_URL + ADMIN + SULLABUS_DELETE,
  SYLLABUS_EDIT_ID : BASE_URL + ADMIN + EDIT_SYLLABUS,

  // Syllabus Details
  SYLLABUS_DETAILS : BASE_URL + ADMIN + ADD_SYLLABUS_DETAILS,
  UPDATE_SYLLABUS_DETAILS_BY_ID : BASE_URL + ADMIN + UPDATE_SYLLABUS_DETAILS,
  SYLLABUS_DETAILS_LIST_BY_ID : BASE_URL + ADMIN + SYLLABUS_DETAILS_LIST,
  SYLLABUS_DETAILS_VIEW_BY_ID : BASE_URL + ADMIN + SYLLABUS_DETAILS_VIEW,
  SYLLABUS_DETAILS_DELETE_BY_ID : BASE_URL + ADMIN + SYLLABUS_DETAILS_DELETE,

  // Assessment 
  GET_ASSESSMENT_LIST : BASE_URL + ADMIN + GET_ASSESSMENT,
  ADD_ASSESSMENT_FORM : BASE_URL + ADMIN + QUESTION_WITH_OPTION,
  DELETE_ASSESSMENT_FORM : BASE_URL + ADMIN + DELETE_ASSESSMENT,
  GET_EDIT_ASSESSMENT_FORM : BASE_URL + ADMIN + EDIT_ASSESSMENT,
  UPDATE_ASSESSMENT_FORM : BASE_URL + ADMIN + UPDATE_ASSESSMENT,



// Dashboard
GET_GOOGLE_ANALYTICS : BASE_URL + ADMIN + GOOGLE_ANALYTICS


};
export default ApiConfig;



