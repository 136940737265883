import React, { useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { Link } from "react-router-dom";
import quraan_kareem_cover_9_line from "../../../assets/images/quraan_kareem_cover_9_line.png";
import ic_back from "../../../assets/images/ic_back.svg";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

const QuraanApps = () => {
  const { sidebar, adminData } = useContext(AppContext);

  const [addQuraan, setAddQuraan] = useState(false);
  const handleAddQuraanClose = () => setAddQuraan(false);
  const handleAddQuraanShow = () => setAddQuraan(true);
  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <Link to="/MobileApps" className="back-btns">
            <img src={ic_back} alt="" />
          </Link>

          <label htmlFor="">Quraan Apps Details</label>
        </div>
      </div>

      <div className="quraan-details">
        <div className="btns-quraan add-btn">
          <button onClick={handleAddQuraanShow}>Update</button>
        </div>
        <div className="content">
          <div className="img">
            <img src={quraan_kareem_cover_9_line} alt="" />
          </div>
          <div className="heading">Quraane Kareem - 11 Line</div>
          <p className="details">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur
            delectus libero atque ut aut! Quae rerum possimus alias culpa
            officiis, minus molestias tempora non similique veritatis
            laboriosam, nesciunt inventore sunt?
          </p>
          <Link to="#" className="app-btn me-2">
            Google Play
          </Link>
          <Link to="#" className="app-btn">
            App Store
          </Link>
        </div>

        <Modal
          show={addQuraan}
          onHide={handleAddQuraanClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Quraan Apps Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="feature-wrapper">
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <Form.Label>Quraan Image</Form.Label>
                    <Form.Control type="file" />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Sabaq Name
                    </Form.Control.Feedback>
                  </div>

                  <div className="col-md-6 mb-4">
                    <Form.Label>Heading Urdu</Form.Label>
                    <Form.Control type="text" />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Heading
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-md-6 mb-4">
                    <Form.Label>Heading Hindi</Form.Label>
                    <Form.Control type="text" />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Heading
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-md-6 mb-4">
                    <Form.Label>Heading English</Form.Label>
                    <Form.Control type="text" />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Heading
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-md-6 mb-4">
                    <Form.Label>Heading Translitration</Form.Label>
                    <Form.Control type="text" />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Heading
                    </Form.Control.Feedback>
                  </div>

                  <div className="col-md-12 mb-4">
                    <Form.Label>Description Urdu</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Tarjama.
                    </Form.Control.Feedback>
                  </div><div className="col-md-12 mb-4">
                    <Form.Label>Description Hindi</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Tarjama.
                    </Form.Control.Feedback>
                  </div><div className="col-md-12 mb-4">
                    <Form.Label>Description English</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Tarjama.
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-md-12 mb-4">
                    <Form.Label>Description Translitration</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Tarjama.
                    </Form.Control.Feedback>
                  </div>

                  <div className="col-md-12 mb-4">
                    <Form.Label>Google Play Link</Form.Label>
                    <Form.Control type="text" />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Heading
                    </Form.Control.Feedback>
                  </div>

                  <div className="col-md-12 mb-4">
                    <Form.Label>App Store Link</Form.Label>
                    <Form.Control type="text" />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Heading
                    </Form.Control.Feedback>
                  </div>
                </div>
                <div className="buttons-main">
                  {/* <button className="cancel-btn">Cancel</button> */}

                  <button className="submit-btn">Submit</button>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </main>
  );
};

export default QuraanApps;


