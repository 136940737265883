import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { Link, useLocation } from "react-router-dom";
import quraan_kareem_cover_9_line from "../../../assets/images/ic_nursery_tr_1.png";
import ic_back from "../../../assets/images/ic_back.svg";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import panch_min_ka_madrasa from "../../../assets/images/panch_min_ka_madrasa.png";
import ic_delete from "../../../assets/images/ic_delete.svg";
import ic_edit from "../../../assets/images/ic_edit.svg";
import ic_eyes from "../../../assets/images/ic_eyes.svg";
import ic_nursery_tr_2 from "../../../assets/images/ic_nursery_tr_2.png";
import {
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../../api/ApiServices";
import ApiConfig, { IMAGE_URL } from "../../../api/ApiConfig";
import { sendAsFormData } from "../../commonFun";
import { ToastMsg } from "../../TostMsg";
import FormLoader from "../../FormLoader";
const Syllabus = () => {
  let groupId = localStorage.getItem("GroupId");

  // const [groupId, setGroupId] = useState(0);
  // const location = useLocation();
  // const getGroupId = location.state;

  console.log("groupId", groupId);

  const { sidebar, adminData } = useContext(AppContext);

  const [addQuraan, setAddQuraan] = useState(false);
  const handleAddQuraanClose = () => setAddQuraan(false);
  const handleAddQuraanShow = () => setAddQuraan(true);

  const [addSyllabus, setAddSyllabus] = useState(false);
  const handleAddSyllabusClose = () => setAddSyllabus(false);
  const handleAddSyllabusShow = () => setAddSyllabus(true);

  const [deleteModal, setDelete] = useState(false);
  const handleDeleteClose = () => setDelete(false);
  const handleDeleteShow = () => setDelete(true);

  const [loader, setLoader] = useState(false);
  const [isEdit, setIsEdit] = useState("");
  const [syllabusDetailsList, setSyllabusDetailsList] = useState([]);
  console.log("syllabusDetailsList", syllabusDetailsList)
  const [syllabusId, setSyllabusId] = useState(0);

  const [syllabusObj, setSyllabusObj] = useState({
    syllabus_title_hindi: "",
    syllabus_desc_hindi: "",
    pub_syllabus_id: groupId,
    syllabus_title_english: "",
    syllabus_desc_english: "",
    syllabus_title_lit: "",
    syllabus_desc_lit: "",
    syllabus_title_urdu: "",
    syllabus_desc_urdu: "",
    file: "",

    buy_now_link: "",
    open_app_link: "",
    play_store_link: "",
    app_store_link: "",
    
    // link_one: "",
    // link_two: "",
  });

  console.log("syllabusObj", syllabusObj);

  // SYLLABUS DETAILS List
  const syllabusDetailsListApi = (urldata) => {
    setLoader(true);
    simpleGetCallWithErrorResponse(urldata)
      .then((res) => {
        console.log("SyllabusDetailsList", res);
        setLoader(false);
        setSyllabusDetailsList(res.json.data);
      })
      .catch((err) => {
        setLoader(false);
        ToastMsg("error", "something went wrong");
      });
  };

  useEffect(() => {
    syllabusDetailsListApi(
      ApiConfig.SYLLABUS_DETAILS_LIST_BY_ID + `?group_id=${groupId}`
    );
  }, []);

  // Add Syllabus Details
  const submitAddSyllabus = (e) => {
    e.preventDefault();
    handleAddQuraanClose(true);
    setLoader(true);

    !isEdit
      ? postWithAuthCallWithErrorResponse(
        ApiConfig.SYLLABUS_DETAILS,
        sendAsFormData(syllabusObj)
      )
        .then((res) => {
          setLoader(false);
          if (res.json.result) {
            // setSyllabusObj({})
            ToastMsg("success", "Form Submitted Successfully");
            syllabusDetailsListApi(
              ApiConfig.SYLLABUS_DETAILS_LIST_BY_ID + `?group_id=${groupId}`
            );
          } else {
            syllabusDetailsListApi(
              ApiConfig.SYLLABUS_DETAILS_LIST_BY_ID + `?group_id=${groupId}`
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
      : postWithAuthCallWithErrorResponse(
        ApiConfig.UPDATE_SYLLABUS_DETAILS_BY_ID,
        sendAsFormData(syllabusObj)
      )
        .then((res) => {
          setLoader(false);
          if (res.json.result) {
            ToastMsg("success", "Form Updated Successfully");
            syllabusDetailsListApi(
              ApiConfig.SYLLABUS_DETAILS_LIST_BY_ID + `?group_id=${groupId}`
            );
          } else {
            syllabusDetailsListApi(
              ApiConfig.SYLLABUS_DETAILS_LIST_BY_ID + `?group_id=${groupId}`
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  // Get Update Data of Syllabus
  const getUpdateDataSyllabus = (id) => {
    simpleGetCallWithErrorResponse(
      ApiConfig.SYLLABUS_DETAILS_VIEW_BY_ID + `?group_id=${id}`
    )
      .then((res) => {
        console.log("Edit Syllabus", res);
        let data = res.json.syllabus;
        let links = res.json.syllabus_data;
        links.sort((a, b) => a.language_id - b.language_id);
        // let syllabus_data = res.json.syllabus_data;

        setSyllabusObj({
          syllabus_title_hindi: data.syllabus_title_hindi,
          syllabus_desc_hindi: data.course_desc_hindi,
          pub_syllabus_id: groupId,
          syllabus_title_english: data.syllabus_title_english,
          syllabus_desc_english: data.course_desc_english,
          syllabus_title_lit: data.syllabus_title_lit,
          syllabus_desc_lit: data.course_desc_lit,
          syllabus_title_urdu: data.syllabus_title_urdu,
          syllabus_desc_urdu: data.course_desc_urdu,
          // file: syllabus_data?.syllabus_image[0],
          // link_one: data.link_one,
          // link_two: data.link_two,
          open_app_link: links[0]?.open_app_link || "",
          buy_now_link: links[0]?.buy_now_link || "",
          play_store_link: links[0]?.play_store_link || "",
          app_store_link: links[0]?.app_store_link || "",

          group_id: id,
        });
      })
      .catch((err) => {
        console.log(err);
        // ToastMsg("error", "something went wrong");
      });
  };

  // Syllabus Delete
  const deleteSyllabus = (id) => {
    setSyllabusId(id);
  };

  // Delete Assist
  const deleteAssistPopUp = () => {
    handleDeleteClose();
    setLoader(true);
    simpleGetCallWithErrorResponse(
      ApiConfig.SYLLABUS_DETAILS_DELETE_BY_ID + `?group_id=${syllabusId}`
    )
      .then((res) => {
        setLoader(false);
        handleDeleteClose();
        ToastMsg("success", "Successfully Deleted");
        syllabusDetailsListApi(
          ApiConfig.SYLLABUS_DETAILS_LIST_BY_ID + `?group_id=${groupId}`
        );
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        syllabusDetailsListApi(
          ApiConfig.SYLLABUS_DETAILS_LIST_BY_ID + `?group_id=${groupId}`
        );
      })
  };

  return (
    <main
      id="cx-main"
      className={sidebar ? "admin-main" : "cx-active admin-main"}
    >
      <div className="heading-btn-main">
        <div className="heading-common">
          <Link to="/Publication" className="back-btns">
            <img src={ic_back} alt="" />
          </Link>
          <label htmlFor="">Syllabus</label>
        </div>
      </div>

      <div className="feature-lists publication-tab-content row">
        <div className="add-btn">
          <button
            onClick={() => {
              handleAddQuraanShow();
              setIsEdit(false);
              // setSyllabusObj({})
            }}
          >
            Add Syllabus+
          </button>
          {/* <button onClick={handleAddSyllabusShow} className="ms-2">
            Update Details
          </button> */}
        </div>
        {/* <div className="course-details">
          <div className="course-heading">Primary Course</div>
          <p className="course-des">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab
            voluptas, numquam debitis earum voluptatibus, eveniet ea,
            repudiandae nobis beatae ratione quas aut a asperiores quaerat!
            Suscipit soluta neque repellendus consequatur.
          </p>
        </div> */}

        {loader ? (
          <FormLoader />
        ) : (
          <>
            {syllabusDetailsList && syllabusDetailsList.length > 0 ? (
              syllabusDetailsList.map((card, index) => {
                return (
                  <div className="col-md-3">
                    <div className="books-card-main">
                      <div className="btns-top">
                        {/* <Link to="/SyllabusDetails">
                          <button
                            onClick={handleAddQuraanShow}
                            className="view"
                          >
                            <img src={ic_eyes} alt="" />
                          </button>
                        </Link> */}
                        <button
                          className="delete "
                          onClick={() => {
                            handleDeleteShow();
                            deleteSyllabus(card?.group_id);
                          }}
                        >
                          <img src={ic_delete} alt="" />
                        </button>
                        <button
                          onClick={() => {
                            setIsEdit(true);
                            handleAddQuraanShow();
                            getUpdateDataSyllabus(card?.group_id);
                          }}
                          className="edit"
                        >
                          <img src={ic_edit} alt="" />
                        </button>
                      </div>

                      <div className="inner-wrapper">
                        <Link to="#" className="book-img">
                          <img
                            // src={quraan_kareem_cover_9_line}
                            // src={`https://api.deeniyatplus.com:3010/api/public/assist/${card.course[0]?.syllabus_image}`}
                            src={`${IMAGE_URL}${card.course[0]?.syllabus_image}`}
                            alt=""
                          />
                        </Link>
                        {card?.course?.map((cardData) => {
                          return (
                            <>
                              <div className="name">
                                {/* 1st Year */}
                                {cardData.syllabus_title}
                              </div>
                              <div className="line">
                                {cardData.syllabus_desc}
                              </div>
                              <hr />
                            </>
                          );
                        })}
                        <div className="line">
                          <span className="fw-bold">Open App: </span>
                          {card?.course[0]?.open_app_link}
                        </div>
                        <div className="line">
                          <span className="fw-bold">Buy Now: </span>
                          {card?.course[0]?.buy_now_link}
                        </div>
                        <div className="line">
                          <span className="fw-bold">Play Store: </span>
                          {card?.course[0]?.play_store_link}
                        </div>
                        <div className="line">
                          <span className="fw-bold">App Store: </span>
                          {card?.course[0]?.app_store_link}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <h5 className="text-danger text-center">No Data Found</h5>
              </>
            )}
          </>
        )}
        {/* <div className="col-md-3">
          <div className="books-card-main">
            <div className="btns-top">
              <Link to="/SyllabusDetails">
                <button onClick={handleAddQuraanShow} className="view">
                  <img src={ic_eyes} alt="" />
                </button>
              </Link>
              <button className="delete " onClick={handleDeleteShow}>
                <img src={ic_delete} alt="" />
              </button>
              <button onClick={handleAddQuraanShow} className="edit">
                <img src={ic_edit} alt="" />
              </button>
            </div>

            <div className="inner-wrapper">
              <Link to="/SyllabusDetails" className="book-img">
                <img src={quraan_kareem_cover_9_line} alt="" />
              </Link>
              <div className="name">1st Year</div>
            </div>
          </div>
        </div> */}
        {/* <div className="col-md-3">
          <div className="books-card-main">
            <div className="btns-top">
              <Link to="/SyllabusDetails">
                <button onClick={handleAddQuraanShow} className="view">
                  <img src={ic_eyes} alt="" />
                </button>
              </Link>
              <button className="delete " onClick={handleDeleteShow}>
                <img src={ic_delete} alt="" />
              </button>
              <button onClick={handleAddQuraanShow} className="edit">
                <img src={ic_edit} alt="" />
              </button>
            </div>

            <div className="inner-wrapper">
              <Link to="/SyllabusDetails" className="book-img">
                <img src={ic_nursery_tr_2} alt="" />
              </Link>
              <div className="name">2st Year</div>
            </div>
          </div>
        </div> */}
      </div>

      <Modal
        show={addQuraan}
        onHide={handleAddQuraanClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEdit ? "Update Syllabus" : "Add Syllabus"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="feature-wrapper">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <Form.Label>Quraan Image</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => {
                      setSyllabusObj({
                        ...syllabusObj,
                        file: e.target.files[0],
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Sabaq Name
                  </Form.Control.Feedback>
                </div>

                <div className="col-md-6 mb-4">
                  <Form.Label>Years Urdu</Form.Label>
                  <Form.Control
                    type="text"
                    value={syllabusObj?.syllabus_title_urdu}
                    onChange={(e) => {
                      setSyllabusObj({
                        ...syllabusObj,
                        syllabus_title_urdu: e.target.value,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Heading
                  </Form.Control.Feedback>
                </div>
                <div className="col-md-6 mb-4">
                  <Form.Label>Years Hindi</Form.Label>
                  <Form.Control
                    type="text"
                    value={syllabusObj?.syllabus_title_hindi}
                    onChange={(e) => {
                      setSyllabusObj({
                        ...syllabusObj,
                        syllabus_title_hindi: e.target.value,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Heading
                  </Form.Control.Feedback>
                </div>
                <div className="col-md-6 mb-4">
                  <Form.Label>Years English</Form.Label>
                  <Form.Control
                    value={syllabusObj?.syllabus_title_english}
                    type="text"
                    onChange={(e) => {
                      setSyllabusObj({
                        ...syllabusObj,
                        syllabus_title_english: e.target.value,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Heading
                  </Form.Control.Feedback>
                </div>
                <div className="col-md-6 mb-4">
                  <Form.Label>Years Translitration</Form.Label>
                  <Form.Control
                    type="text"
                    value={syllabusObj.syllabus_title_lit}
                    onChange={(e) => {
                      setSyllabusObj({
                        ...syllabusObj,
                        syllabus_title_lit: e.target.value,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Heading
                  </Form.Control.Feedback>
                </div>

                <div className="col-md-12 mb-4">
                  <Form.Label>Description Urdu</Form.Label>
                  <Form.Control
                    value={syllabusObj?.syllabus_desc_urdu}
                    as="textarea"
                    onChange={(e) => {
                      setSyllabusObj({
                        ...syllabusObj,
                        syllabus_desc_urdu: e.target.value,
                      });
                    }}
                    rows={3}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Tarjama.
                  </Form.Control.Feedback>
                </div>
                <div className="col-md-12 mb-4">
                  <Form.Label>Description Hindi</Form.Label>
                  <Form.Control
                    value={syllabusObj?.syllabus_desc_hindi}
                    as="textarea"
                    onChange={(e) => {
                      setSyllabusObj({
                        ...syllabusObj,
                        syllabus_desc_hindi: e.target.value,
                      });
                    }}
                    rows={3}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Tarjama.
                  </Form.Control.Feedback>
                </div>
                <div className="col-md-12 mb-4">
                  <Form.Label>Description English</Form.Label>
                  <Form.Control
                    value={syllabusObj?.syllabus_desc_english}
                    as="textarea"
                    onChange={(e) => {
                      setSyllabusObj({
                        ...syllabusObj,
                        syllabus_desc_english: e.target.value,
                      });
                    }}
                    rows={3}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Tarjama.
                  </Form.Control.Feedback>
                </div>

                <div className="col-md-12 mb-4">
                  <Form.Label>Description Translitration</Form.Label>
                  <Form.Control
                    value={syllabusObj?.syllabus_desc_lit}
                    as="textarea"
                    onChange={(e) => {
                      setSyllabusObj({
                        ...syllabusObj,
                        syllabus_desc_lit: e.target.value,
                      });
                    }}
                    rows={3}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Tarjama.
                  </Form.Control.Feedback>
                </div>

                <div className="col-md-12 mb-4">
                  <Form.Label>Open App</Form.Label>
                  <Form.Control
                    value={syllabusObj?.open_app_link}
                    type="text"
                    // required
                    onChange={(e) => {
                      setSyllabusObj({
                        ...syllabusObj,
                        open_app_link: e.target.value,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter App Link
                  </Form.Control.Feedback>
                </div>

                <div className="col-md-12 mb-4">
                  <Form.Label>Buy Now</Form.Label>
                  <Form.Control
                    value={syllabusObj?.buy_now_link}
                    type="text"
                    // required
                    onChange={(e) => {
                      setSyllabusObj({
                        ...syllabusObj,
                        buy_now_link: e.target.value,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter App Link
                  </Form.Control.Feedback>
                </div>

                <div className="col-md-12 mb-4">
                  <Form.Label>Play Store</Form.Label>
                  <Form.Control
                    value={syllabusObj?.play_store_link}
                    type="text"
                    // required
                    onChange={(e) => {
                      setSyllabusObj({
                        ...syllabusObj,
                        play_store_link: e.target.value,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter App Link
                  </Form.Control.Feedback>
                </div>

                <div className="col-md-12 mb-4">
                  <Form.Label>App Store</Form.Label>
                  <Form.Control
                    value={syllabusObj?.app_store_link}
                    type="text"
                    // required
                    onChange={(e) => {
                      setSyllabusObj({
                        ...syllabusObj,
                        app_store_link: e.target.value,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter App Link
                  </Form.Control.Feedback>
                </div>

              </div>
              <div className="buttons-main">
                {/* <button className="cancel-btn">Cancel</button> */}

                <button className="submit-btn" onClick={submitAddSyllabus}>
                  {isEdit ? "Update" : "Submit"}
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* <Modal
        show={addSyllabus}
        onHide={handleAddSyllabusClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Syllabus Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="feature-wrapper">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <Form.Label>Heading Urdu</Form.Label>
                  <Form.Control type="text" />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Heading
                  </Form.Control.Feedback>
                </div>
                <div className="col-md-6 mb-4">
                  <Form.Label>Heading Hindi</Form.Label>
                  <Form.Control type="text" />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Heading
                  </Form.Control.Feedback>
                </div>
                <div className="col-md-6 mb-4">
                  <Form.Label>Heading English</Form.Label>
                  <Form.Control type="text" />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Heading
                  </Form.Control.Feedback>
                </div>
                <div className="col-md-6 mb-4">
                  <Form.Label>Heading Translitration</Form.Label>
                  <Form.Control type="text" />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Heading
                  </Form.Control.Feedback>
                </div>
                <div className="col-md-12 mb-4">
                    <Form.Label>Description Urdu</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Tarjama.
                    </Form.Control.Feedback>
                  </div><div className="col-md-12 mb-4">
                    <Form.Label>Description Hindi</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Tarjama.
                    </Form.Control.Feedback>
                  </div><div className="col-md-12 mb-4">
                    <Form.Label>Description English</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Tarjama.
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-md-12 mb-4">
                    <Form.Label>Description Translitration</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Tarjama.
                    </Form.Control.Feedback>
                  </div>
                
              </div>
              <div className="buttons-main">

                <button className="submit-btn">Submit</button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal> */}

      {/* ======================= DELETE ========================== */}

      <Modal
        show={deleteModal}
        onHide={handleDeleteClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-msg text-center">
            <label htmlFor="">Are you sure you want to delete this item?</label>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="buttons-modal    ">
            <button className="me-2 bg-danger" type="submit"
              onClick={deleteAssistPopUp}
            >
              Delete
            </button>
            <Link to="#" className="" onClick={handleDeleteClose}>
              Cancel
            </Link>
          </div>
        </Modal.Footer>
      </Modal>

      {/* ======================= END DELETE ========================== */}
    </main>
  );
};

export default Syllabus;
